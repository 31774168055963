const initialState = {
	hidden: true,
	searchPath: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SET_SEARCH_SETTINGS':
			return Object.assign({}, state, {
				hidden: action.hidden,
				searchPath: action.searchPath
			});

		default:
			return state;
	}
};
