import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import noop from 'lodash/noop';
import EventDetailsView from '../views/EventDetailsView';
import PeopleProfileView from '../views/PeopleProfileView';
import ChatConversationTags from './ChatConversationTags';
import HelpDocumentViewer from './HelpDocumentViewer';

class RightPanel extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidCatch(error, errorInfo) {
		// eslint-disable-next-line no-console
		console.error('RightPanel: error: ', error, errorInfo);
	}

	renderInAppBrowserComponent() {
		const { component } = this.props;

		if (component.attributes && component.attributes.url) {
			return <HelpDocumentViewer url={component.attributes.url} />;
		}

		return null;
	}

	renderEventDetailsComponent() {
		const { component } = this.props;

		if (component.attributes && component.attributes.eventIDs) {
			return <EventDetailsView eventIDs={component.attributes.eventIDs} />;
		}

		return null;
	}

	renderPeopleProfileComponent() {
		return (
			<section
				className="people-view-container"
				role="main"
				aria-label="Right panel view"
			>
				<div className="adp-e-mobile-content">
					<div style={{ marginTop: '5px' }}>
						<PeopleProfileView />
					</div>
				</div>
			</section>
		);
	}

	renderChatConversationTags() {
		return <ChatConversationTags />;
	}

	renderComponent() {
		const { component } = this.props;

		if (!component) {
			return null;
		}

		const { type } = this.props.component;
		switch (type) {
			case 'EVENT_DETAILS':
				return this.renderEventDetailsComponent();
			case 'IN_APP_BROWSER':
				return this.renderInAppBrowserComponent();
			case 'PEOPLE_PROFILE':
				return this.renderPeopleProfileComponent();
			case 'CHAT_CONVERSATION_TAGS':
				return this.renderChatConversationTags();
			default:
				return null;
		}
	}

	render() {
		return (
			<div className="adp-e-mobile-expanded-main-content-panel">
				{this.renderComponent()}
			</div>
		);
	}
}

RightPanel.propTypes = {
	component: PropTypes.shape({
		type: PropTypes.string,
		attributes: PropTypes.object
	})
};

RightPanel.defaultProps = {
	component: {},
	onClickSupportAction: noop
};

const mapStateToProps = ({ rightPanel }) => ({
	component: rightPanel.component
});

export default connect(mapStateToProps)(RightPanel);
