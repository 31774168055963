import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import appHistory from '../appHistory';
import * as queryString from 'query-string';
import { IconRollLogo } from '@adplabs/adp-e-common/ui-svg/icon-roll-logo';
import { ActionButton } from '@adplabs/adp-e-common/ui-input/components/ActionButton';
import { clearError } from '@adplabs/adp-e-common/ui-error';
import { getLastVisitedBeforeError } from '../utils/trackUser';
import { setHeaderTitleToken } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';
import session from '../UserSession';

const intlNamespace = 'mobile:ErrorView';

class ErrorView extends React.Component {
	constructor() {
		super();
		this.renderBackButton = this.renderBackButton.bind(this);
		this.onBackButtonClick = this.onBackButtonClick.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(
			setHeaderTitleToken(`${intlNamespace}.header`)
		);
		this.props.dispatch(
			setSearchSettings({
				hidden: true
			})
		);
	}

	renderBackButton() {
		const { buttonText, redirectTo } = this.props;
		// if user is not authenticated BACK button
		// should redirect back to login screen
		if (!session.isUserInitialized()) {
			return (
				<div className="adp-e-chat-ui-input-components">
					<div className="actions-group">
						<ActionButton
							onClick={() => {
								// reload the app
								window.location = '/';
							}}
						>
							{buttonText || 'Go Back'}
						</ActionButton>
					</div>
				</div>
			);
		}

		// get the path visited before something failed
		const lastVisited = getLastVisitedBeforeError();
		if ((lastVisited !== null && lastVisited.path) || redirectTo) {
			const lastBeforeError = lastVisited
				? `${lastVisited.path}${lastVisited.search}`
				: '';
			return (
				<div className="adp-e-chat-ui-input-components">
					<div className="actions-group">
						<ActionButton
							onClick={() =>
								this.onBackButtonClick(lastBeforeError || redirectTo)
							}
						>
							{buttonText || 'Go Back'}
						</ActionButton>
					</div>
				</div>
			);
		} else return null;
	}

	onBackButtonClick(redirectTo) {
		if (redirectTo) {
			appHistory.push(redirectTo);
		}
		this.props.dispatch(clearError());
	}

	render() {
		const { message, subMessage } = this.props;
		const defaultMessage =
			"I couldn't find what you were looking for, let's go back and try again.";
		// Grab query string to be backward compatible
		const query = subMessage || queryString.parse(location.search);
		return (
			<section
				className="error-view-container"
				role="main"
				aria-label="Error view"
			>
				<div className="adp-e-mobile-content">
					<div className="error-content">
						<div className="error-title">
							<IconRollLogo className="text-primary-700" size={100} />
						</div>
						<p>{message || defaultMessage}</p>
						{query.msg ? <p className="error-message">{query.msg}</p> : null}
					</div>
					{this.renderBackButton()}
				</div>
			</section>
		);
	}
}

const mapStateToProps = ({ errorManager }) => ({
	...errorManager
});

ErrorView.propTypes = {
	dispatch: PropTypes.func,
	message: PropTypes.string,
	subMessage: PropTypes.string,
	redirectTo: PropTypes.string,
	buttonText: PropTypes.string
};

export default connect(mapStateToProps)(ErrorView);
