const createScriptTag = (src, backupSrc) => {
	const scriptElem = document.createElement('script');
	scriptElem.src = src;
	if (backupSrc) {
		scriptElem.onerror = () => {
			document.body.appendChild(createScriptTag(backupSrc));
		};
	}
	return scriptElem;
};

export default createScriptTag;
