import { socketServerUrl, baseUrl } from '../utils/endpoints';

export const setUserInfo = response => ({
	type: 'SET_USER_INFO',
	response
});

export const endImpersonationSession = () => ({
	type: 'IMPERSONATION_SESSION_END',
	api: {
		endpoint: `${socketServerUrl()}/practitionerServices/v1/impersonation/end`,
		method: 'POST',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	}
});

export const invalidateSession = (response = {}, prevAction) => {
	const action = {
		type: 'INVALIDATE_SESSION'
	};
	return response.error ? action : prevAction;
};

export const startSessionValidationPolling = () => ({
	type: 'VALIDATE_SESSION',
	polling: {
		interval: 60000,
		endpoint: `${baseUrl()}/auth/sbs/status`,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		callback: invalidateSession
	}
});

export const updateDeviceInfo = deviceParameters => ({
	type: 'UPDATE_DEVICE_INFO',
	api: {
		endpoint: `${socketServerUrl()}/user/v1/device`,
		method: 'POST',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(deviceParameters)
	}
});

export const submitUserVisit = userParams => ({
	type: 'SUBMIT_USER_VISIT',
	api: {
		endpoint: `${socketServerUrl()}/user/v1/visit`,
		method: 'POST',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(userParams)
	}
});

export const updatePersonLocale = locale => ({
	type: 'UPDATE_PERSON_LOCALE',
	api: {
		endpoint: `${socketServerUrl()}/user/v1/localecode`,
		method: 'POST',
		credentials: 'include',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			localeCode: locale
		})
	}
});
