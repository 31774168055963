import React from 'react';
import { IconRollLogo } from '@adplabs/adp-e-common/ui-svg/icon-roll-logo';

class LogOutView extends React.Component {
	render() {
		return (
			<section
				className="error-view-container"
				role="main"
				aria-label="Log out view"
			>
				<div className="adp-e-mobile-content error-content">
					<div className="error-title">
						<IconRollLogo className="text-primary-700" size={100} />
					</div>
				</div>
			</section>
		);
	}
}

export default LogOutView;
