/*
  react-router authentication middleware

  see for example:
    https://github.com/reactjs/react-router/blob/master/examples/auth-flow-async-with-query-params/app.js#L60
*/

import session from './UserSession';
import _log from './log';
const log = _log('app:middleware:authorize');

export function UnauthorizedError() {}
UnauthorizedError.prototype.name = UnauthorizedError.name;
UnauthorizedError.prototype.message = 'Error retrieving user from session';

export default function authorize() {
	return new Promise((resolve, reject) => {
		if (!session.isUserInitialized()) {
			session
				.getUser()
				.then(user => {
					if (!user || user.error) {
						// throwing error should trigger User Authentication Status validation in
						throw new Error('Retrieved user is invalid');
					}
					resolve(user);
				})
				.catch(err => {
					log('DEBUG', UnauthorizedError.prototype.message, err);
					reject(new UnauthorizedError());
				});
		} else {
			resolve(session.user);
		}
	});
}
