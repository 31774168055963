import React from 'react';

export function SkipToNav() {
	return (
		<a href="#mobile-navigation" className="a-screen-reader-text">
			Skip to navigation
		</a>
	);
}

export default SkipToNav;
