import { timelineServicesURL } from '../utils/endpoints';

export const setChatConnected = connected => ({
	type: 'SET_CHAT_CONNECTED',
	connected
});

export const addWithInAppMessage = message => ({
	type: 'ADD_CHAT_IN_APP_MESSAGE',
	message
});

export const removeWithInAppMessage = message => ({
	type: 'REMOVE_CHAT_IN_APP_MESSAGE',
	message
});

export const getRunnableConversations = () => ({
	type: 'GET_RUNNABLE_CONVERSATIONS',
	api: {
		method: 'POST',
		endpoint: `${timelineServicesURL}/v1/conversation/runnable`,
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			onlyAlreadyRun: false
		})
	}
});

export const getConversationsAlreadyRun = () => ({
	type: 'GET_CONVERSATIONS_ALREADY_RUN',
	api: {
		method: 'POST',
		endpoint: `${timelineServicesURL}/v1/conversation/runnable`,
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			onlyAlreadyRun: true
		})
	}
});

export const setChatMessageRendered = message => ({
	type: 'SET_CHAT_MESSAGE_RENDERED',
	message
});

export const setCanonicalAwaitingToStartConversation = canonical => ({
	type: 'SET_CANONICAL_AWAITING_TO_START_CONVERSATION',
	canonical
});

export const setChatOAuthParams = oAuthReturnParams => ({
	type: 'SET_CHAT_OAUTH_PARAMS',
	oAuthReturnParams
});

export const setChatStore = chatStore => ({
	type: 'SET_CHAT_STORE',
	chatStore
});

export const setChatBusy = busyIndicator => ({
	type: 'SET_CHAT_BUSY',
	busyIndicator
});
