const queryStringToParams = urlInput => {
	// sometimes browser adds # at the end of the URI after redirection,
	// we should remove it
	const url = urlInput.slice(-1) === '#' ? urlInput.slice(0, -1) : urlInput;
	const paramsObj = {};
	const queryPosition = url.indexOf('?');
	const query = queryPosition >= 0 ? url.substring(queryPosition + 1) : '';
	for (const param of query.split('&')) {
		const ind = param.indexOf('=');
		if (ind > 0) {
			const name = param.substring(0, ind);
			const value = param.substring(ind + 1);
			paramsObj[name] = value;
		}
	}
	return paramsObj;
};

export default queryStringToParams;
