/* global SafariViewController */
import { isMobile } from 'react-device-detect';
import { setMobileAppRequestConfirm } from '@adplabs/adp-e-common/common/utils/UserAgent';
import { getLatestRecordedGeolocation } from './geolocation_old';
import DeviceParams from './deviceParams';
import ClientSocketLib from '../ClientSocketLib';
// eslint-disable-next-line import/no-cycle
import session from '../UserSession';
import { deleteLastVisited, getUserCountryCode } from './trackUser';
import { isUsingCordova, isAndroid, isVersionSameOrAfter } from './appInfo';
import * as SecureKeyStore from './cordovaSecureKeyStorePluginAPI';
import { getEnvironmentName, socketServerUrl } from './endpoints';
import {
	REDIRECT_ENDPOINT_WEB,
	REDIRECT_ENDPOINT_MOBILE,
	HTTP_SERVER_ENDPOINT
} from '../constants';
import { clearStore } from '../store';	// eslint-disable-line import/no-cycle
// eslint-disable-next-line import/no-cycle
import { destroyChat } from '../components/Chat';
import _log from '../log';
import { getURLWithPrefix } from './Prefix';
// import { eIntl } from '@adplabs/adp-e-common/ui-intl';

const log = _log('app:Auth');
// const intlNamespace = 'mobile:NavigationMenu';

function parseResponse(res) {
	if (res && res.ok) {
		try {
			return res.json();
		} catch (e) {
			log('ERROR', `returning parseReponse json error: ${e.message}`);
			throw e;
		}
	} else {
		throw new Error(`${res.status} - ${res.statusText}`);
	}
}

export function launchTheApp(provider, stepUp) {
	if (provider) {
		SecureKeyStore.setKey('provider', provider);
	}
	// force Android OS to syncup cookies in WebView
	if (isAndroid() && isVersionSameOrAfter('3.2.38')) {
		window.cordova.plugins.ADPeAppCookies.syncAllCookies();
	}
	if (stepUp !== undefined) {
		SecureKeyStore.setKey('stepup', stepUp);
	}
	// launch the main application
	window.launchApp();
}
/**
 * Calls Auth server API to verify whether is an access token presented
 * @returns {boolean}
 */
export function isUserAuthenticated() {
	return new Promise((resolve, reject) => {
		getUserStatus()
			.then(statusData => {
				resolve(statusData.authenticated || false);
			})
			.catch(err => {
				log('ERROR', 'isUserAuthenticated: error', err);
				reject(err);
			});
	});
}

export function getUserStatus() {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			}
		};
		fetch(getURLWithPrefix('/auth/healthcheck'), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(() => fetch(getURLWithPrefix('/auth/status'), apiParams))
			.then(parseResponse)
			.then(statusData => {
				log('INFO', 'getUserStatus', statusData);
				if (statusData.error) {
					throw statusData.error;
				}
				resolve(statusData);
			})
			.catch(err => {
				log('ERROR', 'getUserStatus: error', err);
				reject(err);
			});
	});
}

export function getUserInfo() {
	return new Promise((resolve, reject) => {
		fetch(`${socketServerUrl()}/user/v1`, {
			method: 'GET',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(res => {
				if (res && res.status === 403) {
					logOut();
					throw new Error('Session expired');
				}
				return parseResponse(res);
			})
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'getUserInfo', err);
				reject(err);
			});
	});
}

export function updateUserInfo({ clientId }) {
	return new Promise((resolve, reject) => {
		fetch(`${socketServerUrl()}/user/v1/session`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				clientID: clientId
			})
		})
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'updateUserInfo', err);
				reject(err);
			});
	});
}

export function getCompanySetupIndicator() {
	return new Promise((resolve, reject) => {
		fetch(getURLWithPrefix('/auth/company-setup'), {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		})
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'getCompanySetupInfo', err);
				resolve({});
			});
	});
}

export function submitApplicationEntrySource({ partnercode = null, mediacode = null }) {
	return new Promise((resolve, reject) => {
		fetch(`${socketServerUrl()}/user/v1/entrysource`, {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			},
			body: JSON.stringify({ partnercode, mediacode })
		})
			.then(res => resolve())
			.catch(err => {
				log('ERROR', 'submitApplicationEntrySource:', err);
				resolve();
			});
	});
}

export function getAuthCode({ username, password, state }) {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			}
		};
		fetch(getURLWithPrefix(getURLWithPrefix('/auth/healthcheck')), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(() => fetch(getURLWithPrefix('/auth/login'), {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-ADP-E-Device': DeviceParams.getDeviceID()
				},
				body: JSON.stringify({
					username: username.toLowerCase(),
					password,
					state,
					countryCode: getUserCountryCode()
				})
			}))
			.then((res) => {
				log('INFO', res);
				if ((res && res.ok) || (res && res.ok === false && res.status === 401)) {
					try {
						return res.json();
					} catch (e) {
						log('ERROR', `returning auth/login fetch json error: ${e.message}`);
						throw e;
					}
				} else {
					throw new Error(`${res.status} - ${res.statusText}`);
				}
			})
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'getAuthCode:', err);
				reject(err);
			});
	});
}

export function exchangeAuthCodeToAccessToken(authCode) {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		};
		const deviceID = DeviceParams.getDeviceID();
		fetch(getURLWithPrefix('/auth/healthcheck'), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(getLatestRecordedGeolocation)
			.then(gl => {
				return fetch(getURLWithPrefix('/auth/token'), {
					method: 'POST',
					credentials: 'same-origin',
					headers: {
						'Content-Type': 'application/json',
						'X-ADP-E-Latitude': gl.lat,
						'X-ADP-E-Longitude': gl.lng,
						'X-ADP-E-Device': deviceID
					},
					body: JSON.stringify({
						code: authCode,
						client_id: '',
						grant_type: 'e_authorization_code',
						did: deviceID
					})
				});
			})
			.then(parseResponse)
			.then(data => {
				if (data.error) {
					throw data.error;
				}
				resolve(data);
			})
			.catch(err => {
				log('ERROR', 'exchangeAuthCodeToAccessToken:', err);
				reject(err);
			});
	});
}

function getProviderRedirectLocation(mode = 'web', provider) {
	const countryCode = getUserCountryCode();
	const deviceID = DeviceParams.getDeviceID();
	const redirect_uri =
		mode === 'native' ? REDIRECT_ENDPOINT_MOBILE : REDIRECT_ENDPOINT_WEB;
	return `${HTTP_SERVER_ENDPOINT}/auth/login/${provider}?${countryCode ? `countrycode=${countryCode}&` : ''}${deviceID ? `deviceid=${deviceID}&` : ''}redirect_uri=${redirect_uri}`;
}

/**
 * Depends on Cordova accessibility
 * should redirect user directly to authentication provider login page
 * or
 * ask Cordova to load authentication login form in native browser window
 */
export function federationLogin(provider, callback) {
	if (isUsingCordova() && window.cordova) {
		// cordova enabled
		SafariViewController.isAvailable(function (available) {
			if (available) {
				// open new Safari browser and pass new location
				log('DEBUG', 'SafariViewController - show');
				SafariViewController.show(
					{
						url: getProviderRedirectLocation('native', provider),
						reloadOnWindowBlur: true,
						hidden: false,
						animated: true,
						transition: 'curl'
					},
					// this success handler will be invoked for the lifecycle events 'opened', 'loaded' and 'closed'
					function (result) {
						log('DEBUG', 'SafariViewController - result', result);
						if (result.event === 'closed') {
							callback(result);
						}
					},
					function (msg) {
						log('DEBUG', 'SafariViewController - msg', msg);
					}
				);
			} else {
				const loc = getProviderRedirectLocation('web', provider);
				window.location = loc;
			}
		});
	} else {
		// app running in web mode, just redirect to given location
		const loc = getProviderRedirectLocation('web', provider);
		window.location = loc;
	}
}

export function openLinkInNativeBrowser(link) {
	log('DEBUG', 'openLinkInNativeBrowser:', link);
	const isUsngCrvd = isUsingCordova();
	const hasWndnCrdv = window.cordova !== null && window.cordova !== undefined;
	if (isUsngCrvd && hasWndnCrdv) {
		window.cordova.InAppBrowser.open(link, '_system', 'location=no');
	} else {
		// app running in web mode, just redirect to given location
		window.location = link;
		log(
			'DEBUG',
			`isUsngCrvd=${isUsngCrvd} hasWndnCrdv=${hasWndnCrdv} Setting window.location to`,
			link
		);
	}
}

function deleteLiveChatSession() {
	window.setTimeout(() => {
		// defined in adp-e-rocket-chat-livechat/src/store/Store.ts
		// ensure a small interval to make sure react components are
		// unmounted
		window.localStorage.removeItem('live-chat-store');
		window.sessionStorage.removeItem('live-chat-session-id');
	}, 2000);
}

export function logOut() {
	// start E animation
	// showWelcomeScreen();
	// make a call to HTTPBE to delete secured cookies
	fetch(getURLWithPrefix('/auth/logout'), {
		credentials: 'same-origin',
		method: 'DELETE',
		headers: {
			'X-ADP-E-Device': DeviceParams.getDeviceID()
		},
	})
		.then(response => {
			if (response.ok) {
				// force Android OS to syncup cookies in WebView
				if (isAndroid() && isVersionSameOrAfter('3.2.38')) {
					window.cordova.plugins.ADPeAppCookies.syncAllCookies();
				}
				// erase state so no data remain over sessions
				clearStore();
				// delete User Session Object
				session.setUser(null);
				// break WS connection
				ClientSocketLib.disconnect();
				// delete history of visited views
				deleteLastVisited();
				// delete Chat from DOM and from module cache
				destroyChat();
				deleteLiveChatSession();
				// clear geo service flag
				SecureKeyStore.removeKey('startGeoServiceOnCordovaResume').catch(e => {
					log('ERROR', `logOut error at SecureKeyStore.removeKey promise call: ${e.message}`);
				});
				// clear IdentityProviderAccountNumber
				SecureKeyStore.removeKey('sub').catch(e => {
					log('ERROR', `logOut error at SecureKeyStore.removeKey promise call: ${e.message}`);
				});
				// clear StepUp
				SecureKeyStore.removeKey('stepup').catch(e => {
					log('ERROR', `StepUp SecureKeyStore.removeKey promise call: ${e.message}`);
				});

				if (isUsingCordova() || (isMobile && getEnvironmentName() !== 'PROD')) {
					// redirect to Login View
					window.launchAuth();
				} else {
					// redirect to QR code View
					window.launchQRCodeAuth();
				}
			} else {
				throw new Error(`Authentication server error - ${response.status}`);
			}
		})
		.catch(e => {
			log('ERROR', 'logOut', e);
			// reload the app instead of showing error logout API error
			window.location.reload();
			// appHistory.push(
			// 	`/error?msg=${e.message ||
			// 		eIntl.formatMessage(`${intlNamespace}.logoutError`)}`
			// );
		});
}

export function hideWelcomeScreen() {
	if (window && window.cordova) {
		/* eslint-enable no-undef */
		window.cordova.exec(
			null,
			null,
			'CDVWKWebViewEngine',
			'stopLoadAnimation',
			[]
		);
		window.setTimeout(function () {
			navigator.splashscreen.hide();
		}, 0);
	}
}

export function showWelcomeScreen() {
	if (window && window.cordova) {
		/* eslint-enable no-undef */
		window.cordova.exec(
			null,
			null,
			'CDVWKWebViewEngine',
			'startLoadAnimation',
			[]
		);
	}
}

export function SubmitResetPassword({ username, password, state }) {
	return new Promise((resolve, reject) => {
		fetch(getURLWithPrefix('/auth/challenge'), {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username: username.toLowerCase(),
				password,
				state,
				challenge: 'NEW_PASSWORD_REQUIRED'
			})
		})
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'SubmitResetPassword:', err);
				reject(err);
			});
	});
}

export function InitForgotPassword({ username, state }) {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			}
		};
		fetch(getURLWithPrefix('/auth/healthcheck'), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(() => fetch(getURLWithPrefix('/auth/forgotpwd'), {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-ADP-E-Device': DeviceParams.getDeviceID()
				},
				body: JSON.stringify({
					username: username.toLowerCase(),
					state
				})
			}))
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'InitForgotPassword:', err);
				reject(err);
			});
	});
}

export function SubmitForgotPassword({ username, password, code, state }) {
	return new Promise((resolve, reject) => {
		fetch(getURLWithPrefix('/auth/challenge'), {
			method: 'POST',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			},
			body: JSON.stringify({
				challenge: 'PASSWORD_RESET',
				username: username.toLowerCase(),
				password,
				code,
				state
			})
		})
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'SubmitForgotPassword:', err);
				reject(err);
			});
	});
}

export function SubmitSignUp({ username, password, state }) {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			}
		};
		fetch(getURLWithPrefix('/auth/healthcheck'), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(() => fetch(getURLWithPrefix('/auth/signup'), {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-ADP-E-Device': DeviceParams.getDeviceID()
				},
				body: JSON.stringify({
					username: username.toLowerCase(),
					password,
					state
				})
			}))
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'SubmitSignUp:', err);
				reject(err);
			});
	});
}

export function ResendConfirmationCode({ username, state }) {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			}
		};
		fetch(getURLWithPrefix('/auth/healthcheck'), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(() => fetch(getURLWithPrefix('/auth/code'), {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-ADP-E-Device': DeviceParams.getDeviceID()
				},
				body: JSON.stringify({
					username: username.toLowerCase(),
					state
				})
			}))
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'ResendConfirmationCode:', err);
				reject(err);
			});
	});
}

export function SubmitSignUpConfirm({ username, code, state }) {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			}
		};
		fetch(getURLWithPrefix('/auth/healthcheck'), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(() => fetch(getURLWithPrefix('/auth/signup'), {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					'X-ADP-E-Device': DeviceParams.getDeviceID()
				},
				body: JSON.stringify({
					username: username.toLowerCase(),
					code,
					state
				})
			}))
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'SubmitSignUpConfirm:', err);
				reject(err);
			});
	});
}

export function SubmitDeviceToken(token) {
	return new Promise((resolve, reject) => {
		fetch(getURLWithPrefix('/auth/device-token'), {
			method: 'POST',
			credentials: 'include',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			},
			body: JSON.stringify({
				token
			})
		})
			.then(res => parseResponse(res))
			.then(data => resolve(data))
			.catch(err => {
				log('ERROR', 'SubmitDeviceToken:', err);
				reject(err);
			});
	});
}

export function SubmitQRCode(code) {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				'X-ADP-E-Device': DeviceParams.getDeviceID()
			}
		};
		fetch(getURLWithPrefix('/auth/healthcheck'), apiParams)
			.then(parseResponse)
			.then(healthcheckData =>
				healthcheckData.timeStamp
					? new Date(healthcheckData.timeStamp)
					: new Date()
			)
			.then(setMobileAppRequestConfirm)
			.then(() => fetch(getURLWithPrefix(`/workspace/logincode/${code}`), {
				method: 'POST',
				headers: {
					'X-ADP-E-Device': DeviceParams.getDeviceID()
				},
				body: ''
			}))
			.then(res => resolve(res))
			.catch(err => {
				log('ERROR', 'SubmitQRCode:', err);
				reject(err);
			});
	});
}

export function getFirebaseConfig() {
	return new Promise((resolve, reject) => {
		const apiParams = {
			method: 'GET',
			credentials: 'same-origin',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		};
		fetch(getURLWithPrefix('/auth/firebase'), apiParams)
			.then(parseResponse)
			.then(configObj => resolve(configObj))
			.catch(err => {
				log('ERROR', 'getFirebaseConfig:', err);
				reject(err);
			});
	});
}
