import {
	isDevelopmentEnvironment,
	generateHttpServerEndpoint,
	generatePublicPath,
	generateSocketServerEndpoint,
	generateRedirectEndpointWeb,
	generateRedirectEndpointMobile,
	generateDesktopLoginEndpoint
} from './config.js';

const googleMaps = {
	key: 'AIzaSyAVWfVBH20aEJzG3Nl4pF73gd2AFWqozE0',
	clientId: 'gme–adpoem'
};

// Microservice URIs
export const LOCATION = window.location;
export const HTTP_SERVER_ENDPOINT = generateHttpServerEndpoint(LOCATION);
export const SOCKET_SERVER_ENDPOINT = generateSocketServerEndpoint(LOCATION);
export const REDIRECT_ENDPOINT_WEB = generateRedirectEndpointWeb(LOCATION);
export const REDIRECT_ENDPOINT_MOBILE = generateRedirectEndpointMobile(
	LOCATION
);
export const WS_SERVER_ENDPOINT = SOCKET_SERVER_ENDPOINT.replace(
	'https://',
	'wss://'
).replace('http://', 'ws://');
export const CHAT_SERVER_ENDPOINT = HTTP_SERVER_ENDPOINT.replace(
	'https://',
	'wss://'
).replace('http://', 'ws://');
// Google Maps
export const GOOGLE_MAPS_API_KEY = googleMaps.key;
export const GOOGLE_MAPS_CLIENT_ID = googleMaps.clientId;

// Development mode
export const DEVELOPMENT = isDevelopmentEnvironment(LOCATION);

export const PUBLIC_PATH = generatePublicPath(LOCATION);

// See: https://labs-trac.es.ad.adp.com/e/ticket/1503
export const PE_QUERY_PARAM_WHITELIST = [
	'ENT_person',
	'version'
	// The following will be present, but, they
	// are parsed separately in PresentationEngine
	// 'eventId',
	// 'eventSubTypeCode',
	// 'eventStatusCode'
];

export const DESKTOP_LOGIN_ENDPOINT = generateDesktopLoginEndpoint(LOCATION);

export const SCREEN_ORIENTATION_LANDSCAPE = 'landscape';
export const SCREEN_ORIENTATION_PORTRAIT = 'portrait';
