const initialState = {
	connected: false,
	activeConversationCanonical: '',
	activeConversationID: '',
	messagesWithInAppView: [],
	oAuthReturnParams: {},
	chatStore: null,
	runnableConversations: {
		conversations: [],
		canonicalAwaitingToStart: '',
		fetching: true,
		error: false
	},
	chatBusyIndicator: false,
};

const reduceAddMessageWithInApp = (state, action) => {
	const { message: nextMessage } = action;

	/* ensure we don't have duplicated messages */
	const nextMessages = state.messagesWithInAppView.filter(
		message => message.messageID !== nextMessage.messageID
	);
	return Object.assign({}, state, {
		messagesWithInAppView: [nextMessage].concat(nextMessages)
	});
};

const reduceGetRunnableConversations = (state, action) => {
	const { runnableConversations } = state;

	const nextConversations =
		action.fetching || action.error
			? runnableConversations.conversations
			: action.response.runnableConversations;

	return Object.assign({}, state, {
		runnableConversations: Object.assign({}, runnableConversations, {
			conversations: nextConversations,
			fetching: action.fetching,
			error: action.error
		})
	});
};

const reduceGetConversationsAlreadyRun = (state, action) => {
	const { runnableConversations } = state;

	if (action.fetching || action.error) {
		return Object.assign({}, state, {
			runnableConversations: Object.assign({}, runnableConversations, {
				fetching: action.fetching,
				error: action.error
			})
		});
	}

	const { runnableConversations: conversationsAlreadyRun } = action.response;
	if (!conversationsAlreadyRun || conversationsAlreadyRun.length === 0) {
		return Object.assign({}, state, {
			runnableConversations: Object.assign({}, runnableConversations, {
				fetching: action.fetching,
				error: action.error
			})
		});
	}

	const nextConversationsReducer = (nextConversationsList, conversation) => {
		const alreadyRun = conversationsAlreadyRun.find(
			convAlreadyRun => convAlreadyRun.id === conversation.id
		);

		if (alreadyRun) {
			nextConversationsList.push(
				Object.assign({}, conversation, {
					timeLineDateTime: alreadyRun.timeLineDateTime
				})
			);
		} else {
			nextConversationsList.push(conversation);
		}

		return nextConversationsList;
	};

	const { conversations } = runnableConversations;
	const nextConversations = conversations.reduce(nextConversationsReducer, []);

	return Object.assign({}, state, {
		runnableConversations: Object.assign({}, runnableConversations, {
			conversations: nextConversations,
			fetching: action.fetching,
			error: action.error
		})
	});
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SET_CHAT_CONNECTED':
			return Object.assign({}, state, {
				connected: action.connected
			});

		case 'ADD_CHAT_IN_APP_MESSAGE':
			return reduceAddMessageWithInApp(state, action);

		case 'REMOVE_CHAT_IN_APP_MESSAGE':
			return Object.assign({}, state, {
				messagesWithInAppView: state.messagesWithInAppView.filter(
					message => message.messageID !== action.message.messageID
				)
			});

		case 'GET_RUNNABLE_CONVERSATIONS':
			return reduceGetRunnableConversations(state, action);

		case 'GET_CONVERSATIONS_ALREADY_RUN':
			return reduceGetConversationsAlreadyRun(state, action);

		case 'SET_CANONICAL_AWAITING_TO_START_CONVERSATION':
			return Object.assign({}, state, {
				runnableConversations: Object.assign({}, state.runnableConversations, {
					canonicalAwaitingToStart: action.canonical
				})
			});

		case 'SET_CHAT_MESSAGE_RENDERED':
			return Object.assign({}, state, {
				runnableConversations: Object.assign({}, state.runnableConversations, {
					canonicalAwaitingToStart: ''
				})
			});

		case 'CONV_STARTED':
			return Object.assign({}, state, {
				activeConversationCanonical: action.canonical,
				activeConversationID: action.conversationID
			});

		case 'CONV_FINISHED':
			return Object.assign({}, state, {
				activeConversationCanonical: '',
				activeConversationID: ''
			});

		case 'SET_CHAT_OAUTH_PARAMS':
			return Object.assign({}, state, {
				oAuthReturnParams: action.oAuthReturnParams
			});

		case 'SET_CHAT_STORE':
			return Object.assign({}, state, {
				chatStore: action.chatStore
			});

		case 'SET_CHAT_BUSY':
			return Object.assign({}, state, {
				chatBusyIndicator: action.busyIndicator
			});

		default:
			return state;
	}
};
