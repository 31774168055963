/* global device */
import 'whatwg-fetch';
import { isUsingCordova, isAndroid } from './utils/appInfo';
import { getEnvironmentName, httpServerUrl } from './utils/endpoints';

const LOG_LEVELS = ['DEBUG', 'WARN', 'INFO', 'ERROR'];
const ENV_NAME = getEnvironmentName();
const PROD_MODE = ['STG', 'SB', 'PROD'].indexOf(ENV_NAME) >= 0;
const LOG_SERVER_URL = `${httpServerUrl()}/log`;

function postLogMessageToBackend(level, context, args) {
	// stringify Error object
	if (level === 3) {
		const errObjInd = args.findIndex(e => e instanceof Error);
		if (errObjInd >= 0) {
			args[errObjInd] = args[errObjInd].toString();
		}
	}
	const logObj = {
		level: LOG_LEVELS[level],
		message: context,
		data: [],
		dateTime: Date.now(),
		deviceID: typeof device !== 'undefined' ? device.uuid || '' : ''
	};
	if (typeof args[1] === 'string') {
		logObj.message += `:: ${args[1]}`;
		if (args.length === 3) {
			logObj.data = args[2];
		} else logObj.data = args.slice(2);
	} else {
		if (args.length === 2) {
			logObj.data = args[1];
		} else logObj.data = args.slice(1);
	}
	const xhr = new XMLHttpRequest();
	xhr.open('POST', LOG_SERVER_URL, true);
	xhr.setRequestHeader('Content-type', 'application/json');
	xhr.send(JSON.stringify(logObj));
	xhr.onload = () => {};
}

// every file will create a wrapped logger with it's context
// in DEV/DIT/FIT:
// - console output by default
// - server side logging of all log messages if app runs on mobile platform
export const logWrapper = context => {
	return function log(...args) {
		// get log message level
		let levelInd = -1;
		let newArgs;

		if (typeof args[0] === 'string') {
			levelInd = LOG_LEVELS.indexOf(args[0].toUpperCase());
		}

		if (levelInd >= 0) {
			newArgs = [].concat(args);
		} else {
			levelInd = 0;
			newArgs = [LOG_LEVELS[levelInd]].concat(args);
		}
		// in your debugger console, you can say "DEBUG='app:components'"
		const DEBUG = window.DEBUG || '*';
		// only log what's been specified in the debugger
		if (
			DEBUG === '*' ||
			(typeof context === 'string' && context.indexOf(DEBUG) > -1)
		) {
			// eslint-disable-next-line no-console
			console.log(`${context  } ➜  `, ...newArgs);
		}
		// server side logging if App runs on mobile
		if (window.cordova && isUsingCordova()) {
			postLogMessageToBackend(levelInd, context, newArgs);
		}
	};
};

// in STG/PROD:
// server side logging for INFO and ERROR level messages
export const logWrapperInProd = context => {
	if (isUsingCordova()) {
		return function log(...args) {
			let levelInd = -1;
			if (typeof args[0] === 'string') {
				levelInd = LOG_LEVELS.indexOf(args[0].toUpperCase());
			}
			// send only INFO and ERROR logs
			if (levelInd > 1) {
				postLogMessageToBackend(levelInd, context, [].concat(args));
			}
		};
	}
	return function noop() {};
};

export default PROD_MODE ? logWrapperInProd : logWrapper;
