import { baseUrl } from '../utils/endpoints';
import { getVersion } from '@adplabs/adp-e-common/common/utils/AppInfo';

export const toggleLiveChat = () => ({
	type: 'LIVE_CHAT_TOGGLE'
});

export const setFAQState = (status) => ({
	type: 'SET_FAQ_STATE',
	status
});

export const hideLiveChat = () => ({
	type: 'LIVE_CHAT_SET_HIDDEN',
	hiddenPortal: true
});

export const showLiveChat = () => ({
	type: 'LIVE_CHAT_SET_HIDDEN',
	hiddenPortal: false
});

export const liveChatStarted = () => ({
	type: 'LIVE_CHAT_STARTED'
});

export const liveChatEnded = () => ({
	type: 'LIVE_CHAT_ENDED'
});

export const shouldRenderLiveChatIFrame = render => ({
	type: 'LIVE_CHAT_SHOULD_RENDER_IFRAME',
	render
});

export const liveChatEmailFormCalled = () => ({
	type: 'LIVE_CHAT_EMAIL_FORM_CALLED'
});

export const setIsLiveChatOffHours = isOffHours => ({
	type: 'LIVE_CHAT_SET_OFF_HOURS',
	isOffHours
});

export const loadLiveChatParams = ({ skipAgentStatusMonitoring = false } = {}) => {
	const clientMobileAppVersion = encodeURIComponent(getVersion());
	const queryParams = [
		`clientMobileAppVersion=${clientMobileAppVersion}`,
		`skipAgentStatusMonitoring=${skipAgentStatusMonitoring ? '1' : '0'}`
	].join('&');
	return {
		type: 'LOAD_LIVE_CHAT_PARAMS',
		api: {
			method: 'GET',
			endpoint: `${baseUrl()}/newchat/support/liveChat/loadParams?${queryParams}`
		}
	};
};
