import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ValidationError = ({ error, cssClass = [] }) => {
	if (!error) {
		return null;
	}
	return <span className={classNames('input-error', cssClass)}>{error}</span>;
};

ValidationError.propTypes = {
	error: PropTypes.string,
	cssClass: PropTypes.array
};

export default ValidationError;
