import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import {	// eslint-disable-line import/no-cycle
	apiMiddleware,
	pollingMiddleware,
	socketConnectionMiddleware,
	socketIoMiddleware,
	postMessageMiddleware
} from '../middlewares';

import reducers from '../reducers';

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__
	? window.__REDUX_DEVTOOLS_EXTENSION__()
	: f => f;

const storeCache = {
	store: null
};

const configureStore = (initialState = {}) => {
	if (storeCache.store === null) {
		storeCache.store = createStore(
			reducers,
			initialState,
			compose(
				applyMiddleware(
					thunkMiddleware,
					apiMiddleware,
					pollingMiddleware,
					socketConnectionMiddleware,
					socketIoMiddleware,
					postMessageMiddleware
				),
				devTools
			)
		);
	}

	return storeCache.store;
};

const getStore = () => storeCache.store;

const clearStore = () => {
	storeCache.store = null;
};

export default configureStore;
export { clearStore, getStore };
