export const toggleNavigationMenuPopup = () => ({
	type: 'TOGGLE_NAVIGATION_MENU_POPUP'
});

export const showNavigationMenuPopup = () => ({
	type: 'SHOW_NAVIGATION_MENU_POPUP'
});

export const hideNavigationMenuPopup = () => ({
	type: 'HIDE_NAVIGATION_MENU_POPUP'
});
