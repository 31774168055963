import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as queryString from 'query-string';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { formContainerClasses, submitContainerClasses } from './utilClasses';
import { ActionButton } from '@adplabs/adp-e-common/ui-input/components/ActionButton';
import { BaseFontSizeContext } from '@adplabs/adp-e-common/common/utils/BaseFontSizeContext';

const intlNamespace = 'mobile:AuthErrorView';

const ErrorView = ({ onGoBack }) => {
	const { msg } = queryString.parse(location.search);
	const adjustForLargeBaseText = useContext(BaseFontSizeContext).fontSize > 35;

	return (
		<section>
			<h2 className={classNames('mb-8', 'mb-6', 'font-demi', {
				'text-2xs': adjustForLargeBaseText
			})}>
				{eIntl.formatMessage(`${intlNamespace}.header`)}
			</h2>

			<div className={formContainerClasses}>
				<h3
					className={classNames(
						'mt-0',
						'mb-6',
						'font-demi',{
							'text-3xs': adjustForLargeBaseText
						}
					)}
				>
					{msg || eIntl.formatMessage(`${intlNamespace}.msgAuthError`)}
				</h3>

				<ActionButton
					onClick={onGoBack}
					className={classNames(
						'link',
						submitContainerClasses(false),
						!adjustForLargeBaseText ? 'text-base': 'text-4xs',
						'font-bold'
					)}
				>
					{eIntl.formatMessage(`${intlNamespace}.labelBackToLoginOptions`)}
				</ActionButton>
			</div>
		</section>
	);
};

ErrorView.propTypes = {
	onGoBack: PropTypes.func
};

export default ErrorView;

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../../i18n/mobile-${locale}`)
});
