function blurActiveElement() {
	return new Promise((resolve, reject) => {
    // Blur active element, especially from iframe >.<
    // https://labs-trac.es.ad.adp.com/e/ticket/952
		const activeElement = document.activeElement;
		if (activeElement && typeof activeElement.blur === 'function') {
			activeElement.blur();
			resolve(true);
		} else {
			resolve(false);
		}
	});
}

module.exports = blurActiveElement;
