import React from 'react';
import PropTypes from 'prop-types';
import withProfileHandler from './withProfileHandler';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { peopleServicesURL } from '../utils/endpoints';
import { setHeaderTitleToken } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';
import { updatePersonLocale } from '../actions/user';
import { SkipToNav } from '../components/SkipToNav';
import {
	PersonTiles,
	PersonIndexTile,
	PersonProfile,
	getSelfPersonDetails
} from '@adplabs/adp-e-common/ui-people';
import {
	supportedLanguages,
	initLocale
} from '../initLocale';
const peoplePersonService = `${peopleServicesURL}/v1/person`;

const intlNamespace = 'mobile:PersonView';

class PersonProfileView extends React.Component {
	static defaultProps = {
		isPersonProfileOpen: false,
		loadingTiles: true,
		tiles: []
	};

	constructor(props) {
		super(props);
		this.handleChangeLanguageSelectIcon = this.handleChangeLanguageSelectIcon.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { dispatch, selectedPerson } = this.props;
		if (!prevProps.socket.connected && this.props.socket.connected) {
			dispatch(getSelfPersonDetails(selectedPerson, peoplePersonService));
		}
	}

	componentDidMount() {
		const { dispatch, selectedPerson } = this.props;
		dispatch(getSelfPersonDetails(selectedPerson, peoplePersonService));

		dispatch(setHeaderTitleToken(`${intlNamespace}.header`));

		dispatch(
			setSearchSettings({
				hidden: true
			})
		);
	}

	handleChangeLanguageSelectIcon(locale) {
		initLocale(locale)
			.then(() => {
				this.props.dispatch(updatePersonLocale(locale));
			})
			.catch(err => {
				// eslint-disable-next-line no-console
				console.log(err);
			});
	}

	renderPeopleIndex() {
		const { loadingTiles, tiles } = this.props;
		if (loadingTiles || !tiles) {
			return null;
		}
		const indexTile = tiles.find(
			tile => tile.tileIdentifier === PersonTiles.PersonIndexTile
		);
		if (!indexTile) {
			return null;
		}
		return (<PersonIndexTile
			tile={indexTile}
			isMyself
			onChangeLanguageSelectIcon={this.handleChangeLanguageSelectIcon}
			showLanguage={true}
			defaultLanguage={this.props.defaultLanguage}
			languages={supportedLanguages}
		/>);
	}

	renderPersonProfile() {
		const { loadingTiles, selectedPerson, tiles, context } = this.props;
		if (loadingTiles) {
			return null;
		}

		return (
			<div className={'person-card-body-container mobile-person-profile'}>
				<span>
					<PersonProfile
						person={{ ...selectedPerson }}
						tiles={tiles}
						context={context}
						onActionClick={this.props.handleActionClick}
						open
					/>
				</span>
			</div>
		);
	}

	renderContent() {
		const { loadingTiles } = this.props;
		if (loadingTiles) {
			return null;
		}

		const indexTile = this.renderPeopleIndex();
		const personProfile = this.renderPersonProfile();
		return (
			<div className="adp-e-mobile-timeline-personcard-container">
				<div className="person-card-header-thin">{indexTile}</div>
				{personProfile}
			</div>
		);
	}

	render() {
		const peopleContent = this.renderContent();

		return (
			<section
				className="people-view-container"
				role="main"
				aria-label="Person profile view"
			>
				<SkipToNav />
				<div className="adp-e-mobile-content">{peopleContent}</div>
			</section>
		);
	}
}

PersonProfileView.propTypes = {
	loadingTiles: PropTypes.bool,
	selectedPerson: PropTypes.shape({
		associateID: PropTypes.string,
		personID: PropTypes.string,
		isMyself: PropTypes.bool
	}),
	context: PropTypes.shape({
		countryCode: PropTypes.string
	}),
	socket: PropTypes.object,
	tiles: PropTypes.arrayOf(PropTypes.object),
	dispatch: PropTypes.func,
	handleActionClick: PropTypes.func,
	defaultLanguage: PropTypes.string
};

const mapStateToProps = ({ people, socket, user }) => ({
	loadingTiles: people.loadingTiles,
	myself: people.myself,
	selectedPerson: people.selectedPerson,
	socket,
	tiles: people.tiles,
	context: people.context,
	defaultLanguage: user.localeCode
});

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

eIntl.addPart({
	name: 'mobile:Connection',
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

export { PersonProfileView };
export default connect(mapStateToProps)(withProfileHandler(PersonProfileView));
