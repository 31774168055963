import React from 'react';
import PropTypes from 'prop-types';
import IconPencil from '@adplabs/adp-e-common/ui-svg/icon-pencil';

export default class EditableMessage extends React.Component {
	onEditClick = () => {
		this.context.dispatch({
			type: 'GOTO',
			data: this.props.inputID
		});
	};

	render() {
		return (
			<div style={{ display: 'flex' }}>
				<div style={{ flex: 1, position: 'relative' }}>
					{this.props.children}
				</div>
				{this.props.actor === 'user' ? (
					<div
						style={{
							width: 30,
							paddingLeft: 5,
							paddingRight: 5,
							alignSelf: 'flex-end'
						}}
					>
						<IconPencil onClick={this.onEditClick} />
					</div>
				) : (
					undefined
				)}
			</div>
		);
	}
}

EditableMessage.propTypes = {
	inputID: PropTypes.string,
	actor: PropTypes.string,
	children: PropTypes.any
};

EditableMessage.contextTypes = {
	dispatch: PropTypes.func
};
