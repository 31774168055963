// eslint-disable-next-line import/no-cycle
import AppSettingsCoordinator from '../coordinators/AppSettingsCoordinator';

import _log from '../log';
const log = _log('app:utils:initialize-coordinators');

export default function initializeCoordinators() {
	// initialize method of every Coordinator has to be a Promise!
	return Promise.all([AppSettingsCoordinator.initialize()]).catch(error => {
		log('ERROR', `initializeCoordinators: ${  error.message}`, error);
	});
}
