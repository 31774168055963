import React from 'react';
import _log from '../log';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import Haptics from '@adplabs/adp-e-common/common/utils/Haptics';
import appHistory from '../appHistory';
import { getLastVisited } from '../utils/trackUser';
import { SubmitQRCode } from '../utils/Auth';
import session from '../UserSession';

const log = _log('app:user-qr-code-reader-view');
const intlNamespace = 'mobile:QRCodeReaderView';

class QRCodeReaderView extends React.Component {
	constructor() {
		super();

		this.initQRScanner = this.initQRScanner.bind(this);
		this.scanQRSuccess = this.scanQRSuccess.bind(this);
		this.scanQRFailure = this.scanQRFailure.bind(this);
		this.onQRScannerScanned = this.onQRScannerScanned.bind(this);
		this.onQRScannerPrepared = this.onQRScannerPrepared.bind(this);
		this.redirectTo = this.redirectTo.bind(this);
		this.user = session.getUserSync();
		this.pathToRedirect = '/chat';
	}

	initQRScanner() {
		if (window.QRScanner) {
			// https://www.npmjs.com/package/cordova-plugin-qrscanner
			window.QRScanner.prepare(this.onQRScannerPrepared);
		} else {
			this.scanQRFailure('Plugin missing');
		}
	}

	scanQRSuccess(code) {
		SubmitQRCode(code)
			.then(res => {
				log('DEBUG', `scanQRSuccess: ${res.status} - ${res.statusText}`);
				const haptics = Haptics.getInstance();
				haptics.successVibration();
				this.redirectTo();
			})
			.catch(err => {
				log('ERROR', 'scanQRSuccess', err);
				alert(eIntl.formatMessage(`${intlNamespace}.INTERNET_UNAVAILABLE`));
			});
	}

	scanQRFailure(err) {
		log('ERROR', 'scanQRFailure', err);
		if (err.code && err.name) {
			if (
				err.name === 'CAMERA_ACCESS_DENIED' ||
				err.name === 'CAMERA_ACCESS_RESTRICTED'
			) {
				const redirectToSettings = window.confirm(
					eIntl.formatMessage(`${intlNamespace}.permissionError`)
				);
				if (redirectToSettings) {
					if (window.cordova.plugins.settings) {
						// log('DEBUG', 'scanQRFailure: openNativeSettings');
						window.cordova.plugins.settings.open(
							'application_details',
							() => {
								this.redirectTo();
							},
							() => {
								log('ERROR', 'scanQRFailure: openNativeSettings: failure');
								window.alert(
									eIntl.formatMessage(
										`${intlNamespace}.OPEN_SETTINGS_UNAVAILABLE`
									)
								);
								this.redirectTo();
							}
						);
					} else {
						log('ERROR', 'scanQRFailure: openNativeSettings: is not active');
						window.alert(
							eIntl.formatMessage(`${intlNamespace}.OPEN_SETTINGS_UNAVAILABLE`)
						);
						this.redirectTo();
					}
				} else {
					this.redirectTo();
				}
			}
		} else {
			window.alert(eIntl.formatMessage(`${intlNamespace}.UNEXPECTED_ERROR`));
			this.redirectTo();
		}
	}

	onQRScannerScanned(err, text) {
		if (err) {
			// an error occurred, or the scan was canceled (error code `6`)
			log('ERROR', 'onQRScannerScanned', err);
			window.QRScanner.destroy(status => {
				this.scanQRFailure(err);
			});
		} else {
			// The scan completed, display the contents of the QR code:
			log('DEBUG', 'onQRScannerScanned', text);
			window.QRScanner.destroy(status => {
				this.scanQRSuccess(text);
			});
		}
	}

	onQRScannerPrepared(err, status) {
		if (err) {
			log('ERROR', 'onQRScannerPrepared', err);
			window.QRScanner.destroy(status => {
				this.scanQRFailure(err);
			});
			return;
		}
		if (typeof status === 'object' && status.authorized === true) {
			window.QRScanner.show();
			window.QRScanner.scan(this.onQRScannerScanned);
		} else if (typeof status === 'object' && status.denied === true) {
			// permissions denied and scanning is disabled. We can
			// try to ask the user to change their mind, but we'll have to send them
			// to their device settings with `QRScanner.openSettings()`.
			this.scanQRFailure({
				_message: `QRScanner status: ${  JSON.stringify(status)}`
			});
		} else {
			// we didn't get permission, but we didn't get permanently denied. (On
			// Android, a denial isn't permanent unless the user checks the "Don't
			// ask again" box.) We can ask again at the next relevant opportunity.
			this.scanQRFailure({
				_message: `QRScanner status: ${  JSON.stringify(status)}`
			});
		}
	}

	redirectTo(path) {
		const nextPath = path || this.pathToRedirect;
		appHistory.push(nextPath);
	}

	componentDidMount() {
		try {
			const lastVisited = getLastVisited();
			this.pathToRedirect = lastVisited.path || '/chat';
			this.initQRScanner();
			window.scrollTo(0, 0);
		} catch (err) {
			log('ERROR', 'componentDidMount', err);
		}
	}

	componentWillUnmount() {
		if (window.QRScanner) {
			try {
				window.QRScanner.destroy(status => {
					log('DEBUG', 'componentWillUnmount', { status });
				});
			} catch (err) {
				log('ERROR', 'componentWillUnmount', err);
			}
		}
	}

	getBrandAssetURL(client) {
		const { brandAssets = [] } = client || {};
		const brandAssetForURL = brandAssets.find(
			brandAsset => brandAsset.brandAssetCode === 'URL'
		);
		if (brandAssetForURL) {
			return brandAssetForURL.brandAssetValue;
		}
		return '';
	}

	renderHostBanner() {
		const { client } = this.user || {};
		const brandAssetURL = this.getBrandAssetURL(client);

		if (!brandAssetURL) {
			return null;
		}

		const bannerText = eIntl.formatMessage(`${intlNamespace}.bannerText`);
		const [txt1, txt2] = bannerText.split('{url}');

		return (
			<div
				className="qr-code-reader-host-banner"
				role="banner"
				aria-label={bannerText.replace('{url}', brandAssetURL)}
			>
				<span>{txt1}&nbsp;</span>
				<span style={{ fontWeight: 'bold' }}>{brandAssetURL}</span>
				<span>&nbsp;{txt2}</span>
			</div>
		);
	}

	render() {
		return (
			<section
				className="qr-code-reader-view-container"
				role="main"
				aria-label="QR code reader view"
			>
				<div className="qr-code-reader-header" />
				<div className="adp-e-mobile-content">
					<div className="qr-code-reader">
						<div className="qr-code-reader-box">
							<div className="qr-code-reader-box-top">
								<div className="qr-code-reader-box-left" />
								<div className="qr-code-reader-box-right" />
							</div>
							<div className="qr-code-reader-box-bottom">
								<div className="qr-code-reader-box-left" />
								<div className="qr-code-reader-box-right" />
							</div>
						</div>
					</div>
					{this.renderHostBanner()}
				</div>
			</section>
		);
	}
}

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

export default QRCodeReaderView;
