// eslint-disable-next-line import/no-cycle
import initializeCoordinators from './utils/initializeCoordinators.js';
import { getLatestRecordedGeolocation } from './utils/geolocation_old.js';
import moment from 'moment-timezone';
import { SOCKET_SERVER_ENDPOINT } from './constants';

import _log from './log';
const log = _log('app:socket-library');
import io from 'socket.io-client';

class SocketLib {
	constructor() {
		this.user = null;
		this.socket = null;
		this.socketConnectOpts = {
			reconnection: true,
			reconnectionDelay: 1000,
			reconnectionDelayMax: 5000,
			reconnectionAttempts: Infinity,
			query: {
				timezone: moment.tz.guess()
			},
		};
	}

	initSocket(cb) {
		this.socket.on('connect', () => {
			log('DEBUG', `Socket connected. Id: ${this.socket.id}`);
			if (typeof cb === 'function') {
				cb();
			}

			// TODO: do we need to already have personInfo before calling this?
			initializeCoordinators().catch(e => {
				log('ERROR', `initializeCoordinators promise call error: ${e.message}`);
			});
		});

		this.socket.on('error', error => {
			// Only using a warning, since an error will attempt to send the error back to the
			// server over a socket that isn't currently working, setting up a bad cycle.
			log('WARN', 'Socket error:', error);
			if (
				error &&
				typeof error.type !== 'undefined' &&
				error.type === 'E_AUTH_ERROR'
			) {
				/**
				 * @todo: review this scenario and verify that user needs to be log out
				 */
				// we should kill socket and log user out
				// Auth.logOut();
			}
		});

		// If socket gets disconnected, let the socket.io lib handle reconnect protocol. Just log the event.
		this.socket.on('disconnect', reason => {
			log('DEBUG', `Client socket disconnected for reason: ${reason}`);
		});

		this.socket.on('connect_error', error => {
			log('WARN', `Client socket connect error: ${error.message}`, error);
		});

		// -----------------------------------------------------------
		// Reconnect Events; this logic is handled by socket.io.
		// Just log the reconnect events.
		// -----------------------------------------------------------
		this.socket.on('reconnect_attempt', attemptNumber => {
			log(
				'DEBUG',
				`Client socket attempting to reconnect. Attempt number ${attemptNumber}`
			);
			getLatestRecordedGeolocation()
				.then(gl => {
					const options = {
						timezone: moment.tz.guess(),
						lat: gl.lat,
						long: gl.lng
					};
					log('DEBUG', 'Client socket connect options', options);
					this.socket.io.opts.query = options;
				})
				.catch(error => {
					log('WARN', 'updateConnectionQuery error', error);
				});
		});

		this.socket.on('reconnect_error', error => {
			log('DEBUG', `Client socket reconnect error: ${error.message}`, error);
		});

		this.socket.on('reconnect', attemptNumber => {
			log(
				'DEBUG',
				`Client socket successfully reconnected after ${attemptNumber} tries`
			);
		});

		this.socket.on('reconnect_failed', () => {
			log(
				'DEBUG',
				'Client socket reconnect failed. Giving up. Should never happen. Reconnect attempts: Infinity '
			);
		});
	}

	getSocketHandle(cb) {
		if (this.socket) {
			try {
				cb(null, this.socket);
			} catch (e) {
				log('ERROR', `Error trying to to run callback: ${e.message}.`);
			}
		} else {
			// In case some other caller calls getSocketHandle() while the
			// existing request is still in progress we just attach the callback
			// to the existing promise
			if (this.getSocketHandlePromise != null) {
				return this.getSocketHandlePromise.then(() => {
					cb(null, this.socket);
				});
			}

			// No socket yet. Acquire it now, the first time.
			this.getSocketHandlePromise = getLatestRecordedGeolocation()
				.then(gl => {
					this.socketConnectOpts.query = {
						timezone: moment.tz.guess(),
						lat: gl.lat,
						long: gl.lng
					};
				})
				.then(() => {
					this.socket = io(SOCKET_SERVER_ENDPOINT, this.socketConnectOpts);
					return new Promise((resolve, reject) => {
						try {
							this.initSocket(() => {
								resolve();
							});
						} catch (error) {
							reject(error);
						}
					});
				})
				.then(() => {
					log('DEBUG', 'Socket initialized');
					this.getSocketHandlePromise = null;
					cb(null, this.socket);
				})
				.catch(error => {
					log('ERROR', 'getSocketHandle error:', error);
					this.getSocketHandlePromise = null;
				});
		}
	}

	isConnected() {
		return this.socket && this.socket.connected;
	}

	// To be used for failing authentication or logging out, both of which will
	// destroy the socket.
	disconnect() {
		if (this.socket && this.socket.connected) {
			this.socket.disconnect();
			this.socket = null;
			this.getSocketHandlePromise = null;
		}
	}

	// Returns true or false for success or failure.
	subscribeMessage(eventName, cb) {
		if (this.socket) {
			this.socket.on(eventName, cb);
			return true;
		} else {
			log(
				'DEBUG',
				`Trying to subscribe to ${  eventName  } but not yet connected`
			);
			return false;
		}
	}

	// Returns true or false for success or failure.
	unsubscribeMessage(eventName, cb) {
		if (this.socket) {
			if (cb === null || cb === undefined) {
				this.socket.removeListener(eventName);
			} else {
				this.socket.removeListener(eventName, cb);
			}
			return true;
		} else {
			log(
				'DEBUG',
				`Trying to unsubscribe from ${  eventName  } but not connected`
			);
			return false;
		}
	}

	// Used when we do not expect any response on our request
	publishMessage(requestEventName, requestOptions) {
		log(`SocketLib.publishMessage: ${requestEventName}`);
		this.getSocketHandle((err, theSocket) => {
			if (err) {
				log('ERROR', 'Problem with getSocketHandle', err);
				throw err;
			} else {
				// emit request event
				theSocket.emit(requestEventName, requestOptions);
			}
		});
	}

	// Assumes the cb is expecting (err, data).
	sendMessage(requestEventName, requestOptions, responseEventName, cb) {
		this.getSocketHandle((err, theSocket) => {
			if (err) {
				log(
					'DEBUG',
					`SockDiags; sendMessage Error listening for response: ${responseEventName}`,
					err
				);

				cb(err, null);
			} else {
				requestOptions.responseEventName = responseEventName;
				log(
					'DEBUG',
					`SockDiags; sendMessage Listening for response: ${responseEventName}`
				);
				theSocket.once(responseEventName, data => {
					if (data && data.err) {
						log('ERROR', 'Error from server:', data.err);
						cb(data.err);
					} else {
						log('DEBUG', `sendMessage: ${responseEventName}`, data);
						cb(null, data);
					}
				});

				// emit request event
				theSocket.emit(requestEventName, requestOptions);
			}
		});
	}
}

export default SocketLib;
