import { isAndroid, isUsingCordova } from './appInfo';
import _log from '../log'; // eslint-disable-line
const log = _log('app:cordovaAPI');

/**
 * allows to check whether pin/keyguard or passcode is setup
 * on iOS and Android platforms
 */
function pinCheck() {
	return new Promise((resolve, reject) => {
		if (window.cordova.plugins.PinCheck) {
			window.cordova.plugins.PinCheck.isPinSetup(
				success => {
					resolve(true);
				},
				fail => {
					log('INFO', 'PinCheck: failure', { fail });
					resolve(false);
				}
			);
		} else {
			log('DEBUG', 'PinCheck: plugin is not enabled');
			resolve(false);
		}
	});
}

/**
 *  checks the fingerprint status on Android platform
 */
function fingerprintAuthCheck() {
	return new Promise((resolve, reject) => {
		if (window.cordova.plugins.FingerprintAuth) {
			window.cordova.plugins.FingerprintAuth.isAvailable(
				result => {
					// Fingerprint Authentication Dialog is available for use
					resolve(true);
				},
				message => {
					log('INFO', 'FingerprintAuth: isAvailable: error', { message });
					resolve(false);
				}
			);
		} else {
			log('DEBUG', 'FingerprintAuth: plugin is not enabled');
			resolve(false);
		}
	});
}

/**
 * checks whether or not the user has a configured fingerprint scanner
 * on iOS platform (Minimum iOS version is 8)
 */
function touchIDCheck() {
	return new Promise((resolve, reject) => {
		if (window.plugins.touchid) {
			window.plugins.touchid.isAvailable(
				type => {
					// type returned to success callback:
					// 'face' on iPhone X,
					// 'touch' on other devices
					resolve(true);
				},
				message => {
					log('DEBUG', 'TouchID: isAvailable: error', {
						message
					});
					resolve(false);
				}
			);
		} else {
			log('DEBUG', 'TouchID: plugin is not enabled');
			resolve(false);
		}
	});
}

export function isDeviceProtected() {
	return new Promise((resolve, reject) => {
		// if Cordova is not enabled, protection verification has no sense,
		// hence return true
		if (!isUsingCordova() || !window.cordova) {
			return resolve(true);
		}

		let protectionEnabled = false;

		Promise.resolve()
			// verify whether PIN protection is enabled
			.then(pinCheck)
			.then(res => {
				if (res) {
					return Promise.resolve(true);
				}
				// if PIN is not enabled
				// verify whether Fingerpprint/FaceRecognition is enabled
				if (isAndroid()) {
					return fingerprintAuthCheck();
				} else {
					return touchIDCheck();
				}
			})
			.then(res => {
				if (res) {
					protectionEnabled = true;
				}
			})
			.catch(error => {
				log('ERROR', 'isDeviceProtected error', error);
			})
			.finally(() => {
				log('INFO', 'isDeviceProtected:', protectionEnabled);
				resolve(protectionEnabled);
			});
	});
}

export function getPlatformSecurityCode() {
	return new Promise((resolve, reject) => {
		if (!isUsingCordova() || !window.cordova) {
			return resolve(null);
		}
		let platformSecurityCode = 'None';
		Promise.resolve()
			.then(pinCheck)
			.then(res => {
				if (res) {
					platformSecurityCode = 'Pin';
					return Promise.resolve(null);
				}
				// if PIN is not enabled
				// verify whether Fingerpprint/FaceRecognition is enabled
				if (isAndroid()) {
					return fingerprintAuthCheck();
				} else {
					return touchIDCheck();
				}
			})
			.then(res => {
				if (res) {
					platformSecurityCode = 'Biometric';
				}
			})
			.catch(error => {
				log('ERROR', 'getPlatformSecurityCode error', error);
			})
			.finally(() => {
				resolve(platformSecurityCode);
			});
	});
}
