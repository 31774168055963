const initialState = {
	title: '',
	titleToken: '',
	savedTitle: null,
	showLanguageSelector: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SAVE_HEADER_TITLE':
			return Object.assign({}, state, {
				savedTitle: state.savedTitle === null ? state.titleToken : state.savedTitle
			});

		case 'RESTORE_HEADER_TITLE':
			return Object.assign({}, state, {
				titleToken: state.savedTitle !== null ? state.savedTitle : state.titleToken,
				savedTitle: null
			});

		case 'SET_HEADER_TITLE':
			return Object.assign({}, state, {
				title: action.title
			});

		case 'SET_HEADER_TITLE_TOKEN':
			return Object.assign({}, state, {
				titleToken: action.titleToken
			});

		case 'SHOW_LANGUAGE_SELECTOR':
			return Object.assign({}, state, {
				showLanguageSelector: action.value
			});

		default:
			return state;
	}
};
