export const setHeaderTitle = title => ({
	type: 'SET_HEADER_TITLE',
	title
});

export const setHeaderTitleToken = titleToken => ({
	type: 'SET_HEADER_TITLE_TOKEN',
	titleToken
});

export const saveHeaderTitle = () => ({
	type: 'SAVE_HEADER_TITLE'
});

export const restoreHeaderTitle = () => ({
	type: 'RESTORE_HEADER_TITLE'
});

export const showLangaugeSelector = value => ({
	type: 'SHOW_LANGUAGE_SELECTOR',
	value
});
