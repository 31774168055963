// eslint-disable-next-line import/no-cycle
import SocketLib from './SocketLib';
import SocketEvents from './SocketEvents';

import _log from './log';
const log = _log('app:client-socket-library');

class ClientSocketLib extends SocketLib {
	constructor() {
		super();

		// TODO; decide on official participant id format.
		this.participantId = parseInt(Math.random() * 100000, 10);
	}

	startConv(payload) {
		// eslint-disable-next-line @typescript-eslint/no-this-alias
		const self = this;
		const responseType = SocketEvents.START_CONV_RESP;
		log('DEBUG', `Structuring the response type:${  responseType}`);
		const promise = new Promise((resolve, reject) => {
			self.sendMessage(
				SocketEvents.START_CONV,
				payload.requestOptions,
				responseType,
				function (err, data) {
					if (err) {
						log('ERROR', `Problem with START_CONV:${  err}`);
						reject(err);
					} else {
						log('DEBUG', 'Success with START_CONV');
						resolve(data);
					}
				}
			);
		});
		return promise;
	}

	invalidateSession(payload = {}) {
		log('DEBUG', 'Calling ClientSockLib.invalidateSession');
		this.publishMessage(SocketEvents.SESSION_INVALIDATE, payload);
	}
}

export default new ClientSocketLib();
