const paramsToQueryString = params => {
	if (!params) {
		return '';
	}
	let query = '?';
	for (const p in params) {
		query += `${p}=${params[p]}&`;
	}
	return query.slice(0, -1);
};

export default paramsToQueryString;
