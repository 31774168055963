import 'isomorphic-fetch';
import merge from 'lodash/merge';
import appHistory from '../appHistory';
import * as Auth from '../utils/Auth';	// eslint-disable-line import/no-cycle

const options = {
	method: 'GET',
	credentials: 'include'
};

const apiMiddleware = store => next => action => {
	// eslint-disable-line
	if (action.api && action.api.endpoint) {
		const config = merge({}, options, action.api);

		fetch(action.api.endpoint, config)
			.then(async response => {
				const responseData = await response.json();

				if (response.status === 403) {
					Auth.logOut();
					throw new Error('Session expired');
				}

				if (response.status >= 400) {
					appHistory.push('/error');
					throw new Error(responseData.message);
				}
				return responseData;
			})
			.then(result =>
				next(
					merge(action, {
						response: result,
						error: false,
						fetching: false
					})
				)
			)
			.catch(e => {
				next(
					merge(action, {
						error: true,
						fetching: false,
						errorMessage: e.message
					})
				);
			});

		action.error = false;
		action.fetching = true;
	}

	return next(action);
};

export default apiMiddleware;
