export const startConversation = ({
	canonical,
	eventID,
	eventParams,
	actionDetails
}) => ({
	type: 'START_CONV',
	emitParams: {
		responseEventName: 'START_CONV_RESP',
		canonical,
		eventID,
		eventParams,
		actionDetails
	}
});

export const conversationStarted = ({
	canonical,
	conversationID,
	conversationStatus
}) => ({
	type: 'CONV_STARTED',
	canonical,
	conversationID,
	conversationStatus
});

export const conversationFinished = ({
	canonical,
	conversationID,
	conversationStatus
}) => ({
	type: 'CONV_FINISHED',
	canonical,
	conversationID,
	conversationStatus
});
