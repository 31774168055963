/* global device */
import { getLatestRecordedGeolocation } from './geolocation_old';
import {
	getPlatformCode,
	getVersion,
	isUsingCordova,
	isVersionSameOrAfter
} from './appInfo';
import * as CordovaSecurity from './cordovaSecurityPluginAPI';
import _log from '../log'; // eslint-disable-line
const log = _log('DeviceParams');

function isRemoteNotificationsEnabled() {
	const isDiagnosticPluginAvailable =
		isUsingCordova() &&
		isVersionSameOrAfter('2.0.2') &&
		window.cordova &&
		window.cordova.plugins &&
		window.cordova.plugins.diagnostic;

	return new Promise((resolve, reject) => {
		if (isDiagnosticPluginAvailable) {
			window.cordova.plugins.diagnostic.isRemoteNotificationsEnabled(
				enabled => {
					resolve(enabled);
				},
				error => {
					reject(error);
				}
			);
		} else resolve(false);
	});
}

class DeviceParams {
	constructor() {
		this.token = '';
		this.deviceID = '';
		this.deviceName = '';
		this.appVersion = '';
		this.platformCode = '';
		this.platformVersion = '';
		this.platformSecurityCode = '';
		this.enabledIndicator = true;
		this.latitude = null;
		this.longitude = null;
		this.showNotificationIndicator = false;
	}

	setToken(token) {
		this.token = token;
	}

	getToken() {
		return this.token;
	}

	setDeviceID(deviceID) {
		this.deviceID = deviceID;
	}

	getDeviceID() {
		if (typeof device !== 'undefined') {
			this.deviceID = device.uuid;
		}
		return this.deviceID;
	}

	setPlatformCode(platformCode) {
		this.platformCode = platformCode;
	}

	setDeviceName(deviceName) {
		this.deviceName = deviceName;
	}

	getDeviceName() {
		return this.deviceName;
	}

	setAppVersion(appVersion) {
		this.appVersion = appVersion;
	}

	getAppVersion() {
		return this.appVersion;
	}

	setPNSPlatformCode(platformCode) {
		this.platformCode = platformCode;
	}

	getPNSPlatformCode() {
		return this.platformCode;
	}

	setPlatformVersion(platformVersion) {
		this.platformVersion = platformVersion;
	}

	getPlatformVersion() {
		return this.platformVersion;
	}

	setPlatformSecurityCode(platformSecurityCode) {
		this.platformSecurityCode = platformSecurityCode;
	}

	getPlatformSecurityCode() {
		return this.platformSecurityCode;
	}

	setLatitude(latitude) {
		this.latitude = latitude;
	}

	getLatitude() {
		return this.latitude;
	}

	setLongitude(longitude) {
		this.longitude = longitude;
	}

	getLongitude() {
		return this.longitude;
	}

	setShowNotificationIndicator(showNotificationIndicator) {
		this.showNotificationIndicator = showNotificationIndicator;
	}

	getShowNotificationIndicator() {
		return this.showNotificationIndicator;
	}

	getDeviceParameters() {
		return {
			deviceID: this.deviceID,
			deviceName: this.deviceName,
			appVersion: this.appVersion,
			platformCode: this.platformCode,
			platformVersion: this.platformVersion,
			platformSecurityCode: this.platformSecurityCode,
			enabledIndicator: this.enabledIndicator,
			latitude: this.latitude,
			longitude: this.longitude,
			showNotificationIndicator: this.showNotificationIndicator
		};
	}

	/**
	 * - device ID
	 * - device name
	 * - application version
	 * - platform code and version
	 * - device security options
	 * - latitude and longitude
	 * - showNotificationIndicator
	 */
	gatherDeviceParameters() {
		return new Promise((resolve, reject) => {
			// verify whether Cordova is enabled
			if (!isUsingCordova()) {
				return resolve({
					...this.getDeviceParameters()
				});
			}
			// cordova-plugin-device defines a global device object,
			// which describes the device's hardware and software
			if (typeof device !== 'undefined') {
				// operating system version
				this.platformVersion = device.version;

				// Universally Unique Identifier
				// Android: Returns a random 64-bit integer (as a string), generated on the device's first boot
				// iPhone: Returns the [UIDevice identifierForVendor] UUID,
				// which is unique and the same for all apps installed by the same vendor.
				this.deviceID = device.uuid;

				// Combination od device's manufacturer and model as name.
				// The value of model is set by the device manufacturer and may be different across versions of the same product.
				this.deviceName = `${device.manufacturer} - ${device.model}`;
			}

			// E app version number
			this.appVersion = getVersion();

			// Supported Push Notification System platform code
			this.platformCode = getPlatformCode();

			// retrieve
			// - platformSecurityCode
			// - geolocation: lat, long
			// - showNotificationIndicator
			Promise.all([
				CordovaSecurity.getPlatformSecurityCode(),
				getLatestRecordedGeolocation(),
				isRemoteNotificationsEnabled()
			])
				.then(res => {
					// res[0] - platformSecurityCode
					// supported code values: None, Pin, Biometric
					this.platformSecurityCode = res[0];

					// res[2] - showNotificationIndicator
					this.showNotificationIndicator = res[2];

					// res[1] - geolocation: lat, long
					this.latitude = res[1].lat;
					this.longitude = res[1].lng;
				})
				.catch(error => {
					log('ERROR', 'gatherDeviceParameters error', error);
				})
				.finally(() => {
					resolve({
						...this.getDeviceParameters()
					});
				});
		});
	}
}

export default new DeviceParams();
