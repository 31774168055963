import { useRouterHistory } from 'react-router-v3-for-frontend-only';
import { createHistory } from 'history';

/* eslint-disable react-hooks/rules-of-hooks */
const authHistory = useRouterHistory(createHistory)({
	queryKey: false,
	basename: '/m'
});

export default authHistory;
