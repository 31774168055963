import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { IconAppleLogo, IconGoogleLogo } from '@adplabs/adp-e-common/ui-svg';
import { appleAuthenticationFeature } from '../../features/appleAuthenticationFeature';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import classNames from 'classnames';
import AuthFooter from '../../components/auth/AuthFooter';
import { BaseFontSizeContext } from '@adplabs/adp-e-common/common/utils/BaseFontSizeContext';

const intlNamespace = 'mobile:AuthLogin';

export default function LoginView({
	onAppleClick,
	onGoogleClick,
	onEmailClick,
	onAllOptionsClick,
	languages,
	defaultLanguage,
	onLanguageChange,
	providerID,
	loading
}) {
	const subheaderMessageKey = providerID ? 'subheaderWelcomeBack' : 'subheader';

	const adjustForLargeBaseText = useContext(BaseFontSizeContext).fontSize > 35;
	const appleSignIn = !appleAuthenticationFeature.isEnabled() ? null : (
		<button onClick={onAppleClick} data-testid="apple-signin-btn" className={classNames({ xxs: adjustForLargeBaseText })}>
			<div className="apple-icon">
				<IconAppleLogo size={24} />
			</div>
			<span className="text">
				{eIntl.formatMessage(`${intlNamespace}.labelSignInApple`)}
			</span>
		</button>
	);

	const googleSignIn = (
		<button onClick={onGoogleClick} data-testid="google-signin-btn" className={classNames({ xxs: adjustForLargeBaseText })}>
			<div className="google-icon">
				<IconGoogleLogo size={24} />
			</div>
			<span className="text">
				{eIntl.formatMessage(`${intlNamespace}.labelSignInGoogle`)}
			</span>
		</button>
	);

	const emailSignIn = (
		<button onClick={onEmailClick} data-testid="email-signin-btn" className={classNames({ xxs: adjustForLargeBaseText })}>
			<div className="email-icon">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
					/>
				</svg>
			</div>
			<span className="text">
				{eIntl.formatMessage(`${intlNamespace}.labelSignInEmail`)}
			</span>
		</button>
	);

	const renderButtons = () => {
		if (!providerID) {
			return (
				<>
					{appleSignIn}
					{googleSignIn}
					{emailSignIn}
				</>
			);
		}

		const buttonToRender =
			providerID === 'apple'
				? appleSignIn
				: providerID === 'google'
					? googleSignIn
					: emailSignIn;

		return (
			<>
				{buttonToRender}

				<button
					type="button"
					className={classNames('font-demi', 'text-blue-700')}
					onClick={onAllOptionsClick}
					data-testid="all-signin-btn"
				>
					{eIntl.formatMessage(`${intlNamespace}.labelAllLoginOptions`)}
				</button>
			</>
		);
	};

	return (
		<>
			<section className="login-options">
				<h2>{eIntl.formatMessage(`${intlNamespace}.header`)}</h2>
				<h3>
					{loading
						? eIntl.formatMessage(`${intlNamespace}.labelLoading`)
						: eIntl.formatMessage(`${intlNamespace}.${subheaderMessageKey}`)}
				</h3>
				{loading ? null : renderButtons()}
			</section>

			{loading ? null : (
				<AuthFooter
					onLanguageChange={onLanguageChange}
					languages={languages}
					defaultLanguage={defaultLanguage}
					className={classNames({ xxs: adjustForLargeBaseText })}
				/>
			)}
		</>
	);
}

LoginView.propTypes = {
	onAppleClick: PropTypes.func,
	onGoogleClick: PropTypes.func,
	onEmailClick: PropTypes.func,
	onAllOptionsClick: PropTypes.func,
	onLanguageChange: PropTypes.func,
	languages: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		})
	),
	defaultLanguage: PropTypes.string,
	providerID: PropTypes.string,
	loading: PropTypes.bool
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../../i18n/mobile-${locale}`)
});
