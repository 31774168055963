// making log into a noop, but keeping logs in place for future debugging.
// this component is likely deprecated outside of the simulator and will be removed
// import _log from '../log';
// const log = _log('app:utils:user-input-queue');
const log = function noop() {};

/**
 * @description A boolean flag used to indiciate that a request is currently being processed.
 * @private
 */
let bRequestInProgress = false;

/**
 * @description We need to enforce some degree of synchronicity with each attempt from
 * the user to change the value of something for which will trigger UPDATE_INTERACTION
 * @private
 */
const userInputQueue = [];

/**
 * @description Add an item to the queue.
 * @export
 */
const addQueueItem = fn => {
	log(
		'INFO',
		`Adding Queue Item: ${fn.name}, Total Items in Queue: ${
			userInputQueue.length
		}`
	);

	// Put the element at the end of the queue
	userInputQueue.push(fn);
};

/**
 * @description Ask the queue for the next item, which is the oldest item on the queue.
 */
const getFirstQueueItem = () => {
	const fn = userInputQueue.shift();

	if (typeof fn === 'function') {
		log('DEBUG', 'Shifting fn from queue', fn.name);

		// Shift the element off the front of the queue
		return fn;
	}
};

/**
 * @description Get the oldest item on the queue and run it.
 */
const runQueueItem = () => {
	const item = getFirstQueueItem();

	if (typeof item === 'function') {
		log(
			'INFO',
			'Running Queue Item',
			item.name,
			`Total Items in Queue: ${  userInputQueue.length}`
		);

		// Flag that we are about to make a request
		setRequestInProgress(true);

		// Run it
		item(runQueueItem);
	} else {
		log('DEBUG', 'There are no queue items left to run.');

		// We attempted to run a queue item, but we've already run the last queue item.
		setRequestInProgress(false);
	}
};

/**
 * @public
 * @description Attempt to run a socket event. If there are no queue items, and there is no request in progress
 * we will run this function immediately - and then check if there's something else in the queue. If there is a
 * request in progress, add it to the queue. If there is already stuff in the queue, add this one to the queue.
 * @param {function} fn The function we will attempt to run. The last argument of fn should be a callback "next"
 * which should be called by fn to attemptToRun nextQueueItem.
 */
export const attemptToRun = fn => {
	log(
		'INFO',
		`Attempting to run ${  fn.name}`,
		`bRequestInProgress: ${  bRequestInProgress}`
	);

	// If there is nothing in the queue, and nothing running, just run it
	if (userInputQueue.length < 1) {
		log('DEBUG', 'There are no items in the queue.');

		if (bRequestInProgress) {
			log(
				'INFO',
				`Attempting to run ${
					fn.name
				} but a request is already in progress. Wait and it will run.`
			);

			// Any time we attempt to run a socket request but there is already a request in progress
			// we should go and move it to the queue because the currently running function will
			// trigger it when it is complete.
			addQueueItem(fn);
		} else {
			// Flag that we are about to make a request
			setRequestInProgress(true);

			log(
				'INFO',
				`No request is currently in progress. While running ${  fn.name}`,
				`bRequestInProgress: ${  bRequestInProgress}`
			);

			// no "next" present when we are running just one single
			fn(function next(err, data) {
				if (err) {
					log('ERROR', `There was an error processing ${  fn.name}`, err);

					// Now, because there was an error, UPDATE_INTERACTION may never get called.
					// In this case, we should manually set the progress flag so that the queue
					// can go ahead and continue even though no interaction was updated.
					setRequestInProgress(false);
				} else {
					log('DEBUG', `YAY WE DONE!${  fn.name}`, bRequestInProgress);
					log('DEBUG', `${fn.name  } data:`, data);
				}

				// Either way, we should attempt to run the next queue item if there is one.
				log('DEBUG', 'Run next queue item');
				runQueueItem();
			});
		}
	} else {
		// Otherwise, add it to the queue
		log('DEBUG', 'Adding item to the empty queue', fn.name);
		addQueueItem(fn);
	}
};

/**
 * @public
 */
export const setRequestInProgress = b => {
	if (typeof b === 'boolean') {
		log('DEBUG', `Setting Request In Progress flag to ${  b}`);
		bRequestInProgress = b;
	}
};

/**
 * @public
 */
export const getRequestInProgress = () => {
	return bRequestInProgress;
};

/**
 * @public
 */
export const getUserInputQueue = () => {
	return userInputQueue;
};
