import React from 'react';
import PropTypes from 'prop-types';
import IconInfoSmall from '@adplabs/adp-e-common/ui-svg/icon-info-small';
import IconTimeline from '@adplabs/adp-e-common/ui-svg/icon-timeline';
import TimelineView from './TimelineView';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { peopleServicesURL } from '../utils/endpoints';
import {
	GroupProfile,
	openGroupProfile,
	closeGroupProfile,
	getSelectedPersonDetails
} from '@adplabs/adp-e-common/ui-people';
import { SkipToNav } from '../components/SkipToNav';

const peoplePersonService = `${peopleServicesURL}/v1/person`;

const intlNamespace = 'mobile:PeopleView';

class GroupProfileView extends React.Component {
	static defaultProps = {
		isPersonProfileOpen: true,
		loadingTiles: true,
		tiles: []
	};

	componentDidMount() {
		const { dispatch, myself, selectedPerson } = this.props;
		if (selectedPerson) {
			dispatch(
				getSelectedPersonDetails(selectedPerson, myself, peoplePersonService)
			);
		}
	}

	componentDidUpdate(prevProps) {
		const { dispatch, myself, selectedPerson } = this.props;
		const personHasChanged = prevProps.selectedPerson !== selectedPerson;

		if (selectedPerson && personHasChanged) {
			dispatch(
				getSelectedPersonDetails(selectedPerson, myself, peoplePersonService)
			);
		}
	}

	renderToggleIcon() {
		const { dispatch, isGroupProfileOpen } = this.props;

		const currentState = isGroupProfileOpen
			? 'adp-e-mobile-person-card-toggle-open'
			: 'adp-e-mobile-person-card-toggle-closed';

		return (
			<div className={`adp-e-mobile-person-card-toggle ${currentState}`}>
				<button onClick={() => dispatch(openGroupProfile())}>
					<div className="background-wrapper">
						<span className="sr-only">Open profile</span>
						<IconInfoSmall />
					</div>
				</button>

				<button onClick={() => dispatch(closeGroupProfile())}>
					<div className="background-wrapper">
						<span className="sr-only">Open timeline</span>
						<IconTimeline />
					</div>
				</button>
			</div>
		);
	}

	renderPersonTimeline() {
		const { isGroupProfileOpen } = this.props;
		if (isGroupProfileOpen) {
			return null;
		}
		return <TimelineView />;
	}

	renderGroupProfile() {
		const { selectedGroup } = this.props;
		const className = selectedGroup ? 'mobile-group' : 'mobile-profile';

		return (
			<div className={`group-card-body-container ${className}`}>
				<GroupProfile />
			</div>
		);
	}

	renderContent() {
		const toggleIcon = this.renderToggleIcon();
		const personTimeline = this.renderPersonTimeline();
		const groupProfile = this.renderGroupProfile();
		return (
			<span>
				<div className="group-card-toogle">{toggleIcon}</div>
				<div className="adp-e-mobile-timeline-personcard-container">
					{personTimeline}
					{groupProfile}
				</div>
			</span>
		);
	}

	render() {
		const peopleContent = this.renderContent();

		return (
			<section
				className="people-view-container"
				role="main"
				aria-label="Group profile view"
			>
				<SkipToNav />
				<div className="adp-e-mobile-content">{peopleContent}</div>
			</section>
		);
	}
}

GroupProfileView.propTypes = {
	isGroupProfileOpen: PropTypes.bool,
	myself: PropTypes.object,
	selectedGroup: PropTypes.object,
	selectedPerson: PropTypes.shape({
		associateID: PropTypes.string,
		personID: PropTypes.string,
		isMyself: PropTypes.bool
	}),
	dispatch: PropTypes.func
};

const mapStateToProps = ({ people }) => ({
	isGroupProfileOpen: people.isGroupProfileOpen,
	loadingTiles: people.loadingTiles,
	myself: people.myself,
	selectedGroup: people.selectedGroup,
	selectedPerson: people.selectedPerson,
	tiles: people.tiles
});

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/${locale}`)
});

eIntl.addPart({
	name: 'mobile:Connection',
	getMessages: locale => require(`../i18n/${locale}`)
});

export { GroupProfileView };
export default connect(mapStateToProps)(GroupProfileView);
