import isEqual from 'lodash/isEqual';
import Logger from '../log';
import { socketServerUrl, baseUrl } from './endpoints';

function isDevEnvironment() {
	const hostname = window.location.hostname;
	return hostname.indexOf('.local.adpeai.com') > 0;
}

const log = Logger('app:BuildInfoHelper');

const defaultBuildInfo = {
	build: null,
	buildTimeStamp: null,
	branchTag: null
};

class BuildInfoHelper {
	constructor() {
		this.buildInfo = {
			frontend: Object.assign({}, defaultBuildInfo),
			wsbe: Object.assign({}, defaultBuildInfo)
		};

		this.getWSBEBuildData = this.getWSBEBuildData.bind(this);
		this.getFrontEndBuildData = this.getFrontEndBuildData.bind(this);
	}

	async /* private */ getWSBEBuildData() {
		try {
			const currentTimeStamp = new Date().getTime();
			const endpointURL = `${socketServerUrl(
				baseUrl()
			)}/healthcheck?ct=${currentTimeStamp}`;
			const response = await fetch(endpointURL);

			if (response.ok) {
				const jsonData = await response.json();
				log('DEBUG', 'getWSBEBuildData', { endpointURL, data: JSON.stringify(jsonData) });
				return jsonData;
			}

			log('ERROR', `getWSBEBuildData error: ${response.status}`, { endpointURL });
			return Object.assign({}, defaultBuildInfo);
		} catch (e) {
			log('ERROR', `getWSBEBuildData error: ${e.message}`);
			return Object.assign({}, defaultBuildInfo);
		}
	}

	async /* private */ getFrontEndBuildData() {
		try {
			if (isDevEnvironment()) {
				// We won't have jenkins.json on local env
				return Object.assign({}, defaultBuildInfo);
			}

			const currentTimeStamp = new Date().getTime();
			const endpointURL = `${baseUrl()}/m/jenkins.json?ct=${currentTimeStamp}`;
			const response = await fetch(endpointURL);

			if (response.ok) {
				const jsonData = await response.json();
				log('DEBUG', 'getFrontEndBuildData', { endpointURL, data: JSON.stringify(jsonData) });
				return jsonData;
			}

			log('ERROR', `getFrontEndBuildData error: ${response.status}`, { endpointURL });
			return Object.assign({}, defaultBuildInfo);
		} catch (e) {
			log('ERROR', `getFrontEndBuildData error: ${e.message}`);
			return Object.assign({}, defaultBuildInfo);
		}
	}

	async loadBuildInfo() {
		const extractBuildInfo = ({ build, buildTimeStamp, branch_tag }) => ({
			build: build || null,
			buildTimeStamp: buildTimeStamp || null,
			branchTag: branch_tag || null
		});
		try {
			const [wsbe, frontend] = await Promise.all([
				this.getWSBEBuildData(),
				this.getFrontEndBuildData()
			]);
			const buildInfo = {
				frontend: extractBuildInfo(frontend),
				wsbe: extractBuildInfo(wsbe)
			};
			return buildInfo;
		} catch (e) {
			log('ERROR', 'loadBuildInfo: Error', e);
			return this.buildInfo;
		}
	}

	async storeBuildInfo() {
		this.buildInfo = await this.loadBuildInfo();
	}

	isValidBuildInfo(buildInfo) {
		const haveInfo = ({ build, buildTimeStamp, branchTag }) =>
			(Boolean(build) || Boolean(buildTimeStamp) || Boolean(branchTag));

		try {
			return haveInfo(buildInfo.wsbe) && haveInfo(buildInfo.frontend);
		} catch (e) {
			log('DEBUG', 'isBuildInfoEmtpy: Error', e);
			return false;
		}
	}

	async checkBuildInfoChanged() {
		const nextBuildInfo = await this.loadBuildInfo();

		if (!this.isValidBuildInfo(nextBuildInfo)) {
			log(
				'DEBUG',
				'checkBuildInfoChanged: unable to retrieve information - assuming no changes detected'
			);
			return false;
		}

		if (isEqual(this.buildInfo, nextBuildInfo)) {
			log('DEBUG', 'checkBuildInfoChanged: no changes detected');
			return false;
		}

		log('INFO', 'checkBuildInfoChanged: build info changed', {
			currentBuild: this.buildInfo,
			nextBuild: nextBuildInfo
		});

		this.buildInfo = nextBuildInfo;
		return true;
	}
}

export default new BuildInfoHelper();
