import { Feature } from './Feature';
import {
	isAndroid,
	isUsingCordova,
	isVersionSameOrAfter
} from '../utils/appInfo';

class AppleAuthenticationFeature extends Feature {
	isEnabled() {
		// verify whether feature is enabled in current environment
		if (!super.isEnabled()) {
			return false;
		}
		// verify platform and build version
		if (isUsingCordova()) {
			// feature is disabled on Android platform
			if (isAndroid()) {
				return false;
			}
			// on iOS platform it is enabled starting from v2.0.0
			return isVersionSameOrAfter('2.0.0');
		}
		return true;
	}
}

export const appleAuthenticationFeature = new AppleAuthenticationFeature([
	'dev',
	'dit',
	'dit1',
	'dit2',
	'fit',
	'fit1',
	'fit2',
	'hf',
	'stg',
	'sb',
	'hf',
	'prod',
	'prod_eu'
]);
