import React from 'react';
import { IconChatSolid } from '@adplabs/adp-e-common/ui-svg/icon-chat-solid';

const Loader = () => (
	<div className="adp-e-loader">
		<IconChatSolid size="40" className="text-primary-700" />
	</div>
);

export default Loader;
