import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import cookies from 'browser-cookies';
import Chat from '../components/Chat';
import session from '../UserSession';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import appHistory from '../appHistory';
import ClientSocketLib from '../ClientSocketLib';
import { getTimeline } from '@adplabs/adp-e-common/ui-timeline';
import { timelineServicesURL } from '../utils/endpoints';
import { connect } from 'react-redux';
import {
	conversationStarted,
	conversationFinished
} from '../actions/conversation';
import {
	addWithInAppMessage,
	getConversationsAlreadyRun,
	removeWithInAppMessage,
	setChatConnected,
	setChatMessageRendered,
	setChatBusy,
} from '../actions/chat';
import { setHeaderTitleToken, showLangaugeSelector } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';
import { setUserInfo } from '../actions/user';
import { setRightPanelComponent } from '../actions/rightPanel';
import {
	logRollAnalyticsEvent,
	RollAnalyticsEvent,
	subscriptionPurchaseEventMapObj
} from '../lib/rollAnalytics/analytics';
import { requestReview, requestReviewController } from '../utils/rateAndReview';
import { isAndroid, isUsingCordova } from '../utils/appInfo';
import { setUserReviewRequestDate } from '../actions/rateAndReview';

import _log from '../log';
const log = _log('app:ChatView');

const intlNamespace = 'mobile:ChatView';

class ChatView extends React.Component {
	constructor(props) {
		super(props);
		this.handleChatSocketStatusChange = this.handleChatSocketStatusChange.bind(
			this
		);
		this.handleConversationStart = this.handleConversationStart.bind(this);
		this.handleConversationFinish = this.handleConversationFinish.bind(this);
		this.handleCustomLocaleChange = this.handleCustomLocaleChange.bind(this);
		this.handleNavigateTotEventID = this.handleNavigateTotEventID.bind(this);
		this.handleMessageEnterViewport = this.handleMessageEnterViewport.bind(
			this
		);
		this.handleMessageLeaveViewport = this.handleMessageLeaveViewport.bind(
			this
		);
		this.handleOnMessageRendered = this.handleOnMessageRendered.bind(this);
		this.handleShowAppRateAndReviewDialog = this.handleShowAppRateAndReviewDialog.bind(
			this
		);
		this.processMessageReviewRequest = debounce(
			this.processMessageReviewRequest.bind(this),
			5000
		);
		this.loadInAppBrowserUrl = debounce(
			this.loadInAppBrowserUrl.bind(this),
			1000
		);

		this.handleChatInputDisabledStateChange = this.handleChatInputDisabledStateChange.bind(this);

		this.isMobile = isUsingCordova();
	}

	handleChatInputDisabledStateChange(disabled, input) {
		const isBusyIndicatorInput = input && input.type === 'BusyIndicator';
		const chatIsBusy = disabled || isBusyIndicatorInput;

		// eslint-disable-next-line no-console
		console.info(' ==> ', JSON.stringify({
			disabled,
			inputType: input && input.type,
			chatIsBusy
		}));

		this.props.dispatch(setChatBusy(chatIsBusy));
	}

	componentDidMount() {
		this.props.dispatch(
			setHeaderTitleToken(`${intlNamespace}.header`)
		);

		this.props.dispatch(
			setSearchSettings({
				hidden: true,
				searchPath: '/search/chat'
			})
		);

		this.processUserReviewState();
	}

	componentDidUpdate(prevProps) {
		this.setDetailsPanelComponent(prevProps);
		this.processUserReviewState();

		if (
			this.props.isDesktopLayout &&
			this.props.activeConversationID &&
			this.latestWithInAppBrowserMessage
		) {
			this.handleMessageEnterViewport(this.latestWithInAppBrowserMessage);
		}
	}

	setDetailsPanelComponent(prevProps) {
		if (!this.props.isDesktopLayout) {
			return;
		}

		const { component: rightPanelComponent } = this.props.rightPanel;
		const { messagesWithInAppView: currentList } = this.props;
		const { messagesWithInAppView: previousList } = prevProps;

		const currentFirstMessage = currentList.length > 0 ? currentList[0] : null;

		if (!currentFirstMessage) {
			if (
				rightPanelComponent &&
				rightPanelComponent.type === 'CHAT_CONVERSATION_TAGS'
			) {
				return;
			}

			if (
				rightPanelComponent &&
				rightPanelComponent.type !== 'CHAT_CONVERSATION_TAGS' &&
				this.props.activeConversationCanonical === 'company.setup'
			) {
				// during company setup, there is no conversations the user can repeat
				// so we keep the last inAppBrowser message we received
				return;
			}

			// show conversation tags
			this.props.dispatch(
				setRightPanelComponent({
					type: 'CHAT_CONVERSATION_TAGS',
					attributes: {}
				})
			);
			return;
		}

		const previousFirstMessage =
			previousList.length > 0 ? previousList[0] : null;

		if (
			previousFirstMessage &&
			previousFirstMessage.messageID === currentFirstMessage.messageID
		) {
			// current viewable messsage with (i) not changed, nothing to do
			return;
		}

		// new message with (i) entered viewport
		this.showInAppBrowserMessage(currentFirstMessage);
	}

	handleChatSocketStatusChange({ connected }) {
		this.props.dispatch(setChatConnected(connected));
	}

	handleConversationStart(event) {
		this.props.dispatch(conversationStarted(event.conversation));
		if (event.conversation.canonical === 'company.setup') {
			this.props.dispatch(showLangaugeSelector(true));
		}
	}

	processUserReviewState() {
		requestReviewController(this.props.dispatch, this.props.rateAndReview);
	}

	handleShowAppRateAndReviewDialog() {
		requestReview(this.props.dispatch).catch(e => {
			log('ERROR', `Problem with handleShowAppRateAndReviewDialog: ${e.message}`);
		});
	}

	loadInAppBrowserUrl(url) {
		this.props.dispatch(
			setRightPanelComponent({
				type: 'IN_APP_BROWSER',
				attributes: {
					userSelection: false,
					url
				}
			})
		);
	}

	showInAppBrowserMessage(message) {
		if (this.props.isDesktopLayout && this.isWithInAppBrowserMessage(message)) {
			const timestamp = message.timestamp || 0;
			const url = get(message, 'content.props.url', '');
			if (timestamp && url) {
				this.loadInAppBrowserUrl(url);
			}
		}
	}

	isWithInAppBrowserMessage(message) {
		const messageType = get(message, 'content.type', '');
		return messageType === 'WithInAppBrowser';
	}

	handleMessageEnterViewport(message) {
		if (!this.props.isDesktopLayout) {
			return;
		}

		if (
			this.isWithInAppBrowserMessage(message) &&
			message.conversationID &&
			this.props.activeConversationID &&
			message.conversationID === this.props.activeConversationID
		) {
			this.props.dispatch(addWithInAppMessage(message));
			this.latestWithInAppBrowserMessage = null;
			return;
		}

		if (
			!this.props.activeConversationID &&
			this.isWithInAppBrowserMessage(message)
		) {
			this.latestWithInAppBrowserMessage = message;
		}
	}

	handleMessageLeaveViewport(message) {
		if (this.isWithInAppBrowserMessage(message)) {
			this.props.dispatch(removeWithInAppMessage(message));
		}
	}

	async handleConversationFinish(event) {
		log('DEBUG', 'ChatView -> handleConversationFinish:', event);
		if (event.conversation.canonical === 'company.setup') {
			this.props.dispatch(showLangaugeSelector(false));
		}

		this.props.dispatch(conversationFinished(event.conversation));

		const { canonical, conversationStatus } = event.conversation;

		if (conversationStatus === 'complete') {
			const personID = this.props.user && this.props.user.personID;
			if (personID) {
				this.props.dispatch(getConversationsAlreadyRun());
			}
			this.triggerAnalyticsForFinishedConv(canonical);
		}
		if (
			canonical === 'company.setup' ||
			canonical === 'company.demo' ||
			canonical === 'personLocale.update'
		) {
			// submit Session.User object invalidation event
			Promise.resolve()
				.then(ClientSocketLib.invalidateSession())
				.then(() => session.getUser())
				.then(() => {
					const user = session.getUserSync();
					this.props.dispatch(setUserInfo(user));
					this.props.dispatch(
						getTimeline({
							timelineService: timelineServicesURL
						})
					);
				})
				.catch(err => {
					log('ERROR', 'handleConversationFinish:', err);
				});
		}
	}

	triggerAnalyticsForFinishedConv(canonical) {
		const { rollAnalyticsEvent, rollAnalyticsEventObj } = this.getAnalyticsEvents(canonical);
		if (rollAnalyticsEvent) {
			logRollAnalyticsEvent(rollAnalyticsEvent, rollAnalyticsEventObj || '');
		}
	}

	getAnalyticsEvents(canonical) {
		const personID = this.props.user && this.props.user.personID;
		const clientID = this.props.user?.clientID || '';

		switch(canonical) {
			case 'company.setup':
				return {
					rollAnalyticsEvent: RollAnalyticsEvent.SUBSCRIPTION_PURCHASE,
					rollAnalyticsEventObj: subscriptionPurchaseEventMapObj(personID, clientID)
				};

			case 'payroll.run': {
				const hasPayrollCookie = cookies.get('e-payroll-run');
				if (hasPayrollCookie) {
					return {};
				}

				cookies.set('e-payroll-run', '1', {
					expires: '2035-01-01',
					domain: window.location.hostname.replace('e.', '')
				});

				return {
					rollAnalyticsEvent: RollAnalyticsEvent.PAYROLL_RUN
				};
			}

			default:
				return {};
		}
	}

	handleCustomLocaleChange(newLocale) {
		if (this.customLocale && this.customLocale === newLocale) {
			return;
		}

		cookies.set('e-accept-language', newLocale, {
			expires: null, // session cookie
			domain: window.location.hostname.replace('e.', '')
		});

		this.customLocale = newLocale;

		window.setTimeout(() => {
			window.location.reload();
		}, 1000);
	}

	handleNavigateTotEventID(eventID) {
		appHistory.replace(`/timeline/${eventID}`);
	}

	processMessageReviewRequest(message) {
		if (message && message.timestamp) {
			this.props.dispatch(
				setUserReviewRequestDate(new Date(message.timestamp), isAndroid())
			);
		}
	}

	handleOnMessageRendered(message) {
		if (this.isMobile) {
			const requestUserReview = get(
				message,
				'content.props.params.showRateAndReviewPopup',
				false
			);
			if (requestUserReview) {
				this.processMessageReviewRequest(message);
			}
		}

		this.props.dispatch(setChatMessageRendered(message));
	}

	render() {
		return (
			<section
				className="chat-view-container"
				role="main"
				aria-label="Chat view"
			>
				<div className="adp-e-mobile-content">
					<Chat
						user={this.props.user}
						oAuthReturnParams={this.props.oAuthReturnParams}
						onSocketStatusChange={this.handleChatSocketStatusChange}
						onConversationStart={this.handleConversationStart}
						onConversationFinish={this.handleConversationFinish}
						onCustomLocaleChange={this.handleCustomLocaleChange}
						onGotoEventID={this.handleNavigateTotEventID}
						onMessageEnterViewport={this.handleMessageEnterViewport}
						onMessageLeaveViewport={this.handleMessageLeaveViewport}
						onMessageRendered={this.handleOnMessageRendered}
						onShowRateAndReviewDialog={this.handleShowAppRateAndReviewDialog}
						onChatInputDisabledStateChange={this.handleChatInputDisabledStateChange}
					/>
				</div>
			</section>
		);
	}
}

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

ChatView.propTypes = {
	activeConversationCanonical: PropTypes.string,
	activeConversationID: PropTypes.string,
	dispatch: PropTypes.func,
	isDesktopLayout: PropTypes.bool,
	messagesWithInAppView: PropTypes.arrayOf(PropTypes.any),
	oAuthReturnParams: PropTypes.object,
	rateAndReview: PropTypes.object,
	rightPanel: PropTypes.any,
	user: PropTypes.object
};

ChatView.defaultProps = {
	activeConversationCanonical: '',
	activeConversationID: '',
	isDesktopLayout: false,
	messagesWithInAppView: [],
	oAuthReturnParams: {},
	rateAndReview: {},
	rightPanel: { component: null }
};

const mapStateToProps = ({
	user,
	layout,
	chat,
	rightPanel,
	rateAndReview
}) => ({
	activeConversationCanonical: chat.activeConversationCanonical,
	activeConversationID: chat.activeConversationID,
	isDesktopLayout: layout.isDesktopLayout,
	messagesWithInAppView: chat.messagesWithInAppView,
	oAuthReturnParams: chat.oAuthReturnParams,
	rateAndReview,
	rightPanel,
	user
});

export default connect(mapStateToProps)(ChatView);
