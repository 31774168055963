import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { startConversation } from '../actions/conversation';
import { runTileEvent } from '@adplabs/adp-e-common/ui-people';
import { peopleServicesURL } from '../utils/endpoints';
const tileEventService = `${peopleServicesURL}/v1/event/run`;

const withProfileHandler = WrappedComponent => {
	class Handler extends Component {
		constructor(props) {
			super(props);
			this.handleActionClick = this.handleActionClick.bind(this);
			this.handleTileEventClick = this.handleTileEventClick.bind(this);
		}

		handleActionClick(canonical, payload, actionDetails) {
			if (!canonical) {
				return;
			}
			const eventParams = Object.assign({}, payload, {
				checkPastEvents: true
			});
			this.props.dispatch(
				startConversation({
					canonical,
					eventParams,
					actionDetails
				})
			);
		}

		handleTileEventClick(payload, viewpointID) {
			this.props.dispatch(
				runTileEvent(
					this.props.selectedPerson,
					tileEventService,
					payload,
					viewpointID
				)
			);
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					handleActionClick={this.handleActionClick}
					handleTileEventClick={this.handleTileEventClick}
				/>
			);
		}
	}

	Handler.propTypes = {
		dispatch: PropTypes.func,
		selectedGroup: PropTypes.object,
		isPersonProfileOpen: PropTypes.bool,
		selectedPerson: PropTypes.shape({
			associateID: PropTypes.string,
			personID: PropTypes.string,
			isMyself: PropTypes.bool
		})
	};

	return Handler;
};

export default withProfileHandler;
