/* global backgroundGeolocation */
/* global BackgroundGeolocation */
import _log from '../log';
const log = _log('app:Geo');

const defaultGeoServiceStatus = {
	isRunning: false,
	locationServicesEnabled: false,
	authorization: 99
	/* these are the possible values for authorization:
	 * Denied = 0,
	 * Allowed = 1,
	 * Always = 1,
	 * Foreground = 2,
	 * NotDetermined = 99,
	 */
};

const geoServiceStatus = Object.assign({}, defaultGeoServiceStatus);

let geoServiceStopOnSuccess = true;
let geoServiceStopOnError = true;

/**
 * geolocationRecorded will be executed every time a geolocation is recorded in the background
 * @param {*} geolocation
 */
function geolocationRecorded(geolocation) {
	// developer: make sure you comment out this log() before deployment
	// geolocation is private personal information and cannot be recorded on any log
	// log('DEBUG', 'geolocationRecorded: current geolocation', geolocation);

	// we must execute the finish method here to inform the native plugin that we are finished,
	// and the background-task may be completed
	if (typeof backgroundGeolocation !== 'undefined') {
		if (geoServiceStopOnSuccess) {
			backgroundGeolocation.finish();
			// once geolocation is recorded we better call the #stop method to turn OFF background-tracking
			geoServiceStop();
		}
	} else if (typeof BackgroundGeolocation !== 'undefined') {
		if (geoServiceStopOnError) {
			geoServiceStop();
		}
	}
}

/**
 * geoServiceFailed will be executed every time service fails
 * @param {*} error
 */
function geoServiceFailed(error) {
	log('ERROR', 'geo service failed:', error);
	if (geoServiceStopOnError) {
		geoServiceStop();
	}
}

/**
 * geoServiceStart should be called to turn ON the service
 * @param {boolean} [true] options.stopOnSuccess
 * @param {boolean} [true] options.stopError
 * Returns a Promise.
 */
export function geoServiceStart(options) {
	geoServiceStopOnSuccess = options ? options.stopOnSuccess || true : true;
	geoServiceStopOnError = options ? options.stopOnError || true : true;

	if (typeof backgroundGeolocation !== 'undefined') {
		geoServiceStatus.isRunning = false;
		geoServiceStatus.locationServicesEnabled = false;
		geoServiceStatus.authorization = 99;
		return new Promise((resolve, reject) => {
			backgroundGeolocation.isLocationEnabled(
				enabled => {
					if (enabled) {
						geoServiceStatus.locationServicesEnabled = true;
						backgroundGeolocation.start(
							() => {
								geoServiceStatus.isRunning = true;
								geoServiceStatus.authorization = 1;
								log('DEBUG', '[ios] running and authorized');
								resolve(true);
							},
							error => {
								geoServiceStatus.isRunning = false;
								if (error.message.indexOf('restricted') !== -1) {
									geoServiceStatus.authorization = 0;
								} else if (error.message.indexOf('denied') !== -1) {
									geoServiceStatus.authorization = 0;
								}
								log('ERROR', '[ios] error trying to start', error);
								log('DEBUG', '[ios] not running and not authorized');
								resolve(false);
							}
						);
					} else {
						log('DEBUG', '[ios] disabled');
						resolve(false);
					}
				},
				error => {
					log(
						'ERROR',
						'[ios] error trying to determine if service is enabled or not',
						error
					);
					resolve(false);
				}
			);
		});
	} else if (typeof BackgroundGeolocation !== 'undefined') {
		return new Promise((resolve, reject) => {
			BackgroundGeolocation.start();
			resolve(true);
		});
	} else {
		geoServiceStatus.isRunning = false;
		geoServiceStatus.locationServicesEnabled = false;
		geoServiceStatus.authorization = 99;
		const opts = {
			maximumAge: 10 * 60 * 1000, // 10 minutes
			timeout: 1 * 60 * 1000 // 1 minute
		};
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(result) => {
					log(
						'DEBUG', '[web browser] geo service started'
					);
					geoServiceStatus.locationServicesEnabled = true;
					geoServiceStatus.authorization = 1;
					resolve(true);
				},
				(err) => {
					log('ERROR', '[web browser] error trying to start geo service', err);
					resolve(false);
				},
				opts);
		});
	}
}

/**
 * geoServiceStop should be called to turn OFF the service
 */
export function geoServiceStop() {
	if (typeof backgroundGeolocation !== 'undefined') {
		backgroundGeolocation.stop();
	} else if (typeof BackgroundGeolocation !== 'undefined') {
		BackgroundGeolocation.stop();
	}
}

/**
 * geoServiceInit is being called on #deviceReady when cordova is enabled and ready,
 * it configures the cordova plugin, provides callback and starts the service
 * Returns a Promise (geoServiceStart).
 */
export function geoServiceInit() {
	const stationaryRadius = 500; // in meters, when stopped, the minimum distance the device must move beyond the stationary location for aggressive background-tracking to engage.
	const distanceFilter = 500; // in meters
	const stopOnTerminate = true; // force a stop() when the application terminated
	const activityType = 'Other'; // this affects iOS GPS algorithm
	const pauseLocationUpdates = true; // Pauses location updates when app is paused
	const saveBatteryOnBackground = true; // Switch to less accurate significant changes and region monitory when in background
	const maxLocations = 5;
	// if you change fastestInterval, interval, activitiesInterval then review maxLocationAgeInSeconds
	// because they need to be compatible
	const fastestInterval = 1 * 60 * 1000; // 1 minute
	const interval = 5 * 60 * 1000; // 5 minutes
	const activitiesInterval = interval;

	if (typeof backgroundGeolocation !== 'undefined') {
		backgroundGeolocation.configure(geolocationRecorded, geoServiceFailed, {
			desiredAccuracy: 1000, // in meters
			stationaryRadius,
			distanceFilter,
			stopOnTerminate,
			activityType,
			pauseLocationUpdates,
			saveBatteryOnBackground,
			maxLocations,
			fastestInterval,
			interval,
			activitiesInterval
			/* we cannot disable notifications on Android!
			notificationsEnabled: false
			*/
		});
		return geoServiceStart();
	} else if (typeof BackgroundGeolocation !== 'undefined') {
		BackgroundGeolocation.on('stationary', geolocationRecorded);
		BackgroundGeolocation.on('location', geolocationRecorded);
		BackgroundGeolocation.on('error', geoServiceFailed);
		BackgroundGeolocation.on('start', () => {
			log('DEBUG', '[android] service has been started');
		});
		BackgroundGeolocation.on('stop', () => {
			log('DEBUG', '[android] service has been stopped');
		});
		BackgroundGeolocation.on('background', () => {
			log('DEBUG', '[android] application is in background');
		});

		BackgroundGeolocation.on('foreground', () => {
			log('DEBUG', '[android] application is in foreground');
		});
		BackgroundGeolocation.configure({
			// don't use the activity provider because we don't want to ask the user for permisson
			// and also because we modified the cordova plugin and app for android to don't support it.
			locationProvider: BackgroundGeolocation.RAW_PROVIDER,
			desiredAccuracy: BackgroundGeolocation.LOW_ACCURACY,
			stationaryRadius,
			distanceFilter,
			stopOnTerminate,
			activityType,
			pauseLocationUpdates,
			saveBatteryOnBackground,
			maxLocations,
			fastestInterval,
			interval,
			activitiesInterval
			/* we cannot disable notifications on Android!
			notificationsEnabled: false
			*/
		});
		return geoServiceStart();
	} else {
		// there is nothing to initialize for web browser
		return Promise.resolve(true);
	}
}

/**
 * getLatestRecordedGeolocation returns the lastest recorded geolocation
 * Returns a Promise.
 */
export function getLatestRecordedGeolocation() {
	const gl = {
		enabled: false,
		lat: '',
		lng: '',
		time: 0
	};
	if (typeof backgroundGeolocation !== 'undefined') {
		return new Promise((resolve, reject) => {
			backgroundGeolocation.getLocations(
				locations => {
					// developer: make sure you comment out this log() before deployment
					// geolocation is private personal information and cannot be recorded on any log
					if (locations.length > 0) {
						gl.lat = locations[locations.length - 1].latitude;
						gl.lng = locations[locations.length - 1].longitude;
						gl.time = locations[locations.length - 1].time;
						gl.enabled = true;
						// developer: make sure you comment out this log() before deployment
						// geolocation is private personal information and cannot be recorded on any log
						// log('DEBUG', 'latest geolocation', gl);
						log(
							'DEBUG',
							`[ios] returning latest geolocation, which was recorded at: ${new Date(
								gl.time
							)}`
						);
					} else {
						log('ERROR', '[ios] no geolocations stored');
					}
					resolve(gl);
				},
				error => {
					log('ERROR', '[ios] error trying to get geolocations', error);
					resolve(gl);
				}
			);
		});
	} else if (typeof BackgroundGeolocation !== 'undefined') {
		return new Promise((resolve, reject) => {
			BackgroundGeolocation.getLocations(
				locations => {
					// developer: make sure you comment out this log() before deployment
					// geolocation is private personal information and cannot be recorded on any log
					// log('DEBUG', '[android] geolocations', locations);
					if (locations.length > 0) {
						gl.lat = locations[locations.length - 1].latitude;
						gl.lng = locations[locations.length - 1].longitude;
						gl.time = locations[locations.length - 1].time;
						gl.enabled = true;
						// developer: make sure you comment out this log() before deployment
						// geolocation is private personal information and cannot be recorded on any log
						log(
							'DEBUG',
							`[android] returning latest geolocation, which was recorded at: ${new Date(
								gl.time
							)}`
						);
					} else {
						log('ERROR', '[android] no geolocations stored');
					}
					resolve(gl);
				},
				error => {
					log('ERROR', '[android] error trying to get geolocations', error);
					resolve(gl);
				}
			);
		});
	} else {
		const opts = {
			maximumAge: 10 * 60 * 1000, // 10 minutes
			timeout: 1 * 60 * 1000 // 1 minute
		};
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(result) => {
					gl.lat = result.coords.latitude;
					gl.lng = result.coords.longitude;
					gl.time = result.timestamp;
					gl.enabled = true;
					log(
						'DEBUG',
						`[web browser] returning latest geolocation, which was recorded at: ${new Date(
							gl.time
						)}`
					);
					resolve(gl);
				},
				(err) => {
					log('ERROR', '[web browser] error trying to get geolocation', err);
					resolve(gl);
				},
				opts);
		});
	}
}

/**
 * canGetGeolocation returns true when service is running, enabled and authorized
 * @param {*} status
 */
export function canGetGeolocation(status) {
	// it seems status.isRunning is always false on Android therefore we cannot use it
	const result =
		status.locationServicesEnabled &&
		(status.authorization === 1 || status.authorization === 2);
	log('DEBUG', `can get geolocation: ${result}`);
	return result;
}

/**
 * getGeoServiceStatus returns service status
 * Returns a Promise.
 */
export function getGeoServiceStatus() {
	if (typeof backgroundGeolocation !== 'undefined') {
		return new Promise((resolve, reject) => {
			backgroundGeolocation.isLocationEnabled(
				enabled => {
					geoServiceStatus.locationServicesEnabled = enabled;
					log('DEBUG', '[ios] status', geoServiceStatus);
					resolve(geoServiceStatus);
				},
				error => {
					log(
						'ERROR',
						'[ios] error trying to determine if service is enabled or not',
						error
					);
					geoServiceStatus.locationServicesEnabled = false;
					log('DEBUG', '[ios] status', geoServiceStatus);
					resolve(geoServiceStatus);
				}
			);
		});
	} else if (typeof BackgroundGeolocation !== 'undefined') {
		return new Promise((resolve, reject) => {
			BackgroundGeolocation.checkStatus(status => {
				log('DEBUG', '[android] status', status);
				resolve(status);
			});
		});
	} else {
		const opts = {
			maximumAge: 10 * 60 * 1000, // 10 minutes
			timeout: 1 * 60 * 1000 // 1 minute
		};
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(
				(result) => {
					log(
						'DEBUG', '[web browser] geo service status = enabled'
					);
					geoServiceStatus.locationServicesEnabled = true;
					geoServiceStatus.authorization = 1;
					resolve(geoServiceStatus);
				},
				(err) => {
					log('ERROR', '[web browser] geo service status = disabled', err);
					geoServiceStatus.locationServicesEnabled = false;
					resolve(geoServiceStatus);
				},
				opts);
		});
	}
}

/**
 * getCurrentGeolocation returns users current geolocation
 */
export async function getCurrentGeolocation() {
	const sleep = ms => {
		return new Promise(resolve => setTimeout(resolve, ms));
	};

	let geoLocation = { enabled: false, time: 0, lat: '', lng: '' };
	const now = new Date();
	// we need to re-start geo service to get the current geolocation, not an old one
	const started = await geoServiceStart({
		stopOnSuccess: false,
		stopOnError: false
	});

	if (started) {
		// developer: perhaps this value should come from client configuration so we can change it according to their business needs (for clients fine with older geo locations)
		// if you change this, make sure you review fastestInterval, interval, activitiesInterval
		// because they need to be compatible
		const maxLocationAgeInSeconds = 10 * 60; // 10 minutes
		// developer: perhaps this value should come from client configuration so we can change it according to their business needs (for clients located in bad GPS reception areas)
		const maxAttemptsToGetGeolocation = 4;
		// developer: perhaps this value should come from client configuration so we can change it according to their business needs (for clients located in bad GPS reception areas)
		const timeToWaitBetweenAttemptsInSeconds = 1.0;

		let attempts = 0;
		do {
			attempts = attempts + 1;
			const geoServiceStatus = await getGeoServiceStatus();
			const enabled = canGetGeolocation(geoServiceStatus);

			if (!enabled) {
				break;
			}
			const gl = await getLatestRecordedGeolocation();

			if (gl.time !== 0) {
				const glDate = new Date(gl.time);
				const locationAgeInSeconds =
					(now.getTime() - glDate.getTime()) / 1000;

				if (locationAgeInSeconds < maxLocationAgeInSeconds && attempts>1) {
					geoLocation = {
						enabled,
						time: gl.time,
						lat: gl.lat,
						lng: gl.lng
					};

					break;
				}
			}
			if (attempts < maxAttemptsToGetGeolocation) {
				await sleep(timeToWaitBetweenAttemptsInSeconds * 1000);
			}
		} while (attempts < maxAttemptsToGetGeolocation);

		geoServiceStop();
	}

	if (geoLocation.lat !== '') {
		log('DEBUG', '[PunchClick] Able to get a valid geolocation');
	} else {
		log('DEBUG', '[PunchClick] Unable to get a valid geolocation');
	}

	return geoLocation;
}
