export const getPrefix = () => {
	const pathnames = window.location.pathname.split('/');
	const ignoreIndex = pathnames.findIndex((d) => d === 'm');
	const prefixes = pathnames.slice(0, ignoreIndex);
	const prefix = prefixes.filter((p) => p !== '').join('/');
	return prefix;
};
export const getURLWithPrefix = (path) => {
	const prefixes = getPrefix();
	return prefixes.length > 0 ? `/${prefixes}${path}` : path;
};

export const addPrefixToBaseURL = (baseUrl) => {
	const prefixes = getPrefix();
	return prefixes.length > 0 ? `${baseUrl}/${prefixes}` : baseUrl;
};
