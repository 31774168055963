import get from 'lodash/get';
import {
	confirmAppRateAndReview,
	loadLastUserReview
} from '../actions/rateAndReview';
import Logger from '../log';

export const requestReview = async dispatch => {
	const log = Logger('app:requestReview');
	const appReview = get(window, 'cordova.plugins.AppReview', null);

	if (appReview) {
		try {
			const result = await appReview.requestReview();
			dispatch(confirmAppRateAndReview());
			log('DEBUG', 'cordova.plugins.AppReview plugin result', { result });
		} catch (error) {
			log('ERROR', 'cordova.plugins.AppReview plugin error', { error });
		}
	} else {
		log('WARN', 'cordova.plugins.AppReview not available');
	}
};

export const requestReviewController = (dispatch, rateAndReviewState) => {
	const {
		fetching,
		error,
		isAndroid,
		lastUserReview,
		lastUserReviewFetchDate,
		userReviewRequestDate
	} = rateAndReviewState;

	const log = Logger('app:requestReview');

	if (fetching || error) {
		log('DEBUG', 'requestReviewController: pending or error state', {
			fetching,
			error
		});
		return;
	}

	if (userReviewRequestDate && lastUserReviewFetchDate === null) {
		log(
			'DEBUG',
			'requestReviewController going to fetch last user review (first fetch)',
			{ userReviewRequestDate, lastUserReviewFetchDate }
		);
		dispatch(loadLastUserReview(isAndroid));
		return;
	}

	if (
		userReviewRequestDate &&
		lastUserReviewFetchDate &&
		userReviewRequestDate > lastUserReviewFetchDate
	) {
		log(
			'DEBUG',
			'requestReviewController going to fetch last user review (data is old)',
			{ userReviewRequestDate, lastUserReviewFetchDate }
		);
		dispatch(loadLastUserReview(isAndroid));
		return;
	}

	if (
		lastUserReviewFetchDate &&
		userReviewRequestDate &&
		lastUserReview === null
	) {
		log('DEBUG', 'requestReviewController asking review (first review)', {
			userReviewRequestDate,
			lastUserReviewFetchDate
		});
		requestReview(dispatch).catch(e => {
			log('ERROR', `requestReviewController error at first review promise call: ${e.message}`);
		});
		return;
	}

	if (
		lastUserReviewFetchDate &&
		userReviewRequestDate &&
		lastUserReview &&
		lastUserReview.reviewRequestedDate &&
		lastUserReview.reviewRequestedDate < userReviewRequestDate
	) {
		log('DEBUG', 'requestReviewController asking review (review is old)', {
			userReviewRequestDate,
			lastUserReviewFetchDate
		});
		requestReview(dispatch).catch(e => {
			log('ERROR', `requestReviewController error at new review promise call: ${e.message}`);
		});
	}

	// log('DEBUG', 'requestReviewController not asking review', {
	// 	userReviewRequestDate,
	// 	lastUserReviewFetchDate,
	// 	lastUserReview
	// });
};
