import 'isomorphic-fetch';
import merge from 'lodash/merge';
import * as Auth from '../utils/Auth';	// eslint-disable-line import/no-cycle
import { getEnvironmentName } from '../utils/endpoints';

const options = {
	interval: 3000,
	method: 'GET',
	credentials: 'include'
};

const pollingMiddleware = store => next => action => {
	if (action.polling && action.polling.endpoint && action.polling.callback) {
		const config = merge(options, action.polling);
		const callback = action.polling.callback;
		setTimeout(() => {
			fetch(action.polling.endpoint, config)
				.then(response => {
					if (action.type === 'VALIDATE_SESSION' && response.status === 440) {
						throw new Error('Session expired');
					}
					return response.json();
				})
				.then(response => {
					if (action.type === 'VALIDATE_SESSION' && !response.session) {
						// for app with AccessToken e_authorization_code grant type
						// session does not exist on server side
						// therefore do not run session validation polling in DEV/DIT/FIT/STG
						// and log user out in PROD
						if (getEnvironmentName() === 'PROD') {
							throw new Error('Session expired');
						}
						return;
					}
					store.dispatch(callback({ ...response, error: false }, action)); // eslint-disable-line
				})
				.catch(() => {
					store.dispatch(callback({ error: true })); // eslint-disable-line
					// log SBS Desktop user out if session is over
					if (action.type === 'VALIDATE_SESSION') {
						Auth.logOut();
					}
				});
		}, config.interval);
	}
	return next(action);
};

export default pollingMiddleware;
