import React from 'react';
import PropTypes from 'prop-types';
import find from 'lodash/find';
import {
	displayTimelinePopup,
	toggleEventDetailsExhibition
} from '@adplabs/adp-e-common/ui-timeline';
import { Enums } from '@adplabs/adp-e-common/ui-timeline/types';
import { startConversation } from '../actions/conversation';
import { deserializeDisplayComponent } from '@adplabs/adp-e-common/ui-display/deserializeDisplayComponent';

const withTimelineHandler = WrappedComponent => {
	class Handler extends React.Component {
		constructor(props) {
			super(props);
			this.handleActionClick = this.handleActionClick.bind(this);
			this.handleOpportunityClick = this.handleOpportunityClick.bind(this);
			this.handleEventExpandOrCollapse = this.handleEventExpandOrCollapse.bind(
				this
			);
			this.getCurrentSubjectIDs = this.getCurrentSubjectIDs.bind(this);
			this.isGroupOrPersonTimeline = this.isGroupOrPersonTimeline.bind(this);
			this.getCurrentTimeline = this.getCurrentTimeline.bind(this);
			this.getEventsWithSummary = this.getEventsWithSummary.bind(this);
		}

		getCurrentSubjectIDs() {
			const { selectedGroup, selectedPerson } = this.props;

			if (selectedGroup && !selectedPerson) {
				const { members } = selectedGroup;
				return members.map(member => member.personID);
			}
			if (selectedPerson) {
				return [selectedPerson.personID];
			}

			return null;
		}

		isGroupOrPersonTimeline() {
			const { selectedPerson, selectedGroup } = this.props;
			return selectedPerson || selectedGroup;
		}

		getCurrentTimeline() {
			return this.isGroupOrPersonTimeline()
				? this.props.filteredTimeline
				: this.props.timeline;
		}

		getEventsWithSummary(events) {
			// we may need to dispatch getEventDetails to load summary here on the 1st load
			// otherwise it will render a summary skeleton forever (or until other event is clicked)
			return events
				? events.map(event => {
					if (!event.summary?.content) {
						return event;
					}
					return Object.assign({}, event, {
						summary: {
							content: deserializeDisplayComponent(event.summary.content)
						}
					});
				})
				: [];
		}

		handleActionClick(eventID, timelineAction) {
			const timeline = this.getCurrentTimeline();
			const event = find(timeline.events, it => it.id === eventID);

			if (event && timelineAction === Enums.TimelineAction.doIt) {
				this.props.dispatch(
					startConversation({
						canonical: event.canonical,
						eventID: event.id,
						eventParams: {}
					})
				);
			} else {
				this.props.dispatch(
					displayTimelinePopup({
						eventID,
						eventTitle: event.title,
						timelineAction
					})
				);
			}
		}

		handleOpportunityClick(opportunity) {
			const { canonical, event } = opportunity;

			if (canonical && event && event.eventID) {
				this.props.dispatch(
					startConversation({
						canonical,
						eventID: event.eventID,
						eventParams: event
					})
				);
			}
		}

		handleEventExpandOrCollapse(eventID) {
			this.props.dispatch(toggleEventDetailsExhibition(eventID));
		}

		render() {
			return (
				<WrappedComponent
					{...this.props}
					getCurrentSubjectIDs={this.getCurrentSubjectIDs}
					isGroupOrPersonTimeline={this.isGroupOrPersonTimeline}
					getCurrentTimeline={this.getCurrentTimeline}
					getEventsWithSummary={this.getEventsWithSummary}
					handleActionClick={this.handleActionClick}
					handleOpportunityClick={this.handleOpportunityClick}
					handleEventExpandOrCollapse={this.handleEventExpandOrCollapse}
				/>
			);
		}
	}

	Handler.propTypes = {
		dispatch: PropTypes.func,
		filteredTimeline: PropTypes.object,
		timeline: PropTypes.object,
		selectedGroup: PropTypes.shape({
			title: PropTypes.string,
			members: PropTypes.arrayOf(
				PropTypes.shape({
					associateID: PropTypes.string,
					personID: PropTypes.string,
					documentID: PropTypes.string,
					givenName: PropTypes.string,
					formattedName: PropTypes.string,
					initials: PropTypes.string,
					positionName: PropTypes.string,
					timezone: PropTypes.string
				})
			)
		}),
		selectedPerson: PropTypes.shape({
			associateID: PropTypes.string,
			personID: PropTypes.string
		})
	};

	return Handler;
};

export default withTimelineHandler;
