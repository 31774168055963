import { SCREEN_ORIENTATION_LANDSCAPE } from '../constants';

const MIN_DESKTOP_LAYOUT_WIDTH = 768;

const canRenderDesktopLayout = width => width >= MIN_DESKTOP_LAYOUT_WIDTH;

const getDesktopLayout = (width, orientation) =>
	canRenderDesktopLayout(width) && orientation === SCREEN_ORIENTATION_LANDSCAPE;

const initialState = {
	isDesktopLayout: canRenderDesktopLayout(window.innerWidth),
	orientation: SCREEN_ORIENTATION_LANDSCAPE,
	screenWidth: 0,
	stepUp: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SET_WINDOW_SIZE':
			return Object.assign({}, state, {
				screenWidth: action.width,
				isDesktopLayout: getDesktopLayout(action.width, state.orientation)
			});
		case 'SET_WINDOW_ORIENTATION':
			return Object.assign({}, state, {
				orientation: action.orientation,
				isDesktopLayout: getDesktopLayout(state.screenWidth, action.orientation)
			});
		case 'SET_STEPUP':
			return Object.assign({}, state, {
				stepUp: action.stepUp
			});
		default:
			return state;
	}
};
