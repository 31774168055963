import * as emailValidator from 'email-validator';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';

const intlValidationNamespace = 'mobile:AuthPolicyValidationError';

eIntl.addPart({
	name: intlValidationNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

export function emailPolicyValidation(email) {
	return !emailValidator.validate(email)
		? eIntl.formatMessage(`${intlValidationNamespace}.emailError`)
		: '';
}

export function passwordPolicyValidation(pwd) {
	const policyUpperCase = /(?=.*[A-Z])/;
	const policyLowerCase = /(?=.*[a-z])/;
	const policyLength = /(?=.{8,})/;
	const policyNumbers = /(?=.*[0-9])/;
	if (!policyLength.test(pwd)) {
		return eIntl.formatMessage(`${intlValidationNamespace}.pwdLengthPolicy`);
	}
	if (pwd.length > 256) {
		return eIntl.formatMessage(`${intlValidationNamespace}.pwdLengthMaxPolicy`);
	}
	if (!policyLowerCase.test(pwd)) {
		return eIntl.formatMessage(`${intlValidationNamespace}.pwdLowerCasePolicy`);
	}
	if (!policyUpperCase.test(pwd)) {
		return eIntl.formatMessage(`${intlValidationNamespace}.pwdUpperCasePolicy`);
	}
	if (!policyNumbers.test(pwd)) {
		return eIntl.formatMessage(`${intlValidationNamespace}.pwdNumberPolicy`);
	}
	return '';
}

export function passwordConfirmPolicyValidation(pwd, pwdConfirm) {
	return pwd !== pwdConfirm
		? eIntl.formatMessage(`${intlValidationNamespace}.pwdError`)
		: '';
}

export function codePolicyValidation(code) {
	const confirmationCodePolicy = /^\S{1,20}$/; // Minimum length of 1. Maximum length of 20, matches anything but a whitespace.
	return !code || !confirmationCodePolicy.test(code)
		? eIntl.formatMessage(`${intlValidationNamespace}.codeError`)
		: '';
}
