import config from '../utils/config';

export const setSocketAuthenticated = authenticated => ({
	type: 'SET_SOCKET_AUTHENTICATED',
	authenticated
});

export const getSocketConnection = () => ({
	type: 'GET_SOCKET_CONNECTION'
});

export const setSocketConnection = socket => ({
	type: 'SET_SOCKET_CONNECTION',
	connected: socket.connected
});

export const removeSocketToken = () => ({
	type: 'REMOVE_SOCKET_TOKEN',
	api: {
		method: 'POST',
		endpoint: `${config.httpServerUrl}/workspace/logout`
	}
});

export const setSocketDisconnected = ({ reason, retrying }) => ({
	type: 'SET_SOCKET_DISCONNECTED',
	reason,
	retrying
});

export const setSocketConnectionAttempt = ({
	attemptNumber,
	backoffTimeoutMS
}) => ({
	type: 'SET_SOCKET_CONNECTION_ATTEMPT',
	attemptNumber,
	backoffTimeoutMS
});
