export const matchPath = (pathname, testPath) => {
	const escapedPath = testPath.replace(/\//g, '\\/');
	const regExp = new RegExp(`^(.*)${escapedPath}(\/.*|$)`, 'i'); // eslint-disable-line no-useless-escape
	return regExp.test(pathname);
};

export const getLayoutClassNameFromRoute = route => {
	if (matchPath(route, '/qr-code-reader')) {
		return 'qr-code-reader-view-layout';
	}

	if (matchPath(route, '/chat')) {
		return 'chat-view-layout';
	}

	if (matchPath(route, '/register')) {
		return 'registration-view-layout';
	}

	return '';
};

export const isClientRoute = route => {
	return matchPath(route, '/client');
};

export const isPeopleProfileRoute = route => {
	return matchPath(route, '/people-profile');
};

export const isPeopleRoute = route => {
	return matchPath(route, '/people');
};

export const isChatRoute = route => {
	return matchPath(route, '/chat');
};

export const isTimelineRoute = route => {
	return matchPath(route, '/timeline');
};

const internalContentDomains = ['s3.amazonaws.com', 'docs.google.com'];

export const isExternalContentURL = url => {
	try {
		if (url && url.indexOf('http') === 0) {
			const { host } = new URL(url);
			const isInternal = internalContentDomains.some(
				domain => host.indexOf(domain) > 0
			);

			return !isInternal;
		}

		return true;
	} catch (error) {
		return true;
	}
};
