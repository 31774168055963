import { timelineServicesURL } from '../utils/endpoints';

export const setUserReviewRequestDate = (nextDate, isAndroid) => ({
	type: 'SET_USER_REVIEW_REQUEST_DATE',
	userReviewRequestDate: nextDate,
	isAndroid
});

export const loadLastUserReview = isAndroid => {
	const appStoreFilter = `appStoreCode=${
		isAndroid ? 'GOOGLE_PLAY_STORE' : 'APPLE_APP_STORE'
	}`;
	const limitFilter = 'limit=1';
	const endpoint = `${timelineServicesURL}/v1/conversation/loadRateAndReview`;

	return {
		type: 'LOAD_LAST_USER_REVIEW',
		api: {
			method: 'GET',
			endpoint: `${endpoint}?${appStoreFilter}&${limitFilter}`
		}
	};
};

export const confirmAppRateAndReview = () => ({
	type: 'REGISTER_APP_RATE_AND_REVIEW',
	api: {
		method: 'POST',
		endpoint: `${timelineServicesURL}/v1/conversation/confirmRateAndReview`,
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({})
	}
});
