import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

export function Modal(props) {
	const { displayActionPopup, handleClickOutside, className } = props;
	const styles = {
		// display: displayActionPopup ? 'block' : 'none',
	};
	const onPopupClick = (e) => e.stopPropagation();

	return (
		<div
			className={cn([
				'action-modal',
				className,
				displayActionPopup ? 'action-modal--open' : 'action-modal--close',
			])}
			style={styles}
			role="presentation"
			onClick={handleClickOutside}
		>
			<div className="modal-content" role="presentation" onClick={onPopupClick}>
				{props.children}
			</div>
		</div>
	);
}

Modal.defaultProps = {
	displayActionPopup: false,
	children: [],
	handleClickOutside: () => null,
};

Modal.propTypes = {
	children: PropTypes.element,
	displayActionPopup: PropTypes.bool,
	handleClickOutside: PropTypes.func,
	className: PropTypes.string,
};

export default Modal;
