import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ValidationError from '../../components/auth/ValidationError';
import {
	passwordPolicyValidation,
	passwordConfirmPolicyValidation
} from '../../utils/policyValidation';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import {
	formClasses,
	formContainerClasses,
	inputClasses,
	linkClasses,
	passwordContainerClasses,
	secondaryTitleStyles,
	submitContainerClasses,
	validationErrorClasses
} from './utilClasses';
import { PasswordToggle } from './PasswordToggle';
import { ActionButton } from '@adplabs/adp-e-common/ui-input/components/ActionButton';
import { IconPaperplaneSolid } from '@adplabs/adp-e-common/ui-svg';
import { BaseFontSizeContext } from '@adplabs/adp-e-common/common/utils/BaseFontSizeContext';


const intlNamespace = 'mobile:AuthResetPwdView';

export default class ResetPwdView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pwd: '',
			pwdConfirm: '',
			visiblePwd: false,
			visiblePwdConfirm: false,
			errorMsgPwd: '',
			errorMsgPwdConfirm: ''
		};
		this.onPwdChange = this.onPwdChange.bind(this);
		this.onPwdValidate = this.onPwdValidate.bind(this);
		this.onPwdConfirmChange = this.onPwdConfirmChange.bind(this);
		this.onPwdConfirmValidate = this.onPwdConfirmValidate.bind(this);
		this.handleSubmitPwd = this.handleSubmitPwd.bind(this);
		this.renderSubmitButton = this.renderSubmitButton.bind(this);
		this.togglePwdVisible = this.togglePwdVisible.bind(this);
		this.togglePwdConfirmVisible = this.togglePwdConfirmVisible.bind(this);
	}

	onPwdChange(pwd) {
		this.setState(prevState => ({
			...prevState,
			pwd,
			errorMsgPwd: '',
			errorMsgPwdConfirm: ''
		}));
	}

	onPwdValidate(pwd) {
		if (!pwd) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			pwd,
			errorMsgPwd: passwordPolicyValidation(pwd)
		}));
	}

	onPwdConfirmChange(pwdConfirm) {
		this.setState(prevState => ({
			...prevState,
			pwdConfirm,
			errorMsgPwdConfirm: ''
		}));
	}

	onPwdConfirmValidate(pwdConfirm) {
		if (!pwdConfirm) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			pwdConfirm,
			errorMsgPwdConfirm: passwordConfirmPolicyValidation(
				this.state.pwd,
				pwdConfirm
			)
		}));
	}

	handleSubmitPwd(e) {
		e.preventDefault();
		const errorMsgPwd = passwordPolicyValidation(this.state.pwd);
		const errorMsgPwdConfirm = passwordConfirmPolicyValidation(
			this.state.pwd,
			this.state.pwdConfirm
		);
		if (errorMsgPwd || errorMsgPwdConfirm) {
			this.setState(prevState => ({
				...prevState,
				errorMsgPwd,
				errorMsgPwdConfirm
			}));
		} else {
			this.props.onPwdSubmit(this.state.pwd);
			e.target.reset();
		}
	}

	renderSubmitButton() {
		const enabled =
			this.state.pwd &&
			this.state.pwdConfirm &&
			!this.state.errorMsgPwd &&
			!this.state.errorMsgPwdConfirm;
		return (
			<ActionButton
				type="submit"
				primary
				className={submitContainerClasses(this.props.loading)}
				disabled={!enabled}
			>
				<span className="sr-only">
					{eIntl.formatMessage(`${intlNamespace}.labelReset`)}
				</span>
				<IconPaperplaneSolid />
			</ActionButton>
		);
	}

	togglePwdVisible(e) {
		e.preventDefault();
		return this.setState(prevState => ({
			...prevState,
			visiblePwd: !prevState.visiblePwd
		}));
	}

	togglePwdConfirmVisible(e) {
		e.preventDefault();
		return this.setState(prevState => ({
			...prevState,
			visiblePwdConfirm: !prevState.visiblePwdConfirm
		}));
	}

	render() {
		const baseFontSize = this.context.fontSize;
		const adjustForLargeBaseText = baseFontSize > 35;

		return (
			<section className="reset-password">
				<h2 className={classNames({ 'text-2xs': adjustForLargeBaseText })}>{eIntl.formatMessage(`${intlNamespace}.header`)}</h2>

				<h3 {...secondaryTitleStyles(adjustForLargeBaseText)}>
					{eIntl.formatMessage(`${intlNamespace}.subheader`)}
				</h3>

				<div className={formContainerClasses(baseFontSize >= 28)}>
					<form onSubmit={this.handleSubmitPwd}>
						<div
							className={passwordContainerClasses(
								!!this.state.errorMsgPwd,
								true,
								adjustForLargeBaseText
							)}
						>
							<label htmlFor="newPassword" className="sr-only">
								{eIntl.formatMessage(`${intlNamespace}.placeholderPassword`)}
							</label>

							<input
								id="newPassword"
								type={this.state.visiblePwd ? 'text' : 'password'}
								placeholder={eIntl.formatMessage(
									`${intlNamespace}.placeholderPassword`
								)}
								name="pwd"
								autoComplete="new-password"
								value={this.state.pwd}
								onChange={e => this.onPwdChange(e.target.value)}
								className={inputClasses(adjustForLargeBaseText)}
								onBlur={e => this.onPwdValidate(e.target.value)}
							/>

							<PasswordToggle
								show={this.state.visiblePwd}
								onToggle={this.togglePwdVisible}
								hidePasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srHidePassword`
								)}
								showPasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srShowPassword`
								)}
								className={classNames({
									'p-0': baseFontSize >= 33 && !adjustForLargeBaseText
								})}
								size={18}
							/>
						</div>

						<ValidationError
							error={this.state.errorMsgPwd}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						<div
							className={passwordContainerClasses(
								!!this.state.errorMsgPwdConfirm,
								false,
								adjustForLargeBaseText
							)}
						>
							<label htmlFor="confirmPassword" className="sr-only">
								{eIntl.formatMessage(
									`${intlNamespace}.placeholderPasswordConfirm`
								)}
							</label>
							<input
								id="confirmPassword"
								type={this.state.visiblePwdConfirm ? 'text' : 'password'}
								placeholder={eIntl.formatMessage(
									`${intlNamespace}.placeholderPasswordConfirm`
								)}
								name="pwdConfirm"
								autoComplete="new-password"
								value={this.state.pwdConfirm}
								onChange={e => this.onPwdConfirmChange(e.target.value)}
								className={inputClasses(adjustForLargeBaseText)}
								onBlur={e => this.onPwdConfirmValidate(e.target.value)}
							/>

							<PasswordToggle
								show={this.state.visiblePwdConfirm}
								onToggle={this.togglePwdConfirmVisible}
								hidePasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srHidePassword`
								)}
								showPasswordSrLabel={eIntl.formatMessage(
									`${intlNamespace}.srShowPassword`
								)}
								className={classNames({
									'p-0': baseFontSize >= 33 && !adjustForLargeBaseText
								})}
								size={18}
							/>
						</div>
						<ValidationError
							error={this.state.errorMsgPwdConfirm}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						{this.renderSubmitButton()}
					</form>

					<div className="mb-2">
						<button
							type="button"
							onClick={this.props.onSignInClick}
							className={classNames(linkClasses(adjustForLargeBaseText), 'p-0')}
						>
							{eIntl.formatMessage(`${intlNamespace}.labelSignIn`)}
						</button>
					</div>
				</div>
			</section>
		);
	}
}

ResetPwdView.contextType = BaseFontSizeContext;

ResetPwdView.propTypes = {
	onSignInClick: PropTypes.func,
	onPwdSubmit: PropTypes.func,
	loading: PropTypes.bool
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../../i18n/mobile-${locale}`)
});
