import { isEqual } from 'lodash';

const initialState = {
	route: 'global',
	componentsByRoute: {},
	component: null,
	lastEventIDLoadedIntoRightPanel: ''
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ROUTE': {
			const nextComponent = Object.prototype.hasOwnProperty.call(state.componentsByRoute, action.route)
				? state.componentsByRoute[action.route]
				: null;

			if (
				state.route === action.route &&
				isEqual(nextComponent, state.component)
			) {
				return state;
			}

			return Object.assign({}, state, {
				route: action.route,
				component: nextComponent
			});
		}

		case 'SET_COMPONENT':
			return Object.assign({}, state, {
				componentsByRoute: Object.assign({}, state.componentsByRoute, {
					[state.route]: action.component
				}),
				component: action.component
			});

		case 'SET_LAST_TIMELINE_EVENT_LOADED_INTO_RIGHT_PANEL':
			return Object.assign({}, state, {
				lastEventIDLoadedIntoRightPanel: action.eventID
			});

		default:
			return state;
	}
};
