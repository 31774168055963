/* global firebase */
import { isAndroid, isUsingCordova, isVersionSameOrAfter } from '../../utils/appInfo';
import _log from '../../log';
const log = _log('app:Firebase');

export const RollAnalyticsEvent = Object.freeze({
	EMPLOYEE_REGISTRATION: 'employee_registration',
	OWNER_REGISTRATION: 'owner_registration',
	PAYROLL_RUN: 'payroll_run',
	REGISTRATION_COMPLETE: 'registration_complete',
	SUBSCRIPTION_PURCHASE: 'subscription_purchase',
	FAQ_PAGE_VIEW: 'faq_page_view'
});

export const subscriptionPurchaseEventMapObj = (personID, clientID) => ({
	price: 0, // Set to 0 for now
	currency: 'usd',
	product_Name: 'Roll core',
	user_id: personID,
	client_id: clientID
});

export const logRollAnalyticsEvent = (event, params) => {
	const fbAnalytics = firebase.analytics();
	if (isUsingCordova()) {
		if((!isAndroid() && isVersionSameOrAfter('3.2.63')) || isVersionSameOrAfter('3.2.61')) {
			// use Roll Analytics for
			// Android 3.2.61
			// iOS 3.2.63
			if (window.RollAnalytics) {
				// Call Android interface
				return window.RollAnalytics.logEvent(event, JSON.stringify(params || {}));
			} else if (
				window.webkit
				&& window.webkit.messageHandlers
				&& window.webkit.messageHandlers.RollAnalytics) {
				// Call iOS interface
				const message = {
					command: 'logEvent',
					name: event,
					parameters: params
				};
				return window.webkit.messageHandlers.RollAnalytics.postMessage(message);
			}
		} else if(((!isAndroid() && isVersionSameOrAfter('3.2.35')) || isVersionSameOrAfter('3.2.36'))) {
			// use Native APIs for mobile platform
			// Android : 3.2.36
			// iOS : 3.2.35
			if (window.AnalyticsWebInterface) {
				// Call Android interface
				return window.AnalyticsWebInterface.logEvent(event, JSON.stringify(params));
			} else if (window.webkit
					&& window.webkit.messageHandlers
					&& window.webkit.messageHandlers.firebase) {
				// Call iOS interface
				const message = {
					command: 'logEvent',
					name: event,
					parameters: params
				};
				return window.webkit.messageHandlers.firebase.postMessage(message);
			}
		}
	}

	log('INFO', 'No native APIs found, using web');
	return fbAnalytics.logEvent(event, params);
};

export const setCUID = (userAccountID) => {
	// use Roll Analytics for
	// Android 3.2.61
	// iOS 3.2.63
	if((!isAndroid() && isVersionSameOrAfter('3.2.63')) || isVersionSameOrAfter('3.2.61')) {
		if (window.RollAnalytics) {
			// Call Android interface
			log('INFO', 'setCUID', { userAccountID });
			window.RollAnalytics.setUserProperty('CUID', userAccountID);
		} else if (window.webkit
				&& window.webkit.messageHandlers
				&& window.webkit.messageHandlers.RollAnalytics) {
			// Call iOS interface
			const message = {
				command: 'setUserProperty',
				name: 'CUID',
				parameters: userAccountID
			};
			log('INFO', 'setCUID', { userAccountID });
			window.webkit.messageHandlers.RollAnalytics.postMessage(message);
		}
	}
};
