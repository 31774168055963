import React from 'react';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { actions } from '@adplabs/adp-e-common/ui-error/actions/helpReminder';
import { animationStates } from '@adplabs/adp-e-common/ui-error/reducers/helpReminder';
// eslint-disable-next-line import/no-cycle
import * as Auth from '../utils/Auth';
import * as ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { ChatStandalone, Actions as ChatActions } from '@adplabs/adp-e-chat-client';
import { getImpersonationMode } from '../utils/impersonation';
import { setChatStore } from '../actions/chat';

let chatDiv;
let chatParentDiv;
let chatScrollTop = 0;

export const destroyChat = () => {
	chatDiv = null;
	chatScrollTop = 0;
	if (chatParentDiv) {
		chatParentDiv.remove();
	}
	chatParentDiv = null;
};

const buildChat = (
	user,
	oAuthReturnParams,
	onShowRateAndReviewDialog,
	onSocketStatusChange,
	onGotoEventID,
	onConversationStart,
	onConversationFinish,
	onCustomLocaleChange,
	onMessageEnterViewport,
	onMessageLeaveViewport,
	onMessageRendered,
	scheduleHelpReminder,
	resetHelpReminder,
	onChatStoreCreated,
	onChatInputDisabledStateChange,
) => {
	// See if Chat exists, if not create it
	if (!chatDiv) {
		chatParentDiv = document.createElement('div');
		chatParentDiv.id = 'chat-container-parent';
		chatParentDiv.className = 'chat-container chat-container--hide';

		document.body.appendChild(chatParentDiv);

		chatDiv = document.createElement('div');
		chatDiv.style.height = '100%';

		chatParentDiv.appendChild(chatDiv);

		ReactDOM.render(
			<ChatStandalone
				appType={'mobile'}
				impersonation={getImpersonationMode(user)}
				oAuthReturnParams={oAuthReturnParams}
				onConversationFinish={onConversationFinish}
				onConversationStart={onConversationStart}
				onCustomLocaleChange={onCustomLocaleChange}
				onGotoEventID={onGotoEventID}
				onLogout={() => Auth.logOut()}
				onMessageEnterViewport={onMessageEnterViewport}
				onMessageLeaveViewport={onMessageLeaveViewport}
				onMessageRendered={onMessageRendered}
				onShowRateAndReviewDialog={onShowRateAndReviewDialog}
				onSocketStatusChange={onSocketStatusChange}
				onStoreCreated={onChatStoreCreated}
				person={{ id: user.personID }}
				onChatInputDisabledStateChange={onChatInputDisabledStateChange}
				onChatInputChange={() => {
					resetHelpReminder();
					scheduleHelpReminder();
				}}
			/>,
			chatDiv
		);
	}
};

/* Chrome resets the scrollTop to zero when a
 * node is moved from one parent to another.
 * We save and restore scrollTop as user navigate from
 * and to chat viewer.
 */
const saveChatScrollTop = () => {
	const chatMessages = document.querySelector('.chat-messages');
	if (chatMessages) {
		chatScrollTop = chatMessages.scrollTop;
	}
};
const restorChatScrollTop = () => {
	const chatMessages = document.querySelector('.chat-messages');
	if (chatMessages) {
		chatMessages.scrollTop = chatScrollTop;
	}
};

class Chat extends React.Component {
	constructor(props) {
		super();
		this.chatParentNode = null;
		this.onChatStoreCreated = this.onChatStoreCreated.bind(this);
		buildChat(
			props.user,
			props.oAuthReturnParams,
			props.onShowRateAndReviewDialog,
			props.onSocketStatusChange,
			props.onGotoEventID,
			props.onConversationStart,
			props.onConversationFinish,
			props.onCustomLocaleChange,
			props.onMessageEnterViewport,
			props.onMessageLeaveViewport,
			props.onMessageRendered,
			props.scheduleHelpReminder,
			props.resetHelpReminder,
			this.onChatStoreCreated,
			props.onChatInputDisabledStateChange,
		);

		this.cancelHelpReminder = this.cancelHelpReminder.bind(this);
	}

	componentDidUpdate(prevProps) {
		const {
			oAuthReturnParams: prevOAuthReturnParams
		} = prevProps;

		if (!isEqual(prevOAuthReturnParams, this.props.oAuthReturnParams)) {
			this.props.chatStore.dispatch(
				ChatActions.setOAuthReturnParams(this.props.oAuthReturnParams)
			);
		}
	}

	componentDidMount() {
		if (this.chatParentNode) {
			this.chatParentNode.appendChild(chatDiv);
			restorChatScrollTop();
		}

		document.addEventListener('click', this.cancelHelpReminder);
		document.addEventListener('keydown', this.cancelHelpReminder);
	}

	onChatStoreCreated(chatStore) {
		this.props.setChatStore(chatStore);
	}

	cancelHelpReminder() {
		if (this.props.helpReminder === animationStates.idle) {
			this.props.cancelHelpReminder();
		}
	}

	componentWillUnmount() {
		if (this.chatParentNode && chatParentDiv) {
			saveChatScrollTop();
			chatParentDiv.appendChild(chatDiv);
			this.chatParentNode = null;
		}

		document.removeEventListener('click', this.cancelHelpReminder);
		document.removeEventListener('keydown', this.cancelHelpReminder);
	}

	render() {
		return (
			<section
				className="chat-container chat-container--show"
				ref={node => {
					this.chatParentNode = node;
				}}
			/>
		);
	}
}

Chat.propTypes = {
	user: PropTypes.object,
	oAuthReturnParams: PropTypes.object,
	onShowRateAndReviewDialog: PropTypes.func,
	onSocketStatusChange: PropTypes.func,
	onGotoEventID: PropTypes.func,
	onChatInputDisabledStateChange: PropTypes.func,
	onConversationStart: PropTypes.func,
	onConversationFinish: PropTypes.func,
	onCustomLocaleChange: PropTypes.func,
	onMessageEnterViewport: PropTypes.func,
	onMessageLeaveViewport: PropTypes.func,
	onMessageRendered: PropTypes.func,
	resetHelpReminder: PropTypes.func,
	setChatStore: PropTypes.func,
	scheduleHelpReminder: PropTypes.func,
	cancelHelpReminder: PropTypes.func,
	helpReminder: PropTypes.string,
	chatStore: PropTypes.object
};

Chat.defaultProps = {
	oAuthReturnParams: {},
	onChatInputDisabledStateChange: null,
	onCustomLocaleChange: null,
	onShowRateAndReviewDialog: null,
	onSocketStatusChange: null,
	onGotoEventID: null,
	onMessageEnterViewport: null,
	onMessageLeaveViewport: null,
	onMessageRendered: null,
	chatStore: null
};

const mapStateToProps = ({ helpReminder, chat }) => ({
	helpReminder,
	chatStore: chat.chatStore
});

const mapDispatchToProps = Object.assign({}, actions, {
	setChatStore: (chatStore) => (dispatch) => {
		dispatch(setChatStore(chatStore));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
