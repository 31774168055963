import { Feature } from './Feature';

class LanguageSelectorFeature extends Feature {
}

export const languageSelectorFeature = new LanguageSelectorFeature([
	'dev',
	'dit',
	'dit1',
	'dit2',
	'fit',
	'fit1',
	'fit2',
	'hf',
	'stg',
	'sb',
	'hf',
	'prod'
]);

