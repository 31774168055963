import React from 'react';
import PropTypes from 'prop-types';
import appHistory from '../appHistory';
import DeviceParams from '../utils/deviceParams';
import Loader from '../components/Loader';
import session from '../UserSession';
import ClientSocketLib from '../ClientSocketLib';
import { ChatStandalone } from '@adplabs/adp-e-chat-client';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { getCompanySetupIndicator, SubmitDeviceToken } from '../utils/Auth';
import { getImpersonationMode } from '../utils/impersonation';
import { getSocketConnection } from '../actions/socket';
import { startConversation } from '../actions/conversation';
import {
	setHeaderTitleToken,
	showLangaugeSelector } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';
import { setUserInfo, updateDeviceInfo } from '../actions/user';
import { RollAnalyticsEvent, logRollAnalyticsEvent } from '../lib/rollAnalytics/analytics';
import * as Auth from '../utils/Auth';

import _log from '../log';
const log = _log('app:chat-registration-view');

const intlNamespace = 'mobile:RegistrationView';
const authNamespace = 'mobile:AuthErrorView';

class RegistrationView extends React.Component {
	constructor(props) {
		super(props);
		log('DEBUG', 'RegistrationView:', this.props.location);
		this.handleConversationStart = this.handleConversationStart.bind(this);
		this.handleConversationFinish = this.handleConversationFinish.bind(this);
		this.pathToRedirect = '/chat';
		this.conversation = null;
		this.state = {
			showChat: true
		};
	}

	componentDidMount() {
		if (this.conversation) {
			const { canonical } = this.conversation;
			const titlePath =
				canonical === 'invite.accept'
					? 'headerInvitation'
					: 'headerRegistration';
			this.props.dispatch(
				setHeaderTitleToken(`${intlNamespace}.${titlePath}`)
			);
		}

		this.props.dispatch(
			setSearchSettings({
				hidden: true
			})
		);
	}

	handleConversationStart(event) {
		log('DEBUG', 'RegistrationView:: handleConversationStart', { event });
		if(event.conversation.canonical === 'user.register') {
			log('DEBUG', 'RegistrationView:: handleConversationStart - showLangaugeSelector');
			this.props.dispatch(showLangaugeSelector(true));
		}
	}

	async handleConversationFinish(event) {
		log('DEBUG', 'RegistrationView:: handleConversationFinish', { event });
		this.setState({ showChat: false });
		let { canonical } = event.conversation;
		const { conversationStatus } = event.conversation;
		if(!canonical) {
			canonical = this.conversation.canonical;
		}
		if(canonical === 'user.register') {
			log('DEBUG', 'RegistrationView:: handleConversationFinish - showLangaugeSelector');
			this.props.dispatch(showLangaugeSelector(false));
		}

		if (
			(canonical === 'user.register' || canonical === 'invite.accept') &&
			conversationStatus === 'complete'
		) {
			// get new user object with PersonID
			Promise.resolve()
				.then(ClientSocketLib.disconnect())
				.then(() => session.getUser())
				.then(user => {
					this.props.dispatch(setUserInfo(user));
					// initiate Chat socket connection
					this.props.dispatch(getSocketConnection());
					if (canonical === 'user.register' && user.personID) {
						return getCompanySetupIndicator();
					} else return { companySetupIndicator: false };
				})
				.then(data => {
					if (canonical === 'user.register') {
						if (data.companySetupIndicator === true) {
							// dispatch company.setup conversation start after WS is reconnected
							initiateCompanySetupConv(
								ClientSocketLib,
								this.props.dispatch,
								startConversation({ canonical: 'company.setup' })
							);
						} else if (data.companyUpdateIndicator === true) {
							// pass ClientID to company-setup conversation
							initiateCompanySetupConv(
								ClientSocketLib,
								this.props.dispatch,
								startConversation({
									canonical: 'company.setup',
									eventParams: {
										clientID: this.props.user.client.clientID
									}
								})
							);
						}
					}
					this.triggerAnalyticsEvents();
					return appHistory.push(this.pathToRedirect);
				})
				.catch(err => {
					return appHistory.push(
						`/error?msg=${err.message ||
							eIntl.formatMessage(`${authNamespace}.authorizationError`)}`
					);
				})
				.finally(() => {
					const user = session.getUserSync();
					if (user.personID !== null && !user.actingAsID) {
						DeviceParams.gatherDeviceParameters()
							.then(params => {
								if (params.deviceID) {
									log('DEBUG', 'DeviceParams: submit update.device', params);
									this.props.dispatch(updateDeviceInfo(params));
								}
								if (DeviceParams.getToken()) {
									return SubmitDeviceToken(DeviceParams.getToken());
								} else return null;
							})
							.then(data => {
								log('DEBUG', 'SubmitDeviceToken: ', data);
							})
							.catch(err => {
								log('ERROR', 'DeviceParameter:', err);
							});
					}
				});
		} else {
			// return appHistory.push(`/error?msg=${'Registration error'}`);
			log('ERROR', 'RegistrationView:: handleConversationFinish error', {
				event
			});
			return appHistory.push(
				`/error?msg=${eIntl.formatMessage(
					`${intlNamespace}.registrationError`
				)}`
			);
		}
	}

	triggerAnalyticsEvents() {
		const { declaredSmallBusinessOwner, personID } = this.props.user;

		/* Roll Analytics Events */
		logRollAnalyticsEvent(RollAnalyticsEvent.REGISTRATION_COMPLETE);
		logRollAnalyticsEvent(declaredSmallBusinessOwner ? RollAnalyticsEvent.OWNER_REGISTRATION : RollAnalyticsEvent.EMPLOYEE_REGISTRATION);
	}

	render() {
		const conversation = {
			canonical: 'user.register',
			params: {
				userAccountID: this.props.user.userAccountID,
				inviteID: this.props.location.query.inviteID
					? this.props.location.query.inviteID
					: '',
				clientReferralIndicator: this.props.location.query?.referralcode === 'true'
			}
		};
		// if user is registered
		if (this.props.user.personID !== null) {
			// and there is inviteID
			if (this.props.location.query.inviteID) {
				// we should launch invite.accept conversation
				conversation.canonical = 'invite.accept';
			} else {
				// otherwise land user in Chat
				appHistory.push(this.pathToRedirect);
				return null;
			}
		}

		this.conversation = conversation;

		return (
			<section
				className="chat-view-container"
				role="main"
				aria-label="Registration view"
			>
				<div className="adp-e-mobile-content">
					{!this.state.showChat ? (
						<Loader />
					) : (
						<ChatStandalone
							active
							conversation={conversation}
							impersonation={getImpersonationMode(this.props.user)}
							key={this.props.user.userAccountID}
							onConversationFinish={this.handleConversationFinish}
							onConversationStart={this.handleConversationStart}
							onLogout={() => Auth.logOut()}
							singleConversationMode
						/>
					)}
				</div>
			</section>
		);
	}
}

RegistrationView.propTypes = {
	dispatch: PropTypes.func,
	location: PropTypes.object,
	user: PropTypes.object
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

const mapStateToProps = ({
	user
}) => ({
	user
});

export default connect(mapStateToProps)(RegistrationView);

function initiateCompanySetupConv(socket, dispatch, conv) {
	if (socket.isConnected()) {
		dispatch(conv);
	} else {
		setTimeout(() => {
			initiateCompanySetupConv(socket, dispatch, conv);
		}, 300);
	}
}
