const ENV = {
	dev: 'e.local.adpeai.com',
	dit: 'e.dit.adpeai.com',
	dit1: 'e.dit1.adpeai.com',
	dit2: 'e.dit2.adpeai.com',
	dit3: 'e.dit3.adpeai.com',
	fit: 'e.fit.adpeai.com',
	fit1: 'e.fit1.adpeai.com',
	fit2: 'e.fit2.adpeai.com',
	hf: 'roll.hf.adpeai.com',
	stg: 'e.stg.adp.ai',
	sb: 'roll.sb.adp.ai',
	prod: 'e.adp.ai',
	prod_eu: 'roll.eu.adp.ai'
};

export class Feature {
	constructor(environments) {
		this.environments = environments;
	}

	isEnabled() {
		if (
			window.location &&
			window.location.hostname &&
			typeof window.location.hostname === 'string' &&
			this.environments.some(
				envAlias =>
					ENV[envAlias] && window.location.hostname.includes(ENV[envAlias])
			)
		) {
			return true;
		}
		return false;
	}
}
