import cn from 'classnames';
/* eslint-disable no-useless-computed-key */
export const inputClasses = (adjustForLargeBaseText) => {
	const textSize = adjustForLargeBaseText ? 'text-4xs' : 'text-base';

	return cn(
		'w-full',
		textSize,
		'pt-1',
		'pb-1'
	);
};

export const passwordContainerClasses = (hasErrorMsg, hasProvider, adjustForLargeBaseText, isFirstInput = false) => cn('password-container', {
	'mt-5': !isFirstInput && !adjustForLargeBaseText,
	'mb-5': !hasErrorMsg && !adjustForLargeBaseText,
	'mt-3': !isFirstInput && adjustForLargeBaseText,
	'mb-3': !hasErrorMsg && adjustForLargeBaseText,
	'mb-4': !hasProvider && !adjustForLargeBaseText,
	'mb-2': !hasProvider && adjustForLargeBaseText
});


export const submitContainerClasses = (disabled, hasProvider) =>
	cn(
		'w-full',
		'text-center',
		'w-full',
		disabled
			? ['background-neutral-200', 'text-neutral-600']
			: ['background-primary-700', 'text-neutral-100'],
		{
			'mt-5': hasProvider,
		}
	);

export const validationErrorClasses = (adjustForLargeBaseText) => cn(
	'text-red-600',
	'block',
	'font-demi',
	{
		'mb-4': !adjustForLargeBaseText,
		'mb-2 text-4xs': adjustForLargeBaseText,
	}
);

export const formContainerClasses = (adjustForLargeBaseText) => {
	const padding = adjustForLargeBaseText ? 'p-2' : 'p-5';

	return cn('form-container', padding);
};

export const pageTitleClasses = cn('mt-0', 'mb-10');

export const linkClasses = (adjustForLargeBaseText) => {
	const textSize = adjustForLargeBaseText ? 'text-4xs' : 'text-base';

	return cn(textSize, 'font-medium', 'text-blue-700');
};

export const secondaryTitleStyles = (adjustForLargeBaseText) => {
	const textSize = adjustForLargeBaseText ? 'text-4xs' : 'text-base';
	const mb = adjustForLargeBaseText ? 'mb-3' : 'mb-6';

	return {
		style: { lineHeight: '1em' },
		className: cn(
			textSize,
			mb,
			// TODO: This should be a design token
			// in adp-e-common
			'font-regular'
		)
	};
};
