import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TimelineItem } from '@adplabs/adp-e-common/ui-timeline/components/TimelineItem';
import { mediaServerURLBuilder } from '../utils/personAvatar';
import withTimelineHandler from './withTimelineHandler';

class EventDetailsView extends React.Component {
	constructor(props) {
		super(props);
	}

	getEvents() {
		const { events } = this.props;
		if (!events || events.length === 0) {
			return [];
		}

		const currentSubjectIDs = this.props.getCurrentSubjectIDs()
			? this.props
				.getCurrentSubjectIDs()
				.sort()
				.join()
			: '';
		const exclusiveKeyPerTimeline = Array.isArray(currentSubjectIDs)
			? currentSubjectIDs.join('-')
			: currentSubjectIDs;

		const eventsWithSummary = this.props.getEventsWithSummary(events);

		return eventsWithSummary
			.filter(event => this.props.eventIDs.includes(event.id))
			.map(event => (
				<TimelineItem
					key={`${event.id}-${exclusiveKeyPerTimeline}`}
					onActionClick={this.props.handleActionClick}
					onOpportunityClick={this.props.handleOpportunityClick}
					hideAvatar={false}
					mediaServerURLBuilder={mediaServerURLBuilder}
					appendixVisible={event.appendixVisible}
					expandOrCollapseItem={this.handleItemExpandOrCollapse}
					isAppendixEnabled
					isCollapsingEnabled={false}
					{...event}
				/>
			));
	}

	render() {
		return (
			<div className="timeline-container">
				<div className="timeline-items">{this.getEvents()}</div>
			</div>
		);
	}
}

EventDetailsView.propTypes = {
	eventIDs: PropTypes.array,
	filteredTimeline: PropTypes.object,
	timeline: PropTypes.object,
	events: PropTypes.array,
	selectedGroup: PropTypes.shape({
		title: PropTypes.string,
		members: PropTypes.arrayOf(
			PropTypes.shape({
				associateID: PropTypes.string,
				personID: PropTypes.string,
				documentID: PropTypes.string,
				givenName: PropTypes.string,
				formattedName: PropTypes.string,
				initials: PropTypes.string,
				positionName: PropTypes.string,
				timezone: PropTypes.string
			})
		)
	}),
	selectedPerson: PropTypes.shape({
		associateID: PropTypes.string,
		personID: PropTypes.string
	}),
	getCurrentSubjectIDs: PropTypes.func,
	isGroupOrPersonTimeline: PropTypes.func,
	getCurrentTimeline: PropTypes.func,
	getEventsWithSummary: PropTypes.func,
	handleActionClick: PropTypes.func,
	handleOpportunityClick: PropTypes.func,
	handleEventExpandOrCollapse: PropTypes.func
};

const mapStateToProps = ({ timeline, filteredTimeline, people }) => ({
	events: timeline.events,
	filteredTimeline,
	timeline,
	selectedGroup: people.selectedGroup,
	selectedPerson: people.selectedPerson
});

export default connect(mapStateToProps)(withTimelineHandler(EventDetailsView));
