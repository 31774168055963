import { isUsingCordova, isVersionSameOrAfter } from './appInfo';
import _log from '../log'; // eslint-disable-line
const log = _log('app:utils');

export function setKey(name, value) {
	if (
		isUsingCordova() &&
		isVersionSameOrAfter('1.0.77') &&
		window.cordova &&
		window.cordova.plugins &&
		window.cordova.plugins.SecureKeyStore
	) {
		window.cordova.plugins.SecureKeyStore.set(
			res => {
				log('DEBUG', `SecureKeyStore: set ${name}`, res);
			},
			err => {
				log('ERROR', `SecureKeyStore: set ${name}`, err);
			},
			name,
			value
		);
	}else {
		localStorage.setItem(name, value);
	}
}

export function getKey(name) {
	return new Promise((resolve, reject) => {
		if (
			isUsingCordova() &&
			isVersionSameOrAfter('1.0.77') &&
			window.cordova &&
			window.cordova.plugins &&
			window.cordova.plugins.SecureKeyStore
		) {
			window.cordova.plugins.SecureKeyStore.get(
				res => {
					log('DEBUG', `SecureKeyStore: get ${name}`, res);
					resolve(res);
				},
				err => {
					log('ERROR', `SecureKeyStore: get ${name}`, err);
					resolve(null);
				},
				name
			);
		} else {
			const item = localStorage.getItem(name);
			return resolve(item);
		}
	});
}

export function removeKey(name) {
	return new Promise((resolve, reject) => {
		if (
			isUsingCordova() &&
			isVersionSameOrAfter('1.0.77') &&
			window.cordova &&
			window.cordova.plugins &&
			window.cordova.plugins.SecureKeyStore
		) {
			window.cordova.plugins.SecureKeyStore.remove(
				res => {
					log('DEBUG', `SecureKeyStore: remove ${name}`, res);
					resolve(res);
				},
				err => {
					log('ERROR', `SecureKeyStore: remove ${name}`, err);
					resolve(null);
				},
				name
			);
		} else {
			localStorage.removeItem(name);
			return resolve(name);
		}
	});
}
