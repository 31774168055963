// This impersonation.js code was basically copied from adp-e-desktop-workspace
// Method getImpersonationMode is a straight forward copy
// However, isImpersonating is slightly different: instead of checking for user.impesonatorPersonID,
// we check for user.impersonatedPersonID.
// As discussed at https://chat.adp.ai/channel/impersonation?msg=ihGnK6qXK3bFAGj89:
// in our current model Front-End should always have original user data. WSBE has been modified to return impersonation session info on UserProfile call, but I changed property names:
// 		personInfo.impersonatedPersonID
// 		personInfo.impersonatedFormattedName
// 		personInfo.impersonatedUserAccountID
// 		personInfo.actingAsID
// 		personInfo.actingAsMode

export const isImpersonating = user => user && user.impersonatedPersonID;

// export const isInFullImpersonationMode = (user) => user && user.actingAsMode === 'Impersonation';

export const getImpersonationMode = user => {
	if (!isImpersonating(user)) {
		return null;
	}
	return user && user.actingAsMode;
};
