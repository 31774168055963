export default (
	state = {
		connected: false,
		authenticated: false,
		logout: false,
		reason: null,
		retrying: false,
		attemptNumber: 0,
		backoffTimeoutMS: 0
	},
	action
) => {
	switch (action.type) {
		case 'SET_SOCKET_AUTHENTICATED':
		case 'SET_SOCKET_CONNECTION':
		case 'GET_SOCKET_CONNECTION':
			return Object.assign({}, state, {
				connected: action.connected || state.connected,
				authenticated: action.authenticated || state.authenticated,
				logout: false,
				reason: null,
				retrying: false,
				attemptNumber: 0,
				backoffTimeoutMS: 0
			});
		case 'REMOVE_SOCKET_TOKEN':
			if (!action.fetching && !action.error) {
				return Object.assign({}, state, {
					connected: false,
					authenticated: false,
					logout: true
				});
			}
			return state;
		case 'SET_SOCKET_DISCONNECTED':
			return Object.assign({}, state, {
				connected: false,
				reason: action.reason,
				retrying: action.retrying
			});
		case 'SET_SOCKET_CONNECTION_ATTEMPT':
			return Object.assign({}, state, {
				attemptNumber: action.attemptNumber,
				backoffTimeoutMS: action.backoffTimeoutMS
			});
		default:
			return state;
	}
};
