import appHistory from '../appHistory';
import Socket from '../ClientSocketLib';

const socketIoMiddleware = () => next => action => {
	if (Socket.isConnected() && action.emitParams && !action.emitted) {
		Socket.getSocketHandle((err, socket) => {
			if (!err) {
				socket.emit(action.socketEvent || action.type, action.emitParams);
			}
		});
		action.emitted = true;
		if (action.type === 'START_CONV') {
			appHistory.push('/chat');
		}
	}

	return next(action);
};

export default socketIoMiddleware;
