import React from 'react';
import PropTypes from 'prop-types';

class HelpDocumentViewer extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { url } = this.props;
		return (
			<div className="adp-e-mobile-content">
				<iframe
					title="In app browser"
					className="adp-e-mobile-expanded-frame"
					src={url}
					frameBorder="0"
				/>
			</div>
		);
	}
}

HelpDocumentViewer.propTypes = {
	url: PropTypes.string.isRequired
};

HelpDocumentViewer.defaultProps = {};

export default HelpDocumentViewer;
