import { LIVE_CHAT_ENGINE_SEND_EMAIL } from '../components/RocketChatLiveSupport';

const COMPANY_SETUP_CANONICAL = 'company.setup';

const reduceConversationStart = (state, canonical) => {
	if (state.hiddenIcon && canonical === COMPANY_SETUP_CANONICAL) {
		return Object.assign({}, state, {
			hiddenIcon: false
		});
	}

	return state;
};

const reduceSetUserInfo = (state, user) => {
	const { liveSupportAuthorization } = user;

	return Object.assign({}, state, {
		hiddenIcon: !liveSupportAuthorization
	});
};

const initialState = {
	hiddenIcon: true,
	hiddenPortal: true,
	isOffHours: false,
	iconSelected: false,
	conversationActive: false,
	liveChatEngineType: '',
	businessHoursDefinition: [],
	shouldRenderLiveChatIFrame: false,
	userToken: '',
	isFAQHidden: true,
	loadingLiveChatParams: false,
	errorLoadingLiveChatParams: false
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SET_USER_INFO':
		case 'UPDATE_USER_INFO':
			return reduceSetUserInfo(state, action.response);

		case 'LIVE_CHAT_TOGGLE':
			return Object.assign({}, state, {
				hiddenPortal: !state.hiddenPortal,
				iconSelected: state.hiddenPortal && !state.conversationActive
			});

		case 'SET_FAQ_STATE':
			return Object.assign({}, state, {
				isFAQHidden: action.status,
				iconSelected: !action.status
			});

		case 'LIVE_CHAT_SET_HIDDEN':
			return Object.assign({}, state, {
				hiddenPortal: action.hiddenPortal,
				iconSelected: !action.hiddenPortal && !state.conversationActive
			});

		case 'LIVE_CHAT_STARTED':
			return Object.assign({}, state, {
				conversationActive: true,
				iconSelected: false
			});

		case 'LIVE_CHAT_ENDED':
			return Object.assign({}, state, {
				conversationActive: false,
				iconSelected: true
			});

		case 'START_CONV':
			return reduceConversationStart(state, action.emitParams.canonical);

		case 'CONV_STARTED': {
			return reduceConversationStart(state, action.canonical);
		}

		case 'LOAD_LIVE_CHAT_PARAMS': {
			if (action.error) {
				return Object.assign({}, state, {
					errorLoadingLiveChatParams: true,
					loadingLiveChatParams: false
				});
			}

			if (action.fetching) {
				return Object.assign({}, state, {
					errorLoadingLiveChatParams: false,
					loadingLiveChatParams: true
				});
			}

			return Object.assign({}, state, {
				liveChatEngineType: action.response.liveChatEngineType,
				businessHoursDefinition: action.response.businessHoursDefinition,
				userToken: action.response.userToken,
				loadingLiveChatParams: false,
				errorLoadingLiveChatParams: false,
			});
		}

		case 'LIVE_CHAT_SHOULD_RENDER_IFRAME': {
			return Object.assign({}, state, {
				shouldRenderLiveChatIFrame: action.render
			});
		}

		case 'LIVE_CHAT_SET_OFF_HOURS': {
			return Object.assign({}, state, {
				isOffHours: action.isOffHours
			});
		}

		case 'LIVE_CHAT_EMAIL_FORM_CALLED': {
			return Object.assign({}, state, {
				liveChatEngineType: LIVE_CHAT_ENGINE_SEND_EMAIL
			});
		}

		default:
			return state;
	}
};
