/* global SafariViewController */
import Logger from '../log';

export default url => {
	const log = Logger('app:redirectToExternalUrl');
	try {
		if (window.cordova) {
			SafariViewController.isAvailable(available => {
				if (available) {
					SafariViewController.show(
						{
							url,
							hidden: false,
							animated: true,
							enterReaderModeIfAvailable: true,
							transition: 'curl'
						},
						res => log('DEBUG', 'redirectToExternalUrl', { res }),
						msg => log('DEBUG', 'redirectToExternalUrl', { msg })
					);
				} else {
					log('DEBUG', 'SafariViewController not available');
				}
			});
		} else {
			window.open(url, '_blank', 'location=yes');
		}
	} catch (e) {
		log('ERROR', `Error ${e}`);
	}
};
