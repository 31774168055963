import { addPrefixToBaseURL } from './Prefix';

export const baseUrl = () =>
	addPrefixToBaseURL(
		[document.location.protocol, '//', document.location.hostname].join('')
	);

export const httpServerUrl = (base = baseUrl()) => base;

export const socketServerUrl = (base = baseUrl()) =>
	base
		.replace('//e.', '//ws.')
		.replace('//external.', '//ws.')
		.replace('//roll', '//ws');

export const chatUrl = (base = baseUrl()) =>
	[base, '/chatserver/direct/e'].join('');

export const relayAPI = (base = baseUrl()) => `${base}/newchat/relay/v1`;

export const generateFacebookSubscriptionEndpoint = () => {
	if (document.location.hostname.indexOf('.fit.') > 0) {
		return 'https://m.me/adpebotfit';
	}

	return 'https://m.me/adpebot';
};

export const generateSlackSubscriptionEndpoint = (base = baseUrl()) =>
	`${base}/newchat/login/slack`;

export const timelineServicesURL = `${socketServerUrl()}/timeline`;

export const peopleServicesURL = `${socketServerUrl()}/people`;

export const getEnvironmentName = () => {
	const hostName =
		window.location && window.location.hostname ? window.location.hostname : '';

	if (hostName.indexOf('.local.adpeai.com') > 0) {
		return 'DEV';
	}

	if (/^(.+)\.dit(.*)\.adpeai.com$/gi.test(hostName)) {
		/* e.dit, e.dit1, e.ditN  */
		return 'DIT';
	}

	if (/^(.+)\.fit(.*)\.adpeai.com$/gi.test(hostName)) {
		/* e.fit, e.fit1, e.fitN  */
		return 'FIT';
	}

	if (hostName.indexOf('.hf.adpeai.com') > 0) {
		return 'HF';
	}

	if (hostName.indexOf('.sb.adp.ai') > 0) {
		return 'SB';
	}

	if (hostName.indexOf('.stg.adp.ai') > 0) {
		return 'STG';
	}

	if (hostName.indexOf('.adp.ai') > 0) {
		return 'PROD';
	}

	return 'PROD';
};
