const initialState = {
	hidden: true
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'TOGGLE_NAVIGATION_MENU_POPUP':
			return Object.assign({}, state, {
				hidden: !state.hidden
			});

		case 'SHOW_NAVIGATION_MENU_POPUP':
			return Object.assign({}, state, {
				hidden: false
			});

		case 'HIDE_NAVIGATION_MENU_POPUP':
			return Object.assign({}, state, {
				hidden: true
			});

		default:
			return state;
	}
};
