const adjustUserReview = userReview => {
	if (userReview) {
		// we only story the date (not time), so the review is valid
		// for the entire day - should never ask again on on same day
		// adjusting time to last possible moment of the day
		const [reviewDay] = userReview.reviewRequestedDate.split('T');
		return Object.assign({}, userReview, {
			reviewRequestedDate: new Date(`${reviewDay}T23:59:59.999Z`)
		});
	}

	return userReview;
};

const initialState = {
	fetching: false,
	error: false,
	isAndroid: false,
	lastUserReview: null,
	lastUserReviewFetchDate: null,
	userReviewRequestDate: null
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'REGISTER_APP_RATE_AND_REVIEW': {
			if (action.fetching || action.error) {
				return Object.assign({}, state, {
					fetching: action.fetching,
					error: action.error
				});
			}

			return Object.assign({}, state, {
				fetching: false,
				error: false,
				lastUserReview: action.response.userReview
			});
		}

		case 'SET_USER_REVIEW_REQUEST_DATE': {
			if (state.userReviewRequestDate) {
				const currentDate = state.userReviewRequestDate.getTime();
				const nextDate = action.userReviewRequestDate.getTime();
				if (nextDate <= currentDate) {
					return state;
				}
			}

			return Object.assign({}, state, {
				error: false,
				userReviewRequestDate: action.userReviewRequestDate,
				isAndroid: action.isAndroid
			});
		}

		case 'LOAD_LAST_USER_REVIEW': {
			if (action.fetching || action.error) {
				return Object.assign({}, state, {
					fetching: action.fetching,
					error: action.error
				});
			}

			const [lastUserReview = null] = action.response.userReviews || [];

			return Object.assign({}, state, {
				fetching: false,
				error: false,
				lastUserReview: adjustUserReview(lastUserReview),
				lastUserReviewFetchDate: new Date()
			});
		}

		default:
			return state;
	}
};
