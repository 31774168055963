import React from 'react';
import PropTypes from 'prop-types';
import appHistory from '../appHistory';
import IconInfoSmall from '@adplabs/adp-e-common/ui-svg/icon-info-small';
import IconTimeline from '@adplabs/adp-e-common/ui-svg/icon-timeline';
import session from '../UserSession';
import TimelineView from './TimelineView';
import withProfileHandler from './withProfileHandler';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { peopleServicesURL } from '../utils/endpoints';
import { setHeaderTitleToken } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';
import { updatePersonLocale } from '../actions/user';
import { SkipToNav } from '../components/SkipToNav';
import {
	Tiles,
	IndexTile,
	AssociateProfile,
	openPersonProfile,
	closePersonProfile,
	setSelectedPerson,
	getSelectedPersonDetails,
	getAssociateTileDetails,
} from '@adplabs/adp-e-common/ui-people';
import { IconToggleSwitch } from '@adplabs/adp-e-common/ui-components';
import { supportedLanguages, initLocale } from '../initLocale';

const peopleAssociateService = `${peopleServicesURL}/v1/associate`;

const intlNamespace = 'mobile:PeopleView';

/**
 * Show associate's profile (tiles and timeline)
 */
class PeopleProfileView extends React.Component {
	static defaultProps = {
		isInsideGroupView: false,
		isPersonProfileOpen: true,
		loadingTiles: true,
		tiles: [],
	};

	constructor(props) {
		super(props);
		this.handlePersonClick = this.handlePersonClick.bind(this);
		this.handleTileClick = this.handleTileClick.bind(this);
		this.handleChangeLanguageSelectIcon =
			this.handleChangeLanguageSelectIcon.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			dispatch,
			myself,
			selectedPerson,
			showTimeline,
			isPersonProfileOpen,
		} = this.props;

		if (!prevProps.socket.connected && this.props.socket.connected) {
			dispatch(
				getSelectedPersonDetails(selectedPerson, myself, peopleAssociateService)
			);
		}

		if (showTimeline !== undefined && !showTimeline && !isPersonProfileOpen) {
			dispatch(openPersonProfile());
		}

		const personHasChanged = prevProps.selectedPerson !== selectedPerson;

		if (personHasChanged && selectedPerson) {
			dispatch(
				getSelectedPersonDetails(selectedPerson, myself, peopleAssociateService)
			);
		}
	}

	componentDidMount() {
		const { selectedGroup, dispatch, myself, selectedPerson } = this.props;
		if (selectedPerson && myself && myself.positionID) {
			dispatch(
				getSelectedPersonDetails(selectedPerson, myself, peopleAssociateService)
			);
		}

		if (!selectedGroup) {
			this.props.dispatch(setHeaderTitleToken(`${intlNamespace}.header`));

			this.props.dispatch(
				setSearchSettings({
					hidden: true,
					searchPath: '/search/people',
				})
			);
		}
	}

	handlePersonClick(person) {
		const user = session.getUserSync();
		this.props.dispatch(
			setSelectedPerson(
				Object.assign({}, person, {
					isMyself: user.personID === person.personID,
				})
			)
		);
		if (!this.props.isDesktopLayout) {
			if (this.props.selectedGroup) {
				appHistory.push('/people-group');
			} else {
				appHistory.push('/people-profile');
			}
		}
	}

	handleTileClick(viewpointID) {
		const { myself, selectedPerson, tiles } = this.props;
		const tile = tiles.find((tile) => tile.viewpointID === viewpointID);
		if (tile && tile.config && tile.config.refreshAllowed) {
			this.props.dispatch(
				getAssociateTileDetails(
					viewpointID,
					selectedPerson,
					myself,
					tile.value,
					peopleAssociateService
				)
			);
		}
	}

	handleChangeLanguageSelectIcon(locale) {
		initLocale(locale)
			.then(() => {
				this.props.dispatch(updatePersonLocale(locale));
			})
			.catch((err) => {
				// eslint-disable-next-line no-console
				console.log(err);
			});
	}

	renderPeopleIndex() {
		const { loadingTiles, selectedPerson, tiles } = this.props;
		if (loadingTiles || !tiles) {
			return null;
		}
		const indexTile = tiles.find(
			(tile) => tile.tileIdentifier === Tiles.IndexTile
		);
		if (!indexTile) {
			return null;
		}
		const isMyself = selectedPerson && selectedPerson.isMyself;
		return (
			<IndexTile
				tile={indexTile}
				isMyself={isMyself}
				onChangeLanguageSelectIcon={this.handleChangeLanguageSelectIcon}
				showLanguage={true}
				defaultLanguage={this.props.defaultLanguage}
				languages={supportedLanguages}
			/>
		);
	}

	renderToggleIcon() {
		const {
			loadingTiles,
			isPersonProfileOpen,
			showTimeline,
			groups,
			dispatch,
		} = this.props;

		if (loadingTiles || !showTimeline || groups.length === 0) {
			return null;
		}

		const view = isPersonProfileOpen ? 'Profile View' : 'Timeline view';
		const desc = `Switch Icon - you are currently in ${view}. Click here to toggle between profile and timeline view`;

		return (
			<div className="adp-e-mobile-person-card-toggle">
				<IconToggleSwitch
					desc={desc}
					on={!isPersonProfileOpen}
					icons={{
						left: <IconInfoSmall maxSizes={['16px', '0.70rem']} />,
						right: <IconTimeline maxSizes={['16px', '0.70rem']} />,
					}}
					onClick={() => {
						if (isPersonProfileOpen) {
							dispatch(closePersonProfile());
						} else {
							dispatch(openPersonProfile());
						}
					}}
				/>
			</div>
		);
	}

	renderPersonTimeline() {
		const { isPersonProfileOpen, loadingTiles, showTimeline } = this.props;

		if (loadingTiles || isPersonProfileOpen || !showTimeline) {
			return null;
		}

		if (this.props.isDesktopLayout) {
			return (
				<div
					style={{
						overflowY: 'auto',
						height: 'calc(100% - env(safe-area-inset-bottom, 0px))',
					}}
				>
					<TimelineView isInsidePeopleView />
				</div>
			);
		}

		return <TimelineView isInsidePeopleView />;
	}

	renderPersonProfile() {
		const {
			loadingTiles,
			isPersonProfileOpen,
			selectedGroup,
			selectedPerson,
			tiles,
			context,
			isDesktopLayout,
		} = this.props;
		if (loadingTiles || !isPersonProfileOpen) {
			return null;
		}

		if (!loadingTiles && isPersonProfileOpen) {
			if (tiles?.length === 0) {
				return appHistory.push('/people');
			}
		}

		const associateProfile = (
			<AssociateProfile
				person={{ ...selectedPerson }}
				tiles={tiles}
				context={context}
				onActionClick={this.props.handleActionClick}
				onAvatarClick={this.handlePersonClick}
				onTileClick={this.handleTileClick}
				open={isPersonProfileOpen}
			/>
		);

		if (isDesktopLayout) {
			return (
				<div
					className="adp-e-mobile-expanded-main-content-panel"
					style={{
						overflowY: 'auto',
						height: 'calc(100% - 95px - env(safe-area-inset-bottom, 0px))',
					}}
				>
					<div className="adp-e-mobile-expanded-container-tile">
						{associateProfile}
					</div>
				</div>
			);
		}

		const className = selectedGroup ? 'mobile-group' : 'mobile-profile';
		return (
			<div className={`person-card-body-container ${className}`}>
				<span>{associateProfile}</span>
			</div>
		);
	}

	renderContent() {
		const { loadingTiles } = this.props;
		if (loadingTiles) {
			return null;
		}

		const indexTile = this.renderPeopleIndex();
		const toggleIcon = this.renderToggleIcon();
		const personTimeline = this.renderPersonTimeline();
		const personProfile = this.renderPersonProfile();
		return (
			<div className="adp-e-mobile-timeline-personcard-container">
				<div className="person-card-header">
					{indexTile}
					{toggleIcon}
				</div>
				{personTimeline}
				{personProfile}
			</div>
		);
	}

	render() {
		const peopleContent = this.renderContent();

		if (this.props.isInsideGroupView || this.props.isDesktopLayout) {
			return peopleContent;
		}

		return (
			<section
				className="people-view-container"
				role="main"
				aria-label="People profile view"
			>
				<SkipToNav />
				<div className="adp-e-mobile-content">{peopleContent}</div>
			</section>
		);
	}
}

PeopleProfileView.propTypes = {
	isInsideGroupView: PropTypes.bool,
	isPersonProfileOpen: PropTypes.bool,
	loadingTiles: PropTypes.bool,
	myself: PropTypes.object,
	selectedGroup: PropTypes.object,
	selectedPerson: PropTypes.shape({
		associateID: PropTypes.string,
		personID: PropTypes.string,
		isMyself: PropTypes.bool,
	}),
	context: PropTypes.shape({
		countryCode: PropTypes.string,
	}),
	socket: PropTypes.object,
	tiles: PropTypes.arrayOf(PropTypes.object),
	dispatch: PropTypes.func,
	showTimeline: PropTypes.bool,
	isDesktopLayout: PropTypes.bool,
	handleActionClick: PropTypes.func,
	groups: PropTypes.arrayOf(PropTypes.object),
	defaultLanguage: PropTypes.string,
};

const mapStateToProps = ({ people, socket, layout, user }) => ({
	isPersonProfileOpen: people.isPersonProfileOpen,
	loadingTiles: people.loadingTiles,
	myself: people.myself,
	selectedGroup: people.selectedGroup,
	selectedPerson: people.selectedPerson,
	socket,
	tiles: people.tiles,
	showTimeline: people.showTimeline,
	context: people.context,
	isDesktopLayout: layout.isDesktopLayout,
	groups: people.groups,
	defaultLanguage: user.localeCode,
});

eIntl.addPart({
	name: intlNamespace,
	getMessages: (locale) => require(`../i18n/mobile-${locale}`),
});

eIntl.addPart({
	name: 'mobile:Connection',
	getMessages: (locale) => require(`../i18n/mobile-${locale}`),
});

export { PeopleProfileView };
export default connect(mapStateToProps)(withProfileHandler(PeopleProfileView));
