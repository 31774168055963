import { isEmpty } from 'lodash';
import { addPrefixToBaseURL } from './utils/Prefix';

function getBasePath({ protocol, hostname }) {
	return addPrefixToBaseURL(`${protocol}//${hostname}`);
}

function generatePublicPath(location) {
	if (!isEmpty(location)) {
		const publicPath = [location.protocol, '//', location.hostname].join('');

		if (!publicPath.length) {
			throw new Error('Invalid URI generated');
		}

		return addPrefixToBaseURL(publicPath);
	} else {
		throw new Error('Invalid location argument');
	}
}

function generateDesktopLoginEndpoint(location) {
	return `${generatePublicPath(location)}/d/`;
}

function generateHttpServerEndpoint(location) {
	if (!isEmpty(location)) {
		const httpServerEndpoint = [
			location.protocol,
			'//',
			location.hostname,
		].join('');

		if (!httpServerEndpoint.length) {
			throw new Error('Invalid URI generated');
		}

		return addPrefixToBaseURL(httpServerEndpoint);
	} else {
		throw new Error('Invalid location argument');
	}
}

function generateSocketServerEndpoint(location) {
	if (!isEmpty(location)) {
		const socketServerEndpoint = [
			location.protocol,
			'//',
			location.hostname
				.replace('e.', 'ws.')
				.replace('external.', 'ws.')
				.replace('roll.', 'ws.'),
		].join('');

		if (!socketServerEndpoint.length) {
			throw new Error('Invalid URI generated');
		}

		return addPrefixToBaseURL(socketServerEndpoint);
	} else {
		throw new Error('Invalid location argument');
	}
}

function generateRedirectEndpointWeb(location) {
	if (!isEmpty(location)) {
		const redirectEndpoint = [
			addPrefixToBaseURL([location.protocol, '//', location.hostname].join('')),
			'/m',
		].join('');

		if (!redirectEndpoint.length) {
			throw new Error('Invalid URI generated');
		}

		return redirectEndpoint;
	} else {
		throw new Error('Invalid location argument');
	}
}

function generateRedirectEndpointMobile(location) {
	if (!isEmpty(location)) {
		const redirectEndpoint = ['adpeai:', '//', location.hostname].join('');

		if (!redirectEndpoint.length) {
			throw new Error('Invalid URI generated');
		}

		return redirectEndpoint;
	} else {
		throw new Error('Invalid location argument');
	}
}

function generateChatServerEndpoint(location) {
	if (!isEmpty(location)) {
		const chatServerEndpoint = [
			addPrefixToBaseURL([location.protocol, '//', location.hostname].join('')),
			'/chatserver/',
		].join('');

		if (!chatServerEndpoint.length) {
			throw new Error('Invalid URI generated');
		}

		return chatServerEndpoint;
	} else {
		throw new Error('Invalid location argument');
	}
}

function isDevelopmentEnvironment(location) {
	const whitelist = ['local', 'dit', 'fit', 'hf'];
	return (
		location &&
		typeof location.hostname === 'string' &&
		whitelist.some(function (env) {
			return location.hostname.includes(env);
		})
	);
}

function generateChatRelayAPIEndpoint(location) {
	return `${getBasePath(location)}/newchat/relay/v1`;
}

function generateFacebookSubscriptionEndpoint(location) {
	if (location.hostname.indexOf('.fit.') > 0) {
		return 'https://m.me/adpebotfit';
	}

	// dit and local use the same adpebot page.
	return 'https://m.me/adpebot';
}

function generateSlackSubscriptionEndpoint(location) {
	return `${getBasePath(location)}/newchat/login/slack`;
}

function generateConversationEndpoint(location) {
	return `${generateSocketServerEndpoint(location)}/conv-api/v1`;
}

//
// !!!!!
// NOTE:
// This file needs to be loaded both in static HTML and node.
//
(function (exports) {
	exports.isDevelopmentEnvironment = isDevelopmentEnvironment;
	exports.generateChatServerEndpoint = generateChatServerEndpoint;
	exports.generateHttpServerEndpoint = generateHttpServerEndpoint;
	exports.generatePublicPath = generatePublicPath;
	exports.generateRedirectEndpointMobile = generateRedirectEndpointMobile;
	exports.generateRedirectEndpointWeb = generateRedirectEndpointWeb;
	exports.generateSocketServerEndpoint = generateSocketServerEndpoint;
	exports.generateChatRelayAPIEndpoint = generateChatRelayAPIEndpoint;
	exports.generateFacebookSubscriptionEndpoint =
		generateFacebookSubscriptionEndpoint;
	exports.generateSlackSubscriptionEndpoint = generateSlackSubscriptionEndpoint;
	exports.generateConversationEndpoint = generateConversationEndpoint;
	exports.generateDesktopLoginEndpoint = generateDesktopLoginEndpoint;
})(typeof exports === 'undefined' ? window : module.exports);
