const updateTimelineSubjectIDsIfNeeded = (state, action) => {
	switch (action.type) {
		case 'GET_DETAILED_PERSON_INFO': {
			return Object.assign({}, state, {
				currentSubjectIDs: [action.person.personID]
			});
		}
		case 'REMOVE_SELECTED_PERSON':
		case 'REMOVE_SELECTED_GROUP': {
			return Object.assign({}, state, {
				currentSubjectIDs: []
			});
		}
		case 'SET_SELECTED_GROUP': {
			return Object.assign({}, state, {
				currentSubjectIDs: action.members.map(member => member.personID)
			});
		}

		default:
			return null;
	}
};

export { updateTimelineSubjectIDsIfNeeded };
