import React from 'react';
import PropTypes from 'prop-types';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';
import { TextField } from '@adplabs/adp-e-common/ui-input';

import { eIntl } from '@adplabs/adp-e-common/ui-intl';

//
// Debug Logger
//
import _log from '../../log';
const log = _log('app:presentation-engine:presentation');

const intlNamespace = 'mobile:Presentation';

/*
 * Render each Interaction within a Presentation.
 */
export default class Presentation extends React.Component {
	componentDidUpdate() {
		const lastChild = document.getElementById('messages').lastChild;

		if (lastChild != null) {
			lastChild.scrollIntoView();
		}
	}

	render() {
		const {
			messages,
			input,
			onTimeIconClick,
			onPeopleIconClick,
			onChatIconClick,
			onClientIconClick
		} = this.props;

		let navi;
		if (window.__IS_EMBEDDED__) {
			const workspaceHeaderStyle = {
				width: '100%',
				textAlign: 'center',
				position: 'fixed',
				top: '10px',
				fontWeight: '300',
				fontSize: '18px',
				color: '#666666'
			};
			navi = (
				<div className="main-navigation" style={workspaceHeaderStyle}>
					Conversation
				</div>
			);
		} else {
			navi = (
				<Navigation
					onTimeIconClick={onTimeIconClick}
					onPeopleIconClick={onPeopleIconClick}
					onChatIconClick={onChatIconClick}
					onClientIconClick={onClientIconClick}
				/>
			);
		}

		log('🐛 DEBUG', 'Path:', this.props);

		return (
			<div className="presentation">
				<Header title={eIntl.formatMessage(`${intlNamespace}.header`)} />
				<div className="adp-e-mobile-content">
					<div className="interaction-wrapper">
						<div id="messages" className="adp-e-mobile-sim-messages">
							{messages}
						</div>
					</div>

					<div
						id="input"
						className={[
							'@adplabs/adp-e-chat-ui-input-components',
							'input-component-wrapper'
						].join(' ')}
					>
						{input || <TextField placeholder="Message" required />}
					</div>

					{this.props.errorMessage ? (
						<div className="conversation-dev-error">
							<p>Internal Server Error (development only)</p>
							<code>{this.props.errorMessage}</code>
						</div>
					) : (
						undefined
					)}
				</div>
				<div className="adp-e-mobile-footer">{navi}</div>
			</div>
		);
	}
}

Presentation.propTypes = {
	// Props
	messages: PropTypes.arrayOf(PropTypes.object),
	input: PropTypes.object,

	// Navigation Buttons
	onTimeIconClick: PropTypes.func,
	onPeopleIconClick: PropTypes.func,
	onChatIconClick: PropTypes.func,
	onClientIconClick: PropTypes.func,
	errorMessage: PropTypes.string
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../../i18n/mobile-${locale}`)
});
