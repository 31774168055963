import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withProfileHandler from './withProfileHandler';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { peopleServicesURL } from '../utils/endpoints';
import { setHeaderTitleToken } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';
import { SkipToNav } from '../components/SkipToNav';
import {
	ClientProfile,
	getClientDetails,
	getClientTileDetails,
	ClientTiles,
	LegalEntityIndexTile
} from '@adplabs/adp-e-common/ui-people';
const clientService = `${peopleServicesURL}/v1/client`;

const intlNamespace = 'mobile:ClientView';

class ClientProfileView extends React.Component {
	static defaultProps = {
		isPersonProfileOpen: false,
		loadingTiles: true,
		tiles: []
	};

	constructor(props) {
		super(props);
		this.handleTileClick = this.handleTileClick.bind(this);
	}

	componentDidUpdate(prevProps) {
		const { dispatch, selectedPerson } = this.props;
		if (!prevProps.socket.connected && this.props.socket.connected) {
			dispatch(getClientDetails(selectedPerson, clientService));
		}
	}

	componentDidMount() {
		this.props.dispatch(
			getClientDetails(this.props.selectedPerson, clientService)
		);

		this.props.dispatch(
			setHeaderTitleToken(`${intlNamespace}.header`)
		);

		this.props.dispatch(
			setSearchSettings({
				hidden: true
			})
		);
	}

	renderLegalEntityIndex() {
		const { loadingTiles, tiles } = this.props;
		if (loadingTiles || !tiles) {
			return null;
		}
		const indexTile = tiles.find(
			tile => tile.tileIdentifier === ClientTiles.LegalEntityIndexTile
		);
		if (!indexTile) {
			return null;
		}
		return (
			<LegalEntityIndexTile
				tile={indexTile}
				key={ClientTiles.LegalEntityIndexTile}
			/>
		);
	}

	handleTileClick(viewpointID) {
		const { tiles } = this.props;
		const tile = tiles.find(tile => tile.viewpointID === viewpointID);
		if (tile && tile.config && tile.config.refreshAllowed) {
			this.props.dispatch(
				getClientTileDetails(viewpointID, tile.value, clientService)
			);
		}
	}

	renderClientProfile() {
		const {
			loadingTiles,
			selectedPerson,
			tiles,
			context,
			tilesEvents,
			isDesktopLayout
		} = this.props;

		if (loadingTiles) {
			return null;
		}

		const clientProfile = (
			<ClientProfile
				person={{ ...selectedPerson }}
				tiles={tiles}
				context={context}
				tilesEvents={tilesEvents}
				onActionClick={this.props.handleActionClick}
				onTileEventClick={this.props.handleTileEventClick}
				onTileClick={this.handleTileClick}
				open
			/>
		);

		if (isDesktopLayout) {
			return (
				<div
					className="adp-e-mobile-expanded-main-content-panel"
					style={{ overflowY: 'auto' }}
				>
					<div className="adp-e-mobile-expanded-container-tile">
						{clientProfile}
					</div>
				</div>
			);
		}

		return (
			<div className="company-card-body-container mobile-profile">
				<span>{clientProfile}</span>
			</div>
		);
	}

	renderContent() {
		const { loadingTiles } = this.props;
		if (loadingTiles) {
			return null;
		}

		const indexTile = this.renderLegalEntityIndex();
		const clientProfile = this.renderClientProfile();

		return (
			<Fragment>
				<div className="company-card-header">{indexTile}</div>
				{clientProfile}
			</Fragment>
		);
	}

	render() {
		const content = this.renderContent();

		return (
			<section
				className="people-view-container"
				role="main"
				aria-label="Client profile view"
			>
				<SkipToNav />
				<div className="adp-e-mobile-content">{content}</div>
			</section>
		);
	}
}

ClientProfileView.propTypes = {
	loadingTiles: PropTypes.bool,
	selectedPerson: PropTypes.shape({
		associateID: PropTypes.string,
		personID: PropTypes.string,
		isMyself: PropTypes.bool
	}),
	context: PropTypes.shape({
		countryCode: PropTypes.string
	}),
	socket: PropTypes.object,
	tiles: PropTypes.arrayOf(PropTypes.object),
	dispatch: PropTypes.func,
	tilesEvents: PropTypes.arrayOf(PropTypes.object),
	isDesktopLayout: PropTypes.bool,
	handleActionClick: PropTypes.func,
	handleTileEventClick: PropTypes.func
};

const mapStateToProps = ({ people, socket, layout }) => ({
	loadingTiles: people.loadingTiles,
	myself: people.myself,
	selectedPerson: people.selectedPerson,
	socket,
	tiles: people.tilesClient,
	context: people.context,
	tilesEvents: people.tilesEvents,
	isDesktopLayout: layout.isDesktopLayout
});

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

eIntl.addPart({
	name: 'mobile:Connection',
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

export { ClientProfileView };
export default connect(mapStateToProps)(withProfileHandler(ClientProfileView));
