import React from 'react';
import PropTypes from 'prop-types';
import appHistory from '../appHistory';
import session from '../UserSession';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { peopleServicesURL } from '../utils/endpoints';
import { setHeaderTitleToken } from '../actions/header';
import { setSearchSettings } from '../actions/searchSettings';
import { SkipToNav } from '../components/SkipToNav';
import {
	IndexTile,
	getGroups,
	resetHasGroups,
	setSelectedGroup,
	setSelectedPerson,
	PeopleGroupList
} from '@adplabs/adp-e-common/ui-people';
import {
	setRightPanelComponent,
	clearRightPanelComponent
} from '../actions/rightPanel';
import { startConversation } from '../actions/conversation';

const peopleGroupsService = `${peopleServicesURL}/v1/groups`;

const intlNamespace = 'mobile:PeopleView';

/**
 * Show all groups and their members
 */
class PeoplePageView extends React.Component {
	constructor(props) {
		super(props);
		this.handleGroupClick = this.handleGroupClick.bind(this);
		this.handleMyselfClick = this.handleMyselfClick.bind(this);
		this.handlePersonClick = this.handlePersonClick.bind(this);
		this.handleTeamClick = this.handleTeamClick.bind(this);
	}

	componentDidMount() {
		const { dispatch, myself } = this.props;
		if(myself && myself.isOwner === false) {
			this.handleMyselfClick();
			dispatch(resetHasGroups());
		}

		this.props.dispatch(getGroups(peopleGroupsService));

		this.props.dispatch(
			setHeaderTitleToken(`${intlNamespace}.header`)
		);

		this.props.dispatch(
			setSearchSettings({
				hidden: true,
				searchPath: '/search/people'
			})
		);

		this.props.dispatch(resetHasGroups());
	}

	componentDidUpdate(prevProps, prevState) {
		const { hasGroups, dispatch, socket, myself, isDesktopLayout } = this.props;

		if (prevProps?.myself !== myself) {
			if(myself.isOwner === false) {
				this.handleMyselfClick();
				dispatch(resetHasGroups());
			}

			if(isDesktopLayout && myself.isOwner) {
				this.handleMyselfClick();
				dispatch(resetHasGroups());
			}
		}
		if (!hasGroups && prevProps.hasGroups !== hasGroups) {
			this.handleMyselfClick();
			dispatch(resetHasGroups());
		}

		this.loadLatestPersonViewedIntoRightPanel();

		if (!prevProps.socket.connected && socket.connected) {
			this.props.dispatch(getGroups(peopleGroupsService));
		}
	}

	loadLatestPersonViewedIntoRightPanel() {
		const {
			hasGroups,
			isDesktopLayout,
			groups,
			rightPanelComponent,
			selectedPerson,
			dispatch
		} = this.props;
		if (
			!isDesktopLayout ||
			!hasGroups ||
			(rightPanelComponent && rightPanelComponent.type === 'PEOPLE_PROFILE')
		) {
			return;
		}

		if (selectedPerson) {
			dispatch(
				setRightPanelComponent({
					type: 'PEOPLE_PROFILE'
				})
			);
		} else {
			const recentPerson = this.getLatestPersonFromRecentGroup(groups);
			if (recentPerson) {
				this.handlePersonClick(recentPerson);
			}
		}
	}

	getLatestPersonFromRecentGroup(groups) {
		const recentGroup = groups.find(group => group.title === 'recent');
		if (recentGroup?.members.length) {
			return recentGroup.members[0];
		}
	}

	handleMyselfClick() {
		if (this.props.myself) {
			this.props.dispatch(
				setSelectedPerson(
					Object.assign({}, this.props.myself, {
						isMyself: true
					})
				)
			);
			this.loadPeopleProfile();
		}
	}

	handlePersonClick(person) {
		/*
		if (person.terminated) {
			this.props.dispatch(startConversation({
				canonical: 'worker.hire',
				eventParams: {
					rehireIndicator: 'true',
					associateID: person.associateID,
					associateName: person.formattedName
				}
			}));
		} else {
			const user = session.getUserSync();
			this.props.dispatch(
				setSelectedPerson(
					Object.assign({}, person, {
						isMyself: user.personID === person.personID
					})
				)
			);
			this.loadPeopleProfile();
		}
		*/
		const user = session.getUserSync();
		this.props.dispatch(
			setSelectedPerson(
				Object.assign({}, person, {
					isMyself: user.personID === person.personID
				})
			)
		);
		this.loadPeopleProfile();
	}

	loadPeopleProfile() {
		if (this.props.isDesktopLayout && this.props.groups.length > 0) {
			this.props.dispatch(
				setRightPanelComponent({
					type: 'PEOPLE_PROFILE'
				})
			);
		} else {
			appHistory.push('/people-profile');
		}
	}

	handleGroupClick(title, members) {
		this.props.dispatch(setSelectedGroup(title, members, 'group'));
		appHistory.push('/people-group');

		if (this.props.isDesktopLayout) {
			this.props.dispatch(clearRightPanelComponent());
		}
	}

	handleTeamClick(teamID) {
		const myTeams = this.props.groups.find(group => group.title === 'myTeams');
		if (!myTeams) {
			return;
		}
		const team = myTeams.members.find(team => team.title === teamID);
		if (!team) {
			return;
		}
		this.props.dispatch(setSelectedGroup(team.title, team.members, 'team'));
		appHistory.push('/people-group');
	}

	renderIndexTile() {
		const { myself, enableTitleLink, groups } = this.props;

		if (!myself || groups.length === 0) {
			return null;
		}

		return (
			<div className="person-card-header">
				<IndexTile
					tile={{
						value: {
							personID: myself.personID,
							positionID: myself.positionID,
							documentID: myself.documentID,
							formattedName: myself.formattedName,
							initials: myself.initials,
							positionName: myself.positionName,
							timezone: myself.timezone
						}
					}}
					onClick={this.handleMyselfClick}
					isMyself
					enableTitleLink={enableTitleLink}
				/>
			</div>
		);
	}

	render() {
		return (
			<section
				className="people-view-container"
				role="main"
				aria-label="People page view"
			>
				<SkipToNav />
				<div className="adp-e-mobile-content">
					{this.renderIndexTile()}
					<PeopleGroupList
						groups={this.props.groups}
						onPersonClick={this.handlePersonClick}
						onGroupClick={this.handleGroupClick}
						onTeamClick={this.handleTeamClick}
						onAddClick={() => {
							this.props.dispatch(startConversation({
								canonical: 'worker.hire'
							}));
						}}
					/>
				</div>
			</section>
		);
	}
}

PeoplePageView.propTypes = {
	dispatch: PropTypes.func,
	myself: PropTypes.object,
	groups: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			members: PropTypes.arrayOf(
				PropTypes.shape({
					associateID: PropTypes.string,
					personID: PropTypes.string,
					documentID: PropTypes.string,
					givenName: PropTypes.string,
					formattedName: PropTypes.string,
					initials: PropTypes.string,
					positionName: PropTypes.string,
					timezone: PropTypes.string
				})
			)
		})
	),
	socket: PropTypes.object,
	enableTitleLink: PropTypes.bool,
	hasGroups: PropTypes.bool,
	isDesktopLayout: PropTypes.bool,
	rightPanelComponent: PropTypes.shape({
		type: PropTypes.string
	}),
	selectedPerson: PropTypes.object
};

const mapStateToProps = ({ people, socket, layout, rightPanel }) => ({
	groups: people.groups,
	selectedPerson: people.selectedPerson,
	myself: people.myself,
	enableTitleLink: people.enableTitleLink,
	socket,
	hasGroups: people.hasGroups,
	isDesktopLayout: layout.isDesktopLayout,
	rightPanelComponent: rightPanel.component
});

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

eIntl.addPart({
	name: 'mobile:Connection',
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

export { PeoplePageView };
export default connect(mapStateToProps)(PeoplePageView);
