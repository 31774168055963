export const mediaServerURLBuilder = ({
	documentID,
	width,
	height
}) => {
	if (!documentID) {
		return '';
	}
	const params = width && height ? `?width=${width}&height=${height}` : '';
	return `/content/${documentID}${params}`;
};
