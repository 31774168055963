import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import noop from 'lodash/noop';
import { PersonAvatar } from '@adplabs/adp-e-common/ui-components/PersonAvatar';
import IconTimeline from '@adplabs/adp-e-common/ui-svg/icon-timeline';
import IconTimelineSolid from '@adplabs/adp-e-common/ui-svg/icon-timeline-solid';
import IconChat from '@adplabs/adp-e-common/ui-svg/icon-chat';
import IconChatSolid from '@adplabs/adp-e-common/ui-svg/icon-chat-solid';
import IconPeople from '@adplabs/adp-e-common/ui-svg/icon-people';
import IconPeopleSolid from '@adplabs/adp-e-common/ui-svg/icon-people-solid';
import IconQRCodeScannerSolid from '@adplabs/adp-e-common/ui-svg/icon-qr-code-scanner-solid';
import IconQRCodeScanner from '@adplabs/adp-e-common/ui-svg/icon-qr-code-scanner';
import IconStoreFrontSolid from '@adplabs/adp-e-common/ui-svg/icon-storefront-solid';
import IconStoreFront from '@adplabs/adp-e-common/ui-svg/icon-storefront';
import { IconRollLogo } from '@adplabs/adp-e-common/ui-svg/icon-roll-logo';
import { matchPath } from '../utils/routeUtils';

// Paths
const qrPath = '/qr-code-reader';
const chatPath = '/chat';
const timePath = '/timeline';
const peoplePath = '/people';
const personPath = '/person';
const clientPath = '/client';
const peopleGroupPath = '/people-group';
const peopleProfilePath = '/people-profile';

const baseClassName = 'nav-item';
const classNames = {
	navItem: baseClassName,
	navItemDisabled: `${baseClassName}--disabled`,
	navItemActive: `${baseClassName}--active`
};

class Navigation extends React.Component {
	constructor(props) {
		super(props);
		this.handleNavIconClick = this.handleNavIconClick.bind(this);
	}

	hasAccessToQRCodeScanner() {
		const {
			applicationCodes = [],
			roleCodes = [],
			client = {},
			declaredSmallBusinessOwner = false
		} = this.props.user || {};

		/* Declared SBS owner who has not set up company yet */
		if (declaredSmallBusinessOwner) {
			return true;
		}

		/* Allow SBS to login on sbs desktop by scanning qrcode, but only for owners */
		if (client && client.isSmallBusiness) {
			if (roleCodes && roleCodes.includes('clientadmin')) {
				return true;
			}

			/* sbs regular users, won't have access to desktop yet */
			return false;
		}

		/* enterprise users */
		if (applicationCodes.includes('e-workspace')) {
			return true;
		}

		return false;
	}

	handleNavIconClick(dest) {
		this.props.onNavigate(this.props.location.pathname, dest);
	}

	renderQRCodeScanner(active) {
		const { disabled } = this.props;

		if (disabled) {
			return null;
		}

		if (
			window &&
			window.cordova &&
			window.QRScanner &&
			this.hasAccessToQRCodeScanner()
		) {
			// QRScanner Path Active Class Switcher
			const pathIsActive = matchPath(active, qrPath);
			const qrPathClassName = pathIsActive
				? cn(classNames.navItem, classNames.navItemActive)
				: classNames.navItem;

			return (
				<button
					className={qrPathClassName}
					aria-label="QR Code for Scanning"
					onClick={() => this.handleNavIconClick(qrPath)}
				>
					{pathIsActive ? (
						<IconQRCodeScannerSolid className={classNames.navItemActive} />
					) : (
						<IconQRCodeScanner />
					)}
				</button>
			);
		}

		return null;
	}

	renderMobileLogout() {
		const { isDesktopLayout } = this.props;

		if (isDesktopLayout) {
			return null;
		}

		// if (disabled) {
		// 	return (
		// 		<button
		// 			key="logout"
		// 			aria-label="Logout"
		// 			className={classNames.navItem}
		// 			onClick={() => this.handleNavIconClick('logout')}
		// 		>
		// 			<IconLogout />
		// 		</button>
		// 	);
		// }

		return null;
	}

	renderUserSettingsMenuItem() {
		const { isDesktopLayout } = this.props;

		if (isDesktopLayout) {
			const { user } = this.props;

			const renderAvatar = () => {
				if (user && user.personID && user.formattedName) {
					return (
						<PersonAvatar
							id={user.personID}
							formattedName={user.formattedName}
							photoLink={user.corporateProfileImage}
							size="medium"
							showName={false}
						/>
					);
				}

				return <IconRollLogo className="text-primary-700" size={40} />;
			};

			return (
				<button
					className={cn(classNames.navItem, classNames.navItemActive)}
					aria-label="User Options"
					onClick={() => this.handleNavIconClick('userSettings')}
				>
					{renderAvatar()}
				</button>
			);
		}

		return null;
	}

	// Quick and dirty function to create aria-label's from routes
	// This needs to be redone better including translations
	// Based off what I found in index.js
	menuItemAriaLabel(route) {
		const location = route.substring(1);

		let ariaLabel;

		if (location === 'chat') {
			ariaLabel = 'Chat';
		}
		if (location === 'timeline') {
			ariaLabel = 'Timeline';
		}
		if (location === 'people') {
			ariaLabel = 'People';
		}
		if (location === 'person') {
			ariaLabel = 'Person';
		}
		if (location === 'qr-code-reader') {
			ariaLabel = 'QR Code Reader';
		}
		if (location === 'logout') {
			ariaLabel = 'Logout';
		}
		if (location === 'register') {
			ariaLabel = 'Register';
		}

		return typeof ariaLabel === 'string' ? ariaLabel : 'Navigation Item';
	}

	createMenuItem(route, isActive, component, componentActive) {
		const { disabled } = this.props;

		const itemAriaLabel = this.menuItemAriaLabel(route);

		return (
			<button
				key={route}
				className={cn(classNames.navItem, {
					[classNames.navItemDisabled]: disabled
				})}
				aria-label={itemAriaLabel}
				disabled={disabled}
				aria-hidden={disabled}
				onClick={() => this.handleNavIconClick(route)}
			>
				{isActive ? componentActive : component}
			</button>
		);
	}

	renderCompanyProfileIcon(active) {
		const { user } = this.props;
		const hasLoggedUserAccess =
			user &&
			user.client &&
			user.client.isSmallBusiness &&
			user.roleCodes &&
			user.roleCodes.includes('clientadmin');
		const hasImpersonatedUserAccess =
			user.actingAsMode && user.showClientDetails;

		if (hasLoggedUserAccess || hasImpersonatedUserAccess) {
			return this.createMenuItem(
				clientPath,
				matchPath(active, clientPath),
				<IconStoreFront />,
				<IconStoreFrontSolid className={classNames.navItemActive} />
			);
		}

		return null;
	}

	getPeopleRoutePath() {
		return this.props.user.profileType === 'associate'
			? peoplePath
			: personPath;
	}

	render() {
		const { active, isDesktopLayout, user } = this.props;

		if (!user.userAccountID) {
			return null;
		}

		const buttonsList = [
			// QR Code
			this.renderQRCodeScanner(active),
			// company
			this.renderCompanyProfileIcon(active),
			// chat
			this.createMenuItem(
				chatPath,
				matchPath(active, chatPath),
				<IconChat />,
				<IconChatSolid className={classNames.navItemActive} />
			),
			// timeline
			this.createMenuItem(
				timePath,
				matchPath(active, timePath),
				<IconTimeline />,
				<IconTimelineSolid className={classNames.navItemActive} />
			),
			// people
			this.createMenuItem(
				this.getPeopleRoutePath(),
				matchPath(active, peoplePath) ||
					matchPath(active, personPath) ||
					matchPath(active, peopleGroupPath) ||
					matchPath(active, peopleProfilePath),
				<IconPeople />,
				<IconPeopleSolid className={classNames.navItemActive} />
			),
			// logout
			this.renderMobileLogout()
		].filter(comp => comp !== null);

		if (isDesktopLayout) {
			return (
				<div className="adp-e-mobile-footer vertical">
					<nav role="navigation" className="adp-e-mobile-navigation vertical">
						<div className="adp-e-mobile-navigation-bottom">
							{buttonsList.reverse()}
						</div>
					</nav>
				</div>
			);
		}

		return (
			<div className="adp-e-mobile-footer" id="mobile-navigation">
				<nav role="navigation" className="adp-e-mobile-navigation">
					{buttonsList}
					<a
						href="#main-header"
						className="a-screen-reader-text"
						ref={node => {
							this.skipToChatRef = node;
						}}
					>
						Skip to header
					</a>
				</nav>
			</div>
		);
	}
}

Navigation.defaultProps = {
	active: '/',
	disabled: false,
	isDesktopLayout: false,
	location: {},
	onNavigate: noop,
	user: {}
};

Navigation.propTypes = {
	active: PropTypes.string,
	disabled: PropTypes.bool,
	isDesktopLayout: PropTypes.bool,
	location: PropTypes.object,
	onNavigate: PropTypes.func,
	user: PropTypes.object
};

export default Navigation;
