// eslint-disable-next-line import/no-cycle
import ClientSocketLib from '../ClientSocketLib';
import SocketEvents from '../SocketEvents';
import Dispatcher from '../utils/Dispatcher';
import Logger from '../log';

const log = Logger('app:app-settings-coordinator');

class AppSettingsCoordinator {
	constructor() {
		this.initialize = this.initialize.bind(this);

		this.handleChatAppSubscriptionCreate = this.handleChatAppSubscriptionCreate.bind(this);
		this.handleChatAppSubscriptionUpdate = this.handleChatAppSubscriptionUpdate.bind(this);
		this.handleChatAppSubscriptionDelete = this.handleChatAppSubscriptionDelete.bind(this);
	}

	initialize() {
		log('DEBUG', 'Initializing AppSettings data coordinator.');

		const handlers = [{
			event: SocketEvents.SETTINGS_CREATE_CHAT_APP_SUBSCRIPTION,
			callback: this.handleChatAppSubscriptionCreate
		}, {
			event: SocketEvents.SETTINGS_UPDATE_CHAT_APP_SUBSCRIPTION,
			callback: this.handleChatAppSubscriptionUpdate
		}, {
			event: SocketEvents.SETTINGS_DELETE_CHAT_APP_SUBSCRIPTION,
			callback: this.handleChatAppSubscriptionDelete
		}];

		handlers.map(({ event, callback }) => {
			ClientSocketLib.unsubscribeMessage(event, callback);
			ClientSocketLib.subscribeMessage(event, callback);
		});
	}

	handleChatAppSubscriptionCreate(data) {
		log('DEBUG', 'handling chat app subscription create event', data);
		Dispatcher.dispatch({
			type: SocketEvents.SETTINGS_CREATE_CHAT_APP_SUBSCRIPTION,
			subscription: data,
			errorMsg: ''
		});
	}

	handleChatAppSubscriptionUpdate(data) {
		log('DEBUG', 'handling chat app subscription update event', data);
		Dispatcher.dispatch({
			type: SocketEvents.SETTINGS_UPDATE_CHAT_APP_SUBSCRIPTION,
			subscription: data,
			errorMsg: ''
		});
	}

	handleChatAppSubscriptionDelete(data) {
		log('DEBUG', 'handling chat app subscription delete event', data);
		Dispatcher.dispatch({
			type: SocketEvents.SETTINGS_DELETE_CHAT_APP_SUBSCRIPTION,
			subscription: data,
			errorMsg: ''
		});
	}
}

export default new AppSettingsCoordinator();
