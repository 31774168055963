import { getLanguageIdentifier } from '../initLocale';
export default (
	state = {
		actingAsID: null,
		actingAsMode: '',
		applicationCodes: [],
		associateID: null,
		businessEmailAddress: null,
		client: null,
		countryCode: '',
		formattedName: '',
		impersonatedFormattedName: '',
		impersonatedPersonID: '',
		impersonatedUserAccountID: '',
		impersonatedClientName: '',
		liveSupportAuthorization: true,
		personID: null,
		profileType: null,
		roleCodes: [],
		signupEmailAddress: '',
		timeZone: '',
		userAccountID: null,
		identityProviderAccountNumber: null,
		localeCode: getLanguageIdentifier(),
		declaredSmallBusinessOwner: false,
		clients: []
	},
	action
) => {
	switch (action.type) {
		case 'SET_USER_INFO':
		case 'UPDATE_USER_INFO':
			return Object.assign({}, state, {
				actingAsID: null,
				actingAsMode: '',
				impersonatedFormattedName: '',
				impersonatedPersonID: '',
				impersonatedUserAccountID: '',
				...action.response
			});
		case 'INVALIDATE_SESSION':
			return Object.assign({}, state);
		case 'UPDATE_PERSON_LOCALE':
			if (action.fetching || action.error) {
				return Object.assign({}, state, {
					fetching: action.fetching,
					error: action.error
				});
			}
			return Object.assign({}, state, {
				fetching: false,
				error: false,
				localeCode: action.response.localeCode
			});
		default:
			return state;
	}
};
