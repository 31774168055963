import { updateTimelineSubjectIDsIfNeeded } from '../utils/timeline';
import { timelineReducer } from '@adplabs/adp-e-common/ui-timeline';

export default (
	state = {
		currentSubjectIDs: [],
		hasPastEvents: true,
		firstLoad: true,
		timelineSearch: false,
	},
	action
) => {
	const stateWithUpdatedSubjectIDs = updateTimelineSubjectIDsIfNeeded(
		state,
		action
	);
	if (stateWithUpdatedSubjectIDs) {
		return stateWithUpdatedSubjectIDs;
	}

	// As actions triggered by socket messages GET_TIMELINE_EVENTS_DETAILS &
	// UPDATE_TIMELINE must be dealt in different moments for each one of the
	// timelines (main and filtered)
	if (
		['GET_TIMELINE_EVENTS_DETAILS', 'UPDATE_TIMELINE'].includes(action.type)
	) {
		return timelineReducer(state, action);
	}

	const dealingWithFilteredTimeline = state.currentSubjectIDs.length > 0;
	if (dealingWithFilteredTimeline) {
		return state;
	}

	if (action.type === 'GET_TIMELINE') {
		if (action.fetching || !action.response) {
			return Object.assign({}, state, {
				loadingAllCards: true,
				loadingFutureCards: true,
				loadingPastCards: true,
			});
		}
	}

	if (action.type === 'SET_TIMELINE_SEARCH') {
		return Object.assign({}, state, {
			timelineSearch: action.timelineSearch,
		});
	}

	return timelineReducer(state, action);
};
