/* global firebase */
import { getFirebaseConfig } from '../../utils/Auth';
import _log from '../../log';

const log = _log('app:lib:firebase');

export const rollAnalyticsInit = () => {
	try {
		getFirebaseConfig()
			.then((config) => {
				if (config === null) {
					log('ERROR', 'No firebase configuration found');
					return;
				}
				firebase.initializeApp(config);
				firebase.analytics();
			})
			.catch(err => {
				log('ERROR', 'rollAnalyticsInit', err);
			});
	} catch (e) {
		log('ERROR', 'Error initializing firebase client', {
			message: e.message
		});
	}
};
