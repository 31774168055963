export default (
	state = {
		socketDisconnections: 0
	},
	action
) => {
	switch (action.type) {
		case 'SET_SOCKET_DISCONNECTED': {
			return Object.assign({}, state, {
				socketDisconnections: state.socketDisconnections + 1
			});
		}

		case 'TIMELINE_SOCKET_RESET_DISCONNECTIONS_COUNTER': {
			return Object.assign({}, state, {
				socketDisconnections: 0
			});
		}

		default:
			return state;
	}
};
