export const setRightPanelComponent = component => ({
	type: 'SET_COMPONENT',
	component
});

export const clearRightPanelComponent = () => ({
	type: 'SET_COMPONENT',
	component: null
});

export const setLastTimelineEventLoadedIntoRigthPanel = eventID => ({
	type: 'SET_LAST_TIMELINE_EVENT_LOADED_INTO_RIGHT_PANEL',
	eventID
});
