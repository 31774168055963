import cookie from 'cookie';

import {
	socketServerUrl,
	httpServerUrl,
	chatUrl
} from './endpoints';

const cookies = cookie.parse(document.cookie);

export default {
	socketServerUrl: cookies['workspace.websocket'] || socketServerUrl(),
	httpServerUrl: cookies['workspace.httpserver'] || httpServerUrl(),
	chatUrl: chatUrl(),
	chatIframeId: 'rocket-chat',
	clientID: 'test_client'
};
