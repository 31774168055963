import * as CompareVersion from 'compare-versions';
import { isMobile } from 'react-device-detect';

export const isSBSDesktop = () =>
	!window.cordova && !isUsingCordova() && !isMobile;

export const isUsingCordova = () =>
	navigator &&
	navigator.userAgent &&
	navigator.userAgent.match(/ADPEV/) !== null;

export const isAndroid = () =>
	navigator &&
	navigator.userAgent &&
	navigator.userAgent.match(/Android/i) !== null;

export const getADPEV = () => {
	const re = /\[|\]|;/;
	const part = navigator.userAgent.split(re).find(s => s.startsWith('ADPEV/'));
	if (part) {
		const arr = part.split('/');
		if (arr.length === 2) {
			return arr[1];
		}
	}
	return null;
};

export const getVersion = () => {
	const re = /\[|\]|;/;
	const part = navigator.userAgent.split(re).find(s => s.startsWith('ADPEV/'));
	if (part) {
		const arr = part.split('/');
		if (arr.length === 2) {
			return arr[1];
		}
	}
	return null;
};

export const isVersionSameOrAfter = desiredVersion => {
	const myVersion = getVersion();
	if (myVersion === null) {
		return false;
	}
	return CompareVersion.compare(myVersion, desiredVersion, '>=');
};

export const getPlatformCode = () => {
	const appPlatform = navigator.userAgent.match(/PNS\/(\w+)/);
	if (appPlatform && appPlatform[1]) {
		return appPlatform[1];
	}
	return null;
};

// rename to AppInfo
