import React from 'react';
import PropTypes from 'prop-types';
import { IconPaperplaneSolid } from '@adplabs/adp-e-common/ui-svg';
import ValidationError from '../../components/auth/ValidationError';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import {
	formClasses,
	formContainerClasses,
	inputClasses,
	linkClasses,
	secondaryTitleStyles,
	submitContainerClasses,
	validationErrorClasses
} from './utilClasses';
import { ActionButton } from '@adplabs/adp-e-common/ui-input/components/ActionButton';
import classNames from 'classnames';
import { BaseFontSizeContext } from '@adplabs/adp-e-common/common/utils/BaseFontSizeContext';

const intlNamespace = 'mobile:AuthSignUpConfirmView';

export default class SignUpConfirmView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			code: ''
		};
		this.onCodeChange = this.onCodeChange.bind(this);
		this.handleSubmitCode = this.handleSubmitCode.bind(this);
	}

	onCodeChange(code) {
		if (this.props.serverErrorMsg) {
			this.props.resetServerErrorMsg();
		}
		this.setState(prevState => ({
			...prevState,
			code
		}));
	}

	handleSubmitCode(e) {
		e.preventDefault();
		if (this.state.code) {
			this.props.onCodeSubmit(this.state.code);
			e.target.reset();
		}
	}
	render() {
		const adjustForLargeBaseText = this.context.fontSize > 35;

		return (
			<section className="signup-confirm">
				<h2 className={classNames({ 'text-3xs': adjustForLargeBaseText })}>{eIntl.formatMessage(`${intlNamespace}.header`)}</h2>
				<h3 {...secondaryTitleStyles(adjustForLargeBaseText)}>
					{eIntl.formatMessage(`${intlNamespace}.subheader.weHaveSent`)}{' '}
					<span className="font-demi">{this.props.userName}</span>.{' '}
					{eIntl.formatMessage(`${intlNamespace}.subheader.enterIt`)}
				</h3>
				<div className={formContainerClasses(adjustForLargeBaseText)}>
					<form onSubmit={this.handleSubmitCode}>
						<label htmlFor="enterCode" className="sr-only">
							{eIntl.formatMessage(`${intlNamespace}.labelCode`)}
						</label>

						<input
							id="enterCode"
							type="text"
							inputMode="decimal"
							placeholder={eIntl.formatMessage(
								`${intlNamespace}.placeholderCode`
							)}
							name="code"
							value={this.state.code}
							onChange={e => this.onCodeChange(e.target.value)}
							className={classNames(inputClasses(adjustForLargeBaseText), {
								'mb-3': !adjustForLargeBaseText,
								'mb-2': adjustForLargeBaseText
							})}
						/>

						<ValidationError
							error={this.props.serverErrorMsg || null}
							cssClass={validationErrorClasses(adjustForLargeBaseText)}
						/>

						<ActionButton
							type="submit"
							primary
							className={classNames(
								submitContainerClasses(this.props.loading, false),
								'mb-4'
							)}
						>
							<span className="sr-only">
								{eIntl.formatMessage(`${intlNamespace}.labelSubmit`)}
							</span>
							<IconPaperplaneSolid />
						</ActionButton>
					</form>
					<div className={classNames({ 'mb-2': !adjustForLargeBaseText, 'mb-1': adjustForLargeBaseText })}>
						<span className={classNames('text-neutral-800', { 'text-4xs': adjustForLargeBaseText })}>
							{eIntl.formatMessage(`${intlNamespace}.labelGoBackTo`)}
						</span>{' '}
						<button
							onClick={this.props.onSignInClick}
							className={classNames(linkClasses(adjustForLargeBaseText), 'p-0')}
							type="button"
						>
							{eIntl.formatMessage(`${intlNamespace}.labelSignIn`)}
						</button>
					</div>

					<div>
						<span className={classNames('text-neutral-800', { 'text-4xs': adjustForLargeBaseText })}>
							{eIntl.formatMessage(`${intlNamespace}.labelOr`)}
						</span>{' '}
						<button
							onClick={this.props.onResendCodeClick}
							className={classNames(linkClasses(adjustForLargeBaseText), 'p-0')}
							type="button"
						>
							{eIntl.formatMessage(`${intlNamespace}.labelResendCode`)}
						</button>
					</div>
				</div>
			</section>
		);
	}
}

SignUpConfirmView.contextType = BaseFontSizeContext;

SignUpConfirmView.propTypes = {
	userName: PropTypes.string,
	onCodeSubmit: PropTypes.func,
	onResendCodeClick: PropTypes.func,
	serverErrorMsg: PropTypes.string,
	resetServerErrorMsg: PropTypes.func,
	onSignInClick: PropTypes.func,
	loading: PropTypes.bool,
	resendingCode: PropTypes.bool
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../../i18n/mobile-${locale}`)
});
