//
// Socket Events
//
// There is an identical file in the adp-e-rest-api codebase for the server
// side of the conversation. It is curently located under src. Any changes to this
// file should be made to that file as well to keep both sides of the
// socket communication in sync.
//
// Note that most socket events have an event name (client -> server) followed
// by a 'RESP' version coming back (server -> client).

const SocketEvents = {
	ON_SEARCH: 'ON_SEARCH',
	ON_SEARCH_RESP: 'ON_SEARCH_RESP',

	GET_SEARCH_HISTORY: 'GET_SEARCH_HISTORY',
	GET_SEARCH_HISTORY_RESP: 'GET_SEARCH_HISTORY_RESP',

	ADD_SEARCH_HISTORY: 'ADD_SEARCH_HISTORY',
	DELETE_SEARCH_HISTORY: 'DELETE_SEARCH_HISTORY',
	UPDATE_SEARCH_HISTORY: 'UPDATE_SEARCH_HISTORY',

	GET_EVENT: 'GET_EVENT',
	GET_EVENT_RESP: 'GET_EVENT_RESP',

	GET_PERSON_INFO: 'GET_PERSON_INFO',
	GET_PERSON_INFO_RESP: 'GET_PERSON_INFO_RESP',

	GET_DETAILED_PERSON_INFO: 'GET_DETAILED_PERSON_INFO',
	GET_DETAILED_PERSON_INFO_RESP: 'GET_DETAILED_PERSON_INFO_RESP',

	USER_ACTIVITY: 'USER_ACTIVITY',
	GET_USER_ACTIVITY: 'GET_USER_ACTIVITY',
	GET_USER_ACTIVITY_RESP: 'GET_USER_ACTIVITY_RESP',

	// Version message can be used as a connectivity test.
	VERSION: 'VERSION',
	VERSION_RESP: 'VERSION_RESP',

	// Client logging facilities
	CLIENT_LOG: 'CLIENT_LOG',
	CLIENT_LOG_RESP: 'CLIENT_LOG_RESP',

	// Timeline update only comes from server to client. No RESP
	TIMELINE_UPDATE: 'TIMELINE_UPDATE',
	GET_TIMELINE_UPDATE: 'GET_TIMELINE_UPDATE',
	GET_CURRENT_TIMESTAMP: 'GET_CURRENT_TIMESTAMP',
	GET_CURRENT_TIMESTAMP_RESP: 'GET_CURRENT_TIMESTAMP_RESP',

	// Notification messages.
	START_CONV: 'START_CONV',
	START_CONV_RESP: 'START_CONV_RESP',
	LEFT_CONV: 'LEFT_CONV',
	LEFT_CONV_RESP: 'LEFT_CONV_RESP',

	// App settings events
	SETTINGS_CREATE_CHAT_APP_SUBSCRIPTION:
		'SETTINGS_CREATE_CHAT_APP_SUBSCRIPTION',
	SETTINGS_UPDATE_CHAT_APP_SUBSCRIPTION:
		'SETTINGS_UPDATE_CHAT_APP_SUBSCRIPTION',
	SETTINGS_DELETE_CHAT_APP_SUBSCRIPTION:
		'SETTINGS_DELETE_CHAT_APP_SUBSCRIPTION',

	SESSION_INVALIDATE: 'SESSION_INVALIDATE'
};

export default SocketEvents;
