import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { PersonAvatar } from '@adplabs/adp-e-common/ui-components/PersonAvatar';
import { IconRollLogo, IconSwap } from '@adplabs/adp-e-common/ui-svg';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
// eslint-disable-next-line import/no-cycle
import { UserShape, LocationShape, ClientShape } from '../layouts/CoreLayout';

const intlNamespace = 'mobile:MainHeader';
const baseClassName = 'nav-item';
const classNames = {
	navItem: baseClassName,
	navItemDisabled: `${baseClassName}--disabled`,
	navItemActive: `${baseClassName}--active`,
};
class DesktopHeader extends React.Component {
	constructor(props) {
		super(props);
		this.handleNavIconClick = this.handleNavIconClick.bind(this);
	}

	handleNavIconClick(dest) {
		this.props.onNavigate(this.props.location.pathname, dest);
	}

	renderUserSettingsMenuItem() {
		const { user } = this.props;

		const renderAvatar = () => {
			if (user && user.personID && user.formattedName) {
				return (
					<PersonAvatar
						id={user.personID}
						formattedName={user.formattedName}
						photoLink={user.corporateProfileImage}
						size="medium"
						showName={false}
						onClick={() => this.handleNavIconClick('userSettings')}
					/>
				);
			}

			return <IconRollLogo className="text-primary-700" size={40} onClick={() => this.handleNavIconClick('userSettings')} />;
		};

		return (
			<div
				className={cn(classNames.navItem, classNames.navItemActive)}
				style={{ fontFamily: 'Arial', userSelect: 'none' }}
				aria-label="User Options"
			>
				{renderAvatar()}
			</div>
		);
	}

	render() {
		const { client, toggleClientSwitchpopup, disableClientSwitch, title } =
			this.props;

		return (
			<div className="adp-desktop-header">
				<div className="flex">
					{this.renderUserSettingsMenuItem()}
					{client ? (
						<button
							className="client-title"
							onClick={toggleClientSwitchpopup}
							onKeyDown={toggleClientSwitchpopup}
							disabled={disableClientSwitch}
						>
							<p>{client.clientName} </p>
							<IconSwap className="ml-2" size="18" />
						</button>
					) : null}
				</div>
				{title()}
				<div className="adp-header-menu">{this.props.renderHelp()}</div>
			</div>
		);
	}
}

DesktopHeader.propTypes = {
	user: UserShape,
	onNavigate: PropTypes.func,
	location: LocationShape,
	renderHelp: PropTypes.func,
	client: ClientShape,
	toggleClientSwitchpopup: PropTypes.func,
	disableClientSwitch: PropTypes.bool,
	title: PropTypes.func,
};

export default DesktopHeader;

eIntl.addPart({
	name: intlNamespace,
	getMessages: (locale) => require(`../i18n/mobile-${locale}`),
});
