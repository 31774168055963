import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import {
	IconLogout,
	IconSwap,
} from '@adplabs/adp-e-common/ui-svg';

const intlNamespace = 'mobile:NavigationMenu';

const NavigationMenuPopup = ({ hidden, onClickLogout, onClickOutside, toggleClientSwitchpopup, showClientSwitch, disableClientSwitch }) => {
	NavigationMenuPopup.handleClickOutside = () => onClickOutside();

	if (hidden) {
		return null;
	}

	return (
		<div className="navigation-menu-popup-container">
			<div className="navigation-menu-popup-container-arrow" />
			<div className="navigation-menu-popup-container-border-node">
				<button onClick={onClickLogout}>
					<IconLogout />
					<span>{eIntl.formatMessage(`${intlNamespace}.logout`)}</span>
				</button>
				{showClientSwitch ? <button onClick={toggleClientSwitchpopup} className="flex" disabled={disableClientSwitch}>
					<IconSwap />
					<span>{eIntl.formatMessage(`${intlNamespace}.changeCompany`)}</span>
				</button> : null}
			</div>
		</div>
	);
};

NavigationMenuPopup.defaultProps = {
	hidden: true,
	onClickLogout: () => null,
	onClickOutside: () => null,
	toggleClientSwitchpopup: () => null,
	showClientSwitch: false,
	disableClientSwitch: false,
};

NavigationMenuPopup.propTypes = {
	hidden: PropTypes.bool,
	onClickOutside: PropTypes.func,
	onClickLogout: PropTypes.func,
	toggleClientSwitchpopup: PropTypes.func,
	showClientSwitch: PropTypes.bool,
	disableClientSwitch: PropTypes.bool,
};

eIntl.addPart({
	name: intlNamespace,
	getMessages: locale => require(`../i18n/mobile-${locale}`)
});

const clickOutsideConfig = {
	handleClickOutside: () => NavigationMenuPopup.handleClickOutside
};

export default onClickOutside(NavigationMenuPopup, clickOutsideConfig);
