// import ClientSocketLib from './ClientSocketLib';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
// eslint-disable-next-line import/no-cycle
import { getUserInfo } from './utils/Auth';
import _log from './log';
const log = _log('app:user-session');

class UserSession {
	constructor() {
		this.user = null;
	}

	setUser(data) {
		this.user = data;
		return this.user;
	}

	// In some cases, we may just want the value of user. The authorize middleware
	// prob already verified using getUser() (async), so now we just want the value.
	getUserSync() {
		if (this.user) {
			return this.user;
		} else {
			return null;
		}
	}

	isUserInitialized() {
		if (this.user === null) {
			return false;
		} else {
			return true;
		}
	}

	getUser() {
		return new Promise((resolve, reject) => {
			getUserInfo()
				.then((data) => {
					log('DEBUG', 'getUser: data', data);
					this.user = data;
					if (data && data.countryCode) {
						eIntl.setCountryCode(data.countryCode);
					}
					resolve(data);
				})
				.catch((err) => {
					log('ERROR', 'getUser', err);
					reject(err);
				});
		});
	}
}

export default new UserSession();
