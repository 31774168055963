import React from 'react';
import PropTypes from 'prop-types';
import AuthHeader from '../components/auth/AuthHeader';
import { withRouter } from 'react-router-v3-for-frontend-only';
function AuthLayout(props) {
	return (
		<>
			<AuthHeader location={props.location} />
			<div>{props.children}</div>
		</>
	);
}

AuthLayout.propTypes = {
	children: PropTypes.element,
	location: PropTypes.object
};

export default withRouter(AuthLayout);
