export const setWindowSize = (height, width) => ({
	type: 'SET_WINDOW_SIZE',
	height,
	width
});

export const setWindowOrientation = orientation => ({
	type: 'SET_WINDOW_ORIENTATION',
	orientation
});

export const setStepUp = stepUp => ({
	type: 'SET_STEPUP',
	stepUp
});
