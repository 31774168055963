import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import isEqual from 'lodash/isEqual';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import {
	displayTimelinePunchPopup,
	displayTimelineTimeSheetPopup,
	getMoreTimelineEvents,
	getTimeline,
	getTimelineEventsDetails,
	hideTimelineActionPopup,
	InfiniteScroll,
	setScrollPosition,
	setScrollToNow,
	setTimelineFirstLoad,
	submitTimelineAction,
	Timeline,
	TimelinePopup,
	toggleEventDetailsExhibition,
	updateClock,
	updateTimelineActionComments,
	setPunchLocation,
} from '@adplabs/adp-e-common/ui-timeline';
import { BaseInputContext } from '@adplabs/adp-e-common/ui-input/utils/BaseInputContext';
import { Enums } from '@adplabs/adp-e-common/ui-timeline/types';
import {
	removeSelectedGroup,
	removeSelectedPerson,
} from '@adplabs/adp-e-common/ui-people';
import {
	SearchView,
	setSearchQuery,
	submitSearchQuery,
	cleanSearchQuery,
} from '@adplabs/adp-e-common/ui-search';
import {
	canGetGeolocation,
	getCurrentGeolocation,
	getGeoServiceStatus,
	getLatestRecordedGeolocation,
} from '../utils/geolocation_old';
import { mediaServerURLBuilder } from '../utils/personAvatar';
import {
	resetTimelineSocketDisconnectionsCounter,
	setTimelineSearch,
} from '../actions/timelineSocket';
import { setHeaderTitleToken } from '../actions/header';
import {
	setRightPanelComponent,
	setLastTimelineEventLoadedIntoRigthPanel,
} from '../actions/rightPanel';
import { setSearchSettings } from '../actions/searchSettings';
import { startConversation } from '../actions/conversation';
import { timelineServicesURL } from '../utils/endpoints';
import withTimelineHandler from './withTimelineHandler';

const timelineActionService = `${timelineServicesURL}/v1/action`;
const timelineTimeService = `${timelineServicesURL}/v1/time`;

class TimelineView extends React.Component {
	constructor(props) {
		super(props);
		this.handleActionCancelOrClickOutside =
			this.handleActionCancelOrClickOutside.bind(this);
		this.handleActionSubmit = this.handleActionSubmit.bind(this);
		this.handleActionCommentsChange =
			this.handleActionCommentsChange.bind(this);
		this.handleClockUpdate = this.handleClockUpdate.bind(this);
		this.handleEnableLocation = this.handleEnableLocation.bind(this);
		this.handleExceptionPunchClick = this.handleExceptionPunchClick.bind(this);
		this.handleUpdatePunchClick = this.handleUpdatePunchClick.bind(this);
		this.handleEventClick = this.handleEventClick.bind(this);
		this.handlePunchClick = this.handlePunchClick.bind(this);
		this.handleScroll = this.handleScroll.bind(this);
		this.handleScrollDown = this.handleScrollDown.bind(this);
		this.handleScrollUp = this.handleScrollUp.bind(this);
		this.handleScrollToNow = this.handleScrollToNow.bind(this);
		this.handleScrollToEventById = this.handleScrollToEventById.bind(this);
		this.handleTimelineLoad = this.handleTimelineLoad.bind(this);
		this.getTimelineOwner = this.getTimelineOwner.bind(this);
		this.reloadExpandedEventsDetails =
			this.reloadExpandedEventsDetails.bind(this);
		const scrollToItemID = props.params && props.params.eventID;

		this.state = {
			scrollToItemID,
		};
	}
	scrollRef;

	componentDidMount() {
		const ignoreCache = this.props.timelineSocket.socketDisconnections > 0;
		if (ignoreCache) {
			this.props.dispatch(resetTimelineSocketDisconnectionsCounter());
		}
		this.handleTimelineLoad(ignoreCache);

		if (!this.props.isGroupOrPersonTimeline()) {
			this.props.dispatch(setHeaderTitleToken('mobile:TimelineView.header'));

			this.props.dispatch(
				setSearchSettings({
					hidden: true,
					searchPath: '/search/timeline',
				})
			);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (
			this.props.params?.eventID &&
			prevProps.params?.eventID &&
			prevProps.params.eventID !== this.props.params.eventID
		) {
			// this is needed for deep links when timeline was already opened via deep link and the user clicks on another deep link
			this.setState({
				...this.state,
				scrollToItemID: this.props.params.eventID,
			});
		}

		if (
			!isEqual(
				this.getTimelineOwner(this.props),
				this.getTimelineOwner(prevProps)
			)
		) {
			this.handleTimelineLoad();
		} else if (!prevProps.socket.connected && this.props.socket.connected) {
			this.reloadExpandedEventsDetails();
			if (this.props.timelineSocket.socketDisconnections > 0) {
				this.handleTimelineLoad(true);
			}
		}
		const timeline = this.props.getCurrentTimeline();
		const { firstLoad, loadingAllCards, loadingFutureCards, loadingPastCards } =
			timeline;
		const loadingEvents =
			loadingAllCards || loadingFutureCards || loadingPastCards;
		if(this.props.socket.connected && this.props.timeline.hasPastEvents && this.props.timeline.events?.length < 10){
			this.handleScrollDown();
		}
		if (firstLoad && !loadingEvents) {
			this.props.dispatch(setTimelineFirstLoad(false));
			if (!this.state.scrollToItemID) {
				this.props.dispatch(setScrollToNow(true));
			}
		}
		this.loadLatestEventIntoRightPanel();
	}

	/**
	 * Fill the right panel with the latest event when there's no event selected
	 */
	loadLatestEventIntoRightPanel() {
		const {
			rightPanelComponent,
			isGroupOrPersonTimeline,
			lastEventIDLoadedIntoRightPanel,
			timeline,
		} = this.props;

		const isValidComponent = (comp) =>
			comp && (comp.type === 'EVENT_DETAILS' || comp.type === 'IN_APP_BROWSER');

		if (isGroupOrPersonTimeline() || isValidComponent(rightPanelComponent)) {
			return;
		}

		if (timeline.events && timeline.events.length) {
			if (
				lastEventIDLoadedIntoRightPanel &&
				timeline.events.some(
					(event) => event.id === lastEventIDLoadedIntoRightPanel
				)
			) {
				this.loadIntoRightPanel(lastEventIDLoadedIntoRightPanel);
			} else {
				this.loadIntoRightPanel(
					this.state.scrollToItemID || timeline.events[0].id
				);
			}
		}
	}

	reloadExpandedEventsDetails() {
		const { dispatch, filteredTimeline, timeline } = this.props;
		const expandedEventIDs = [];

		const allEvents = (timeline.events || []).concat(
			filteredTimeline.events || []
		);
		if (allEvents && allEvents.length > 0) {
			expandedEventIDs.push(
				allEvents
					.filter((event) => event.appendixVisible)
					.map((event) => event.id)
			);
		}

		const uniqExpandedEventIDs = uniq(expandedEventIDs.flat());
		if (uniqExpandedEventIDs.length > 0) {
			dispatch(
				getTimelineEventsDetails(uniqExpandedEventIDs, timelineServicesURL)
			);
		}
	}

	getTimelineOwner(props) {
		const { selectedPerson, selectedGroup } = props;
		return { selectedPerson, selectedGroup };
	}

	handleTimelineLoad(ignoreCache) {
		const { dispatch } = this.props;

		if (this.props.isGroupOrPersonTimeline()) {
			const subjectIDs = this.props.getCurrentSubjectIDs();
			dispatch(
				getTimeline({
					subjectIDs,
					timelineService: timelineServicesURL,
					timestamp: +new Date(),
				})
			);
		} else {
			dispatch(removeSelectedGroup());
			dispatch(removeSelectedPerson());
			const timeline = this.props.getCurrentTimeline();
			if (ignoreCache || this.state.scrollToItemID || !timeline.events) {
				if (this.state.scrollToItemID) {
					dispatch(
						getTimeline({
							eventIDsToInclude: [this.state.scrollToItemID],
							timelineService: timelineServicesURL,
							timestamp: +new Date(),
						})
					);
				} else {
					dispatch(
						getTimeline({
							timelineService: timelineServicesURL,
						})
					);
				}
			}
		}
	}

	handleActionCancelOrClickOutside() {
		this.props.dispatch(hideTimelineActionPopup());
	}

	handleActionSubmit(actionParams) {
		const timeline = this.props.getCurrentTimeline();
		const { timeSheet } = this.props;
		const punchParams = timeline.punch;

		if (timeline.selectedTimelineAction === Enums.TimelineAction.timesheet) {
			if (!timeSheet.userInputs) {
				return this.props.dispatch(hideTimelineActionPopup());
			}
		}
		if (timeline.selectedTimelineAction === Enums.TimelineAction.punch) {
			Object.assign(punchParams, {
				punchTypeCode: actionParams.punchTypeCode,
			});
		}

		this.props.dispatch(
			submitTimelineAction({
				timelineAction: timeline.selectedTimelineAction,
				timelineActionService,
				params: {
					eventID: timeline.selectedEventID,
					keepPopupOpened: actionParams && actionParams.keepPopupOpened,
					punch: punchParams,
					timeSheetUserInput: timeSheet.userInputs,
					userComments: timeline.actionComments,
				},
			})
		);
	}

	handleActionCommentsChange(comments) {
		this.props.dispatch(updateTimelineActionComments(comments));
	}

	handleClockUpdate(now) {
		this.props.dispatch(updateClock(now));
	}

	handleEnableLocation() {
		if (window.cordova.plugins.settings) {
			window.cordova.plugins.settings.open(
				'application_details',
				() => {
					console.debug('[EnableLocation] openNativeSettings: success');
				},
				() => {
					console.debug('[EnableLocation] openNativeSettings: failure');
				}
			);
			this.props.dispatch(hideTimelineActionPopup());
		}
	}

	handleEventClick(eventID) {
		if (!eventID) {
			return;
		}
		this.props.dispatch(
			getTimelineEventsDetails([eventID], timelineServicesURL)
		);
		this.loadIntoRightPanel(eventID);
	}

	loadIntoRightPanel(eventID) {
		if (this.props.isDesktopLayout && !this.props.isInsidePeopleView) {
			this.props.dispatch(setLastTimelineEventLoadedIntoRigthPanel(eventID));
			this.props.dispatch(
				setRightPanelComponent({
					type: 'EVENT_DETAILS',
					attributes: {
						eventIDs: [eventID],
					},
				})
			);
		}
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	async handlePunchClick(action, timestamp) {
		if (action === Enums.TimelineAction.punch) {
			// Find if location services are available
			let locationEnabled = true;
			const { punch } = this.props.getCurrentTimeline();
			if (punch?.locationRequired) {
				const geoServiceStatus = await getGeoServiceStatus();
				locationEnabled = canGetGeolocation(geoServiceStatus);
			}

			// open actionSheet
			this.props.dispatch(
				displayTimelinePunchPopup({
					location: {
						enabled: locationEnabled,
					},
					timelineAction: action,
					timelineTimeService,
					timestamp,
				})
			);

			if (locationEnabled) {
				const geoLocations = await Promise.all([
					getCurrentGeolocation(),
					getLatestRecordedGeolocation(),
				]);
				// verify CurrentLocation (0) and use LatestRecordedLocation (1) if empty
				const location =
					geoLocations[0] && geoLocations[0].lat !== ''
						? geoLocations[0]
						: geoLocations[1];
				this.props.dispatch(
					setPunchLocation({
						location,
						timestamp,
					})
				);
			}
		} else if (action === Enums.TimelineAction.timesheet) {
			this.props.dispatch(
				displayTimelineTimeSheetPopup({
					timelineAction: action,
					timelineTimeService,
				})
			);
		}
	}

	handleExceptionPunchClick() {
		this.handlePunchExceptionOrUpdate('clockEntry.record');
	}

	handleUpdatePunchClick() {
		this.handlePunchExceptionOrUpdate('clockEntry.update');
	}

	handlePunchExceptionOrUpdate(canonical) {
		this.props.dispatch(startConversation({ canonical }));
		this.props.dispatch(hideTimelineActionPopup());
	}

	handleScrollUp() {
		if (!this.props.socket.connected) {
			return;
		}

		const timeline = this.props.getCurrentTimeline() || {};
		if (
			!timeline.loadingFutureCards &&
			timeline.events &&
			timeline.events.length
		) {
			const firstEvent = timeline.events[0];
			const excludedIDs = filter(
				timeline.events,
				(event) =>
					event.timestamp === firstEvent.timestamp || event.status === 'overdue'
			).map((event) => event.id);

			this.props.dispatch(
				getMoreTimelineEvents(
					'future',
					firstEvent.timestamp,
					excludedIDs,
					this.props.getCurrentSubjectIDs(),
					timelineServicesURL
				)
			);
		}
	}

	handleScrollDown() {
		if (!this.props.socket.connected) {
			return;
		}
		const { events, loadingPastCards } = this.props.getCurrentTimeline() || {};

		if (!loadingPastCards && events?.length) {
			const lastNotOverdueEventTimestamp = this.getLastNotOverdueEventTimestamp(events);
			const excludedIDs = filter(events,
				(event) => event.timestamp === lastNotOverdueEventTimestamp || event.status === 'overdue'
			).map((event) => event.id);

			this.props.dispatch(
				getMoreTimelineEvents(
					'past',
					lastNotOverdueEventTimestamp,
					excludedIDs,
					this.props.getCurrentSubjectIDs(),
					timelineServicesURL
				)
			);
		}
	}

	getLastNotOverdueEventTimestamp(events) {
		for (let i = events.length - 1; i >= 0; i-=1) {
			if (events[i].status !== 'overdue') {
				return events[i].timestamp;
			}
		}
		return events[events.length - 1].timestamp;
	}


	handleScrollToNow(scrollTop) {
		this.props.dispatch(setScrollToNow(false));
		this.props.dispatch(setScrollPosition(scrollTop));
	}

	handleScrollToEventById(itemID, scrollTop) {
		const { dispatch } = this.props;
		const timeline = this.props.getCurrentTimeline();
		const itemToScroll = timeline.events.find((item) => item.id === itemID);
		if(itemToScroll.titleModifier === 'ToDo'){
			this.props.dispatch(startConversation({
				canonical: itemToScroll.canonical,
				eventID: itemToScroll.id,
				eventParams: {},
			}));
		}else{
			this.setState({ scrollToItemID: null });
			dispatch(getTimelineEventsDetails([itemID], timelineServicesURL));
			dispatch(setScrollPosition(scrollTop));
			if (itemToScroll && !itemToScroll.appendixVisible) {
				dispatch(toggleEventDetailsExhibition(itemID));
			}
		}
	}

	handleScroll(scrolledTo) {
		this.props.dispatch(setScrollPosition(scrolledTo));
	}

	renderAccessibilityHeader = () => {
		return (
			<>
				<a href="#now-section" className="a-screen-reader-text">
					Skip to now section
				</a>
				<a href="#mobile-navigation" className="a-screen-reader-text">
					Skip to navigation
				</a>
			</>
		);
	};

	onSearchInputChange = (query) => {
		if (query.length > 2) {
			this.props.dispatch(submitSearchQuery('timeline', query));
		} else {
			this.props.dispatch(setSearchQuery('timeline', query));
		}
	};

	onSearchCancel = () => {
		this.props.dispatch(cleanSearchQuery('timeline'));
		this.props.dispatch(setTimelineSearch(false));
	};

	renderTimeline() {
		const timeline = this.props.getCurrentTimeline();
		const {
			hasPastEvents,
			loadingAllCards: loadingAllEvents,
			loadingFutureCards: loadingFutureEvents,
			loadingPastCards: loadingPastEvents,
			punch,
			scrollToNow,
			timestamp,
			actionCompleted,
		} = timeline;

		const displayPunch =
			punch && punch.displayPunch && !this.props.isGroupOrPersonTimeline();
		const events = timeline ? timeline.events : [];
		const scrollPosition = timeline ? timeline.scrollPosition : 0;
		const scrollToItemID = this.state.scrollToItemID;
		const currentSubjectIDs = this.props.getCurrentSubjectIDs()
			? this.props.getCurrentSubjectIDs().sort()
				.join()
			: '';

		const eventsWithSummary = this.props.getEventsWithSummary(events);
		const renderAccessibilityHeader = this.renderAccessibilityHeader();

		return (
			<div
				className="adp-e-mobile-timeline-container"
				ref={(reference) => (this.scrollRef = reference)}
			>
				{renderAccessibilityHeader}
				<InfiniteScroll
					onReachBottom={this.handleScrollDown}
					onReachTop={this.handleScrollUp}
					onScroll={this.handleScroll}
					loadingBottomItems={loadingPastEvents}
					loadingTopItems={loadingFutureEvents}
					position={scrollPosition}
				>
					<Timeline
						clock={this.props.clock}
						currentSubjectIDs={currentSubjectIDs}
						displayPunch={displayPunch}
						events={eventsWithSummary}
						expandOrCollapseItem={this.props.handleEventExpandOrCollapse}
						isItemAppendixEnabled={
							!this.props.isDesktopLayout || this.props.isInsidePeopleView
						}
						hasPastEvents={hasPastEvents}
						hideAvatar={false}
						loadingAllCards={loadingAllEvents}
						loadingFutureCards={loadingFutureEvents}
						loadingPastCards={loadingPastEvents}
						mediaServerURLBuilder={mediaServerURLBuilder}
						onActionClick={this.props.handleActionClick}
						onClockUpdate={this.handleClockUpdate}
						onItemClick={this.handleEventClick}
						onOpportunityClick={this.props.handleOpportunityClick}
						onPunchClick={this.handlePunchClick}
						onScrollToItemById={this.handleScrollToEventById}
						onScrollToNow={this.handleScrollToNow}
						punch={punch}
						scrollToItemID={scrollToItemID}
						scrollToNow={scrollToNow && !scrollToItemID}
						updatedTime={timestamp}
						actionCompleted={actionCompleted}
					/>
				</InfiniteScroll>
				<BaseInputContext.Provider value={{ dispatch: this.props.dispatch }}>
					<TimelinePopup
						clock={this.props.clock}
						locale={this.props.locale}
						onAddException={this.handleExceptionPunchClick}
						onUpdatePunch={this.handleUpdatePunchClick}
						onCancel={this.handleActionCancelOrClickOutside}
						onClickOutside={this.handleActionCancelOrClickOutside}
						onCommentsChange={this.handleActionCommentsChange}
						onEnableLocation={this.handleEnableLocation}
						onSubmit={this.handleActionSubmit}
						timeline={timeline}
						timeSheet={this.props.timeSheet}
					/>
				</BaseInputContext.Provider>
			</div>
		);
	}

	render() {
		const { search, timeline } = this.props;
		const searchData = search['timeline'];
		const { query, loading } = searchData;

		if (this.props.isGroupOrPersonTimeline()) {
			return this.renderTimeline();
		}

		return (
			<section
				className="timeline-view-container"
				role="main"
				aria-label="Timeline view"
			>
				<div className="adp-e-mobile-content">
					{timeline.timelineSearch ? (
						<SearchView
							onCancelClick={this.onSearchCancel}
							onSearchInputChange={this.onSearchInputChange}
							searchInputValue={query}
							loading={loading}
						/>
					) : null}
					{this.renderTimeline()}
				</div>
			</section>
		);
	}
}

TimelineView.defaultProps = {
	isInsidePeopleView: false,
};

TimelineView.propTypes = {
	locale: PropTypes.string,
	clock: PropTypes.object,
	filteredTimeline: PropTypes.object,
	timeline: PropTypes.object,
	timelineSocket: PropTypes.object,
	dispatch: PropTypes.func,
	params: PropTypes.object,
	selectedGroup: PropTypes.shape({
		title: PropTypes.string,
		members: PropTypes.arrayOf(
			PropTypes.shape({
				associateID: PropTypes.string,
				personID: PropTypes.string,
				documentID: PropTypes.string,
				givenName: PropTypes.string,
				formattedName: PropTypes.string,
				initials: PropTypes.string,
				positionName: PropTypes.string,
				timezone: PropTypes.string,
			})
		),
	}),
	selectedPerson: PropTypes.shape({
		associateID: PropTypes.string,
		personID: PropTypes.string,
	}),
	socket: PropTypes.object,
	getCurrentSubjectIDs: PropTypes.func,
	isGroupOrPersonTimeline: PropTypes.func,
	getCurrentTimeline: PropTypes.func,
	getEventsWithSummary: PropTypes.func,
	handleActionClick: PropTypes.func,
	handleOpportunityClick: PropTypes.func,
	handleEventExpandOrCollapse: PropTypes.func,
	isDesktopLayout: PropTypes.bool,
	timeSheet: PropTypes.object,
	isInsidePeopleView: PropTypes.bool,
	rightPanelComponent: PropTypes.shape({
		type: PropTypes.string,
	}),
	lastEventIDLoadedIntoRightPanel: PropTypes.string,
	search: PropTypes.shape({
		timeline: PropTypes.shape({
			query: PropTypes.string,
			result: PropTypes.arrayOf(PropTypes.object),
			timestamp: PropTypes.number,
			history: PropTypes.shape({
				queries: PropTypes.arrayOf(PropTypes.string),
				hits: PropTypes.arrayOf(PropTypes.object),
			}),
			hit: PropTypes.object,
			loading: PropTypes.bool,
		}),
	}),
};

const mapStateToProps = ({
	filteredTimeline,
	timeline,
	timelineSocket,
	clock,
	people,
	socket,
	layout,
	timeSheet,
	user,
	rightPanel,
	search,
}) => ({
	...timeline,
	filteredTimeline,
	isSmallBusiness: user?.client?.isSmallBusiness,
	locale: user?.localeCode || 'en',
	socket,
	timeline,
	timelineSocket,
	timeSheet,
	selectedGroup: people.selectedGroup,
	selectedPerson: people.selectedPerson,
	clock,
	isDesktopLayout: layout.isDesktopLayout,
	rightPanelComponent: rightPanel.component,
	lastEventIDLoadedIntoRightPanel: rightPanel.lastEventIDLoadedIntoRightPanel,
	search,
});

eIntl.addPart({
	name: 'mobile:TimelineView',
	getMessages: (locale) => {
		return require(`../i18n/mobile-${locale}`);
	},
});

eIntl.addPart({
	name: 'mobile:Connection',
	getMessages: (locale) => require(`../i18n/mobile-${locale}`),
});

export default connect(mapStateToProps)(withTimelineHandler(TimelineView));
