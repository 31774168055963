const postMessageMiddleware = store => next => action => { // eslint-disable-line
	if (action.postMessage && action.postMessage.element && action.postMessage.message) {
		const { element, message } = action.postMessage;

		const targetElement = document.querySelector(element);
		if (targetElement && targetElement.contentWindow &&
      targetElement.contentWindow.postMessage) {
			targetElement.contentWindow.postMessage(message, '*');
		}

		action.completed = true;
	}

	return next(action);
};

export default postMessageMiddleware;
