import React from 'react';
import PropTypes from 'prop-types';
import { eIntl, mergeIntlFiles } from '@adplabs/adp-e-common/ui-intl';
import { openLinkInNativeBrowser } from '../../utils/Auth';
import { IconWorldSmall } from '@adplabs/adp-e-common/ui-svg';
import { SelectInput } from '@adplabs/adp-e-common/ui-input/components';

const intlNamespace = 'mobile:AuthFooter';

const AuthFooter = ({ languages, defaultLanguage, onLanguageChange, className }) => {
	const selectLanguage = (
		<div className="select-language">
			<div>
				<IconWorldSmall size={20} />
				<SelectInput
					items={languages}
					defaultValue={defaultLanguage}
					onChange={onLanguageChange}
					disableAutoFocus
					itemsClassNames={className}
				/>
			</div>
		</div>
	);

	const links = {
		urlHelp: eIntl.formatMessage(`${intlNamespace}.urlHelp`),
		urlTerms: eIntl.formatMessage(`${intlNamespace}.urlTerms`),
		urlPrivacy: eIntl.formatMessage(`${intlNamespace}.urlPrivacy`),
		urlAI: eIntl.formatMessage(`${intlNamespace}.urlAI`)
	};

	return (
		<footer>
			{languages.length > 1 ? selectLanguage : null}
			<ul>
				<li>
					<a
						href={links.urlPrivacy}
						onClick={e => {
							e.preventDefault();
						}}
						onTouchStart={e => {
							e.preventDefault();
							openLinkInNativeBrowser(e.target.href);
						}}
						className={className}
					>
						{eIntl.formatMessage(`${intlNamespace}.labelPrivacy`)}
					</a>
				</li>
				<li>
					<a
						href={links.urlAI}
						onClick={e => {
							e.preventDefault();
						}}
						onTouchStart={e => {
							e.preventDefault();
							openLinkInNativeBrowser(e.target.href);
						}}
						className={className}
					>
						{eIntl.formatMessage(`${intlNamespace}.labelAI`)}
					</a>
				</li>
				<li>
					<a
						href={links.urlHelp}
						onClick={e => {
							e.preventDefault();
						}}
						onTouchStart={e => {
							e.preventDefault();
							openLinkInNativeBrowser(e.target.href);
						}}
						className={className}
					>
						{eIntl.formatMessage(`${intlNamespace}.labelHelp`)}
					</a>
				</li>
				<li>
					<a
						href={links.urlTerms}
						onClick={e => {
							e.preventDefault();
						}}
						onTouchStart={e => {
							e.preventDefault();
							openLinkInNativeBrowser(e.target.href);
						}}
						className={className}
					>
						{eIntl.formatMessage(`${intlNamespace}.labelTerms`)}
					</a>
				</li>
			</ul>
		</footer>
	);
};

AuthFooter.propTypes = {
	defaultLanguage: PropTypes.string,
	languages: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		})
	),
	onLanguageChange: PropTypes.func,
	className: PropTypes.string
};

export default AuthFooter;

eIntl.addPart({
	name: intlNamespace,
	getMessages: (locale) => {
		const country = eIntl.getCountryCode();
		const baseFile = require(`../../i18n/mobile-${locale}`);
		try {
			const countrySpecificFile = require(`../../i18n/${country}/mobile-${locale}`);
			return mergeIntlFiles(baseFile, countrySpecificFile, locale, intlNamespace);
		} catch {
			return baseFile;
		}
	}
});
