import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconSearch from '@adplabs/adp-e-common/ui-svg/icon-search';
import IconHeadphones from '@adplabs/adp-e-common/ui-svg/icon-headphones';
import IconHeadphonesSolid from '@adplabs/adp-e-common/ui-svg/icon-headphones-solid';
import {
	IconXSmall,
	IconWorldSmall,
	IconSwap,
} from '@adplabs/adp-e-common/ui-svg';
import { SelectInput } from '@adplabs/adp-e-common/ui-input/components';
import spinner from '../assets/img/throbber-spinner.gif';
import noop from 'lodash/noop';
import classNames from 'classnames';
import { eIntl } from '@adplabs/adp-e-common/ui-intl';
import { animationStates } from '@adplabs/adp-e-common/ui-error/reducers/helpReminder';
import { actions } from '@adplabs/adp-e-common/ui-error/actions/helpReminder';

const intlNamespace = 'mobile:MainHeader';

/**
 * @param {string} title - The title of the page
 */
const getARIALabelForTitle = (title) => {
	if (!title) return title;
	switch (title.toLowerCase()) {
		case 'chat':
			return `${title} - This is where you'll have quick conversations with the app to complete all your work-related tasks.`;

		case 'timeline':
			return `${title} - This is where you'll find any tasks you need to work on and can review any tasks you've already completed.`;

		case 'people':
			return `${title} - This is where you'll find information relevant to your job and compensation. It's also where managers can view and update details for their employees.`;

		default:
			return title;
	}
};

class Header extends React.Component {
	constructor(props) {
		super(props);

		this.handleClickSupportIcon = this.handleClickSupportIcon.bind(this);
		this.handleClickEndSession = this.handleClickEndSession.bind(this);
		this.handleClickSearchIcon = this.handleClickSearchIcon.bind(this);
		this.handleClickLogoutIcon = this.handleClickLogoutIcon.bind(this);
		this.handleChangeLanguageSelectIcon =
			this.handleChangeLanguageSelectIcon.bind(this);
	}

	handleClickEndSession() {
		this.props.onClickEndSession();
	}

	handleClickSearchIcon() {
		this.props.onClickSearchIcon();
	}

	handleClickLogoutIcon() {
		this.props.onClickLogoutIcon();
	}

	handleClickSupportIcon() {
		this.props.onClickSupportAction();
	}

	handleChangeLanguageSelectIcon(e) {
		this.props.onChangeLanguageSelectIcon(e);
	}

	renderSearchIcon() {
		const { actingAsMode, hideSearchIcon } = this.props;

		if (actingAsMode) {
			return null;
		}

		if (hideSearchIcon) {
			return null;
		}

		return (
			<button
				className="header-icon search-icon"
				aria-label="Search"
				onClick={this.handleClickSearchIcon}
			>
				<IconSearch />
			</button>
		);
	}

	renderLogoutIcon() {
		const { hideLogoutIcon = true } = this.props;
		if (hideLogoutIcon) {
			return null;
		}

		return (
			<button
				className="header-icon"
				aria-label="Logout"
				onClick={this.handleClickLogoutIcon}
			>
				<IconXSmall maxSizes={['15px', '.9em']} />
			</button>
		);
	}

	renderLanguageSelectIcon() {
		const {
			hideLanguageSelectIcon = true,
			languages = [],
			defaultLanguage,
		} = this.props;
		if (hideLanguageSelectIcon) {
			return null;
		}
		return (
			<div className="select-language-header-control">
				<div>
					<SelectInput
						items={languages}
						defaultValue={defaultLanguage}
						onChange={this.handleChangeLanguageSelectIcon}
						disableAutoFocus
					/>
					<IconWorldSmall maxSizes={['20px', '.9em']} />
				</div>
			</div>
		);
	}

	renderSupportIcon() {
		if (this.props.hideSupportIcon) {
			return null;
		}

		if (this.props.actingAsMode) {
			return null;
		}

		const cssClasses = classNames(
			'header-icon header-icon-support-icon mobile',
			{
				'header-icon-live-chat-visible': this.props.supportIconSelected,
				'header-icon-live-chat-active': this.props.liveChatActive,
				animating: this.props.helpReminder === animationStates.animating,
			}
		);

		const supportButtonAriaStatus = this.props.liveChatActive
			? 'Support Available'
			: 'Support Currently Unavailable';

		const icon = this.props.liveChatActive ? (
			<IconHeadphonesSolid />
		) : (
			<IconHeadphones />
		);

		return (
			<button
				className={cssClasses}
				aria-label={supportButtonAriaStatus}
				onClick={this.handleClickSupportIcon}
				onAnimationEnd={this.props.helpReminderDone}
			>
				<span>{eIntl.formatMessage(`${intlNamespace}.helpButtonLabel`)}</span>

				{icon}
			</button>
		);
	}

	renderEndSession() {
		const { impersonatedClientName, actingAsMode } = this.props;

		if (!actingAsMode) {
			return null;
		}
		const compName = impersonatedClientName
			? impersonatedClientName.substring(0, 10)
			: '';
		return (
			<button
				className="end-session-button"
				onClick={this.handleClickEndSession}
			>
				<IconXSmall size={10} />
				{compName}
			</button>
		);
	}

	renderLoading() {
		if (!this.props.showLoading) {
			return null;
		}

		return (
			<img
				className="loading-icon"
				src={spinner}
				height="20"
				width="20"
				alt="Loading..."
			/>
		);
	}

	render() {
		const {
			actingAsMode,
			isDesktopLayout,
			title,
			client,
			toggleClientSwitchpopup,
		} = this.props;

		const classNames = [
			'adp-e-mobile-header',
			actingAsMode ? actingAsMode.toLowerCase() : '',
			isDesktopLayout ? 'adp-e-desktop-header' : ''
		].join(' ');

		return (
			<header
				id="main-header"
				className={classNames}
				aria-label={getARIALabelForTitle(title)}
				// Without role="banner", VoiceOver won't categorize
				// this <header> as a Landmark.
				role="banner"
			>
				{isDesktopLayout ? (
					<h1>
						{title}
						{this.renderLoading()}
					</h1>
				) : (
					<>
						<div className="page-title-elements">
							<div>
								<h1>{title}</h1>
								{client ? (
									<button
										className="client-title"
										onClick={toggleClientSwitchpopup}
										onKeyDown={toggleClientSwitchpopup}
										disabled={this.props.disableClientSwitch}
									>
										<p className="overflow-ellipsis">{client.clientName} </p>
										<IconSwap className="text-primary-500 ml-1" size="13" />
									</button>
								) : null}
							</div>
							{this.renderLoading()}
						</div>
						{this.renderLanguageSelectIcon()}
						{this.renderLogoutIcon()}
						{this.renderSupportIcon()}
						{this.renderEndSession()}
					</>
				)}
			</header>
		);
	}
}

Header.defaultProps = {
	onClickSupportAction: noop,
	onClickEndSession: noop,
	onClickSearchIcon: noop,
	onClickLogoutIcon: noop,
	onChangeLanguageSelectIcon: noop,
	isDesktopLayout: false,
	disableClientSwitch: false,
	title: ''
};

Header.propTypes = {
	actingAsMode: PropTypes.string,
	hideSearchIcon: PropTypes.bool,
	hideSupportIcon: PropTypes.bool,
	hideLogoutIcon: PropTypes.bool,
	hideLanguageSelectIcon: PropTypes.bool,
	impersonatedClientName: PropTypes.string,
	isDesktopLayout: PropTypes.bool,
	liveChatActive: PropTypes.bool,
	onClickEndSession: PropTypes.func,
	onClickSearchIcon: PropTypes.func,
	onClickSupportAction: PropTypes.func,
	onClickLogoutIcon: PropTypes.func,
	onChangeLanguageSelectIcon: PropTypes.func,
	showLoading: PropTypes.bool,
	supportIconSelected: PropTypes.bool,
	title: PropTypes.string.isRequired,
	helpReminderDone: PropTypes.func,
	helpReminder: PropTypes.string,
	defaultLanguage: PropTypes.string,
	languages: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string,
		})
	),
	client: PropTypes.shape({
		clientID: PropTypes.string,
		clientName: PropTypes.string,
	}),
	toggleClientSwitchpopup: PropTypes.func,
	disableClientSwitch: PropTypes.bool,
};

const mapStateToProps = ({ helpReminder }) => ({ helpReminder });

export default connect(mapStateToProps, actions)(Header);

eIntl.addPart({
	name: intlNamespace,
	getMessages: (locale) => require(`../i18n/mobile-${locale}`),
});
